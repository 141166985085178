import { Component, OnDestroy, OnInit } from '@angular/core';
import { faTimesCircle, faExclamationCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Store } from '@ngrx/store';
import { FormArrayState, FormControlState, FormControlValueTypes, FormGroupState, NgrxValueConverter } from 'ngrx-forms';
import { Observable, Subscription } from 'rxjs';
import { addHouseholdMember, addHouseholdPet, addHouseholdServiceAnimal, clearHouseholdPets, clearHouseholdServiceAnimals, removeHouseholdMember, removeHouseholdPet, removeHouseholdServiceAnimal, setCurrentStep } from '../store/application.actions';
import { ApplicationState, ApplicationSteps } from '../store/application.state';
import { ApplicationForm, HouseholdAnimal, HouseholdMember } from '../store/application-form.state';
import { Relations } from '../store/reference-data';

@Component({
    selector: 'app-household-info',
    templateUrl: './household-info.component.html',
    styleUrls: ['./household-info.component.css']
})
export class HouseholdInfoComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    faTimesCircle: IconDefinition = faTimesCircle;
    faExclamationCircle: IconDefinition = faExclamationCircle;

    formState$: Observable<FormGroupState<ApplicationForm['householdInfo']>>
    householdMembers$: Observable<FormArrayState<HouseholdMember>>
    householdServiceAnimals$: Observable<FormArrayState<HouseholdAnimal>>
    householdPets$: Observable<FormArrayState<HouseholdAnimal>>

    serviceDogs: boolean = false;
    additionalPets: boolean = false;
    allergic: boolean = false;

    householdMemberRelationOptions: string[] = Relations;
    homeTypeOptions: string[] = ['Detached Single Family', 'Apartment', 'Townhouse', 'Condominium', 'Mobile Home'];
    sizeOfYardOptions: string[] = ['0-500 square feet', '501-1000 square feet', '1001-1050 square feet', '1051-2000 square feet', 'More than 2000 square feet'];
    homeLocationOptions: string[] = ['Rural', 'Suburban', 'Central Urban'];

    constructor(private store: Store<{ application: ApplicationState }>) {
        this.formState$ = store.select(s => s.application.applicationForm.controls.householdInfo)
        this.householdMembers$ = store.select(s => s.application.applicationForm.controls.householdInfo.controls.householdMembers);
        this.householdServiceAnimals$ = store.select(s => s.application.applicationForm.controls.householdInfo.controls.serviceAnimals);
        this.householdPets$ = store.select(s => s.application.applicationForm.controls.householdInfo.controls.pets);
    }

    ngOnInit() {
        this.subscription.add(this.formState$.subscribe(s => {
            this.serviceDogs = s.controls.serviceAnimalsInHome.value;
            this.additionalPets = s.controls.petsInHome.value;
            this.allergic = s.controls.dogAllergyInHome.value;

            if (this.serviceDogs && s.controls.serviceAnimals.value.length === 0) {
                this.addServiceAnimal();
            } else if (!this.serviceDogs && s.controls.serviceAnimals.value.length > 0) {
                this.store.dispatch(clearHouseholdServiceAnimals());
            }

            if (this.additionalPets && s.controls.pets.value.length === 0) {
                this.addPet();
            } else if (!this.additionalPets && s.controls.pets.value.length > 0) {
                this.store.dispatch(clearHouseholdPets());
            }
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addHouseholdMember() {
        this.store.dispatch(addHouseholdMember());
    }

    removeHouseholdMember(index: number) {
        this.store.dispatch(removeHouseholdMember({ index }));
    }

    addServiceAnimal() {
        this.store.dispatch(addHouseholdServiceAnimal());
    }

    removeServiceAnimal(index: number) {
        this.store.dispatch(removeHouseholdServiceAnimal({ index }));
    }

    addPet() {
        this.store.dispatch(addHouseholdPet());
    }

    removePet(index: number) {
        this.store.dispatch(removeHouseholdPet({ index }));
    }

    backToPreviousStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.PersonalInfo }));
        window.scrollTo(0,0);
    }

    continueToNextStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.MilitaryInfo }));
        window.scrollTo(0,0);
    }

    trackByIndex(index, item) {
        return index;
    }

    selectValueConverter: NgrxValueConverter<string, string> = {
        convertViewToStateValue(value: string): string {
            if (!value)
                return null;
            return value;
        },

        convertStateToViewValue(value: string): string {
            if (!value)
                return '';
            return value;
        }
    }

    isInvalid<TValue extends FormControlValueTypes>(control: FormControlState<TValue>): boolean {
        return control.isTouched && control.isInvalid
    }
}
