import { Component, Input } from '@angular/core';
import { FormControlState, NgrxValueConverter } from 'ngrx-forms';

@Component({
    selector: 'app-warrior-form-select',
    templateUrl: './warrior-form-select.component.html',
    styleUrls: ['./warrior-form-select.component.css']
})
export class WarriorFormSelectComponent {
    @Input() label: string;
    @Input() placeholder?: string = 'Please select';
    @Input('aria-label') ariaLabel?: string;
    @Input() control: FormControlState<string>
    @Input() options: string[]
    @Input() sublabel?: string;

    constructor() {}

    selectValueConverter: NgrxValueConverter<string, string> = {
        convertViewToStateValue(value: string): string {
            if (!value)
                return null;
            return value;
        },

        convertStateToViewValue(value: string): string {
            if (!value)
                return '';
            return value;
        }
    }
}
