<li class="nav-item dropdown" *ngIf="isAuthenticated$ | async">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <span *ngIf="isImpersonated$ | async">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                class="impersonation-warning" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
        </span>
        {{userName$ | async}}
    </a>
    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
        <ng-container *ngIf="isAdmin$ | async">
            <li>
                <a class="dropdown-item" [routerLink]="['/admin','impersonate']">Impersonate User</a>
            </li>
            <li>
                <hr class="dropdown-divider">
            </li>
        </ng-container>
        <ng-container *ngIf="isImpersonated$ | async">
            <li>
                <div class="dropdown-item impersonation-warning" [routerLink]="[]">
                    <span class="badge badge-pill badge-warning">Impersonated By</span>
                    <span>{{impersonatedBy$ | async}}</span>
                </div>
            </li>
            <li>
                <hr class="dropdown-divider">
            </li>
        </ng-container>
        <li>
            <a class="dropdown-item" [routerLink]='["/authentication/logout"]' [state]='{ local: true }'
                title="Logout">Logout</a>
        </li>
    </ul>
</li>
