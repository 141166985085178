<div class="border-bottom px-3 py-4">
    <h5 class="section-title mb-0">Miscellaneous</h5>
</div>
<ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState">
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Have you owned a pet dog in the past?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.ownedPetDog" class="form-check-input" type="radio"
                        name="ownedPetDog" id="ownedPetDog_Yes" [value]="true">
                    <label class="form-check-label" for="ownedPetDog_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.ownedPetDog" class="form-check-input" type="radio"
                        name="ownedPetDog" id="ownedPetDog_No" [value]="false">
                    <label class="form-check-label" for="ownedPetDog_No">
                        No
                    </label>
                </div>
            </div>
        </div>
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Are you familiar with the care of dogs?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.familiarWithDogCare" class="form-check-input"
                        type="radio" name="familiarWithDogCare" id="familiarWithDogCare_Yes" [value]="true">
                    <label class="form-check-label" for="familiarWithDogCare_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.familiarWithDogCare" class="form-check-input"
                        type="radio" name="familiarWithDogCare" id="familiarWithDogCare_No" [value]="false">
                    <label class="form-check-label" for="familiarWithDogCare_No">
                        No
                    </label>
                </div>
            </div>
        </div>
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Is anyone in your house scared of dogs?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.cynophobia" class="form-check-input" type="radio"
                        name="cynophobia" id="cynophobia_Yes" [value]="true">
                    <label class="form-check-label" for="cynophobia_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.cynophobia" class="form-check-input" type="radio"
                        name="cynophobia" id="cynophobia_No" [value]="false">
                    <label class="form-check-label" for="cynophobia_No">
                        No
                    </label>
                </div>
            </div>
        </div>

        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.householdSupportApplication.value === false">
                <h6 class="text-dark mb-0">Does everyone in your household support your decision to apply to a service dog
                    program?
                </h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.householdSupportApplication"
                            class="form-check-input" type="radio" name="householdSupportApplication" id="householdSupportApplication_Yes"
                            [value]="true">
                        <label class="form-check-label" for="householdSupportApplication_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.householdSupportApplication"
                            class="form-check-input" type="radio" name="householdSupportApplication" id="householdSupportApplication_No"
                            [value]="false">
                        <label class="form-check-label" for="householdSupportApplication_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.householdSupportApplication.value === false"
                label="Who does not support your decision?"
                [control]="formState.controls.householdSupportApplicationNonSupportingPerson"
                [showBottomBorder]="false"
            ></app-warrior-form-input>
        </div>

        <app-warrior-form-input label="Obstacles & Issues" sublabel="Please comment on any obstacles or issues you feel need to be addressed in
            order for
            you to attend team training: (Keep in mind that the waitlist is anywhere from 18-36 months.)"
            [control]="formState.controls.obstaclesAndIssues" placeholder="Leave a comment here">
        </app-warrior-form-input>

        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.joinInprogressClass.value === true">
                <h6 class="text-dark mb-0">In the rare occasion of an open spot in a class, do you have the flexibility in your schedule to fill a last-minute spot?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.joinInprogressClass" class="form-check-input"
                            type="radio" name="joinInProgressClass" id="joinInProgressClass_Yes" [value]="true">
                        <label class="form-check-label" for="joinInProgressClass_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.joinInprogressClass" class="form-check-input"
                            type="radio"  name="joinInProgressClass" id="joinInProgressClass_No" [value]="false">
                        <label class="form-check-label" for="joinInProgressClass_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-select
                *ngIf="formState.controls.joinInprogressClass.value === true"
                label="How much advance notice would you require?"
                aria-placeholder="Select a value"
                [options]="['Less than 2 weeks', '2-4 weeks', '4-8 weeks']"
                [showBottomBorder]="false"
                [control]="formState.controls.joinInprogressClassTimeFrame"
            ></app-warrior-form-select>
        </div>

        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.acceptedToAlternateDogOrg.value === true">
                <h6 class="text-dark mb-0">Have you ever been accepted to another service dog organization?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.acceptedToAlternateDogOrg"
                            class="form-check-input" type="radio" name="acceptedToAlternateDogOrg" id="acceptedToAlternateDogOrg_Yes" [value]="true">
                        <label class="form-check-label" for="acceptedToAlternateDogOrg_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.acceptedToAlternateDogOrg"
                            class="form-check-input" type="radio" name="acceptedToAlternateDogOrg" id="acceptedToAlternateDogOrg_No" [value]="false">
                        <label class="form-check-label" for="acceptedToAlternateDogOrg_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.acceptedToAlternateDogOrg.value === true"
                label="Which organization?"
                [showBottomBorder]="false"
                [control]="formState.controls.acceptedToAlternateDogOrgWhichOrg"
            ></app-warrior-form-input>
        </div>


        <div *ngIf="formState.controls.acceptedToAlternateDogOrg.value === true"
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Do you currently have a service dog from the organization mentioned above?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.haveAlternateDog" class="form-check-input"
                        type="radio" name="haveAlternateDog" id="haveAlternateDog_Yes" value="Yes">
                    <label class="form-check-label" for="haveAlternateDog_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.haveAlternateDog" class="form-check-input"
                        type="radio" name="haveAlternateDog" id="haveAlternateDog_No" value="No">
                    <label class="form-check-label" for="haveAlternateDog_No">
                        No
                    </label>
                </div>
            </div>
        </div>

        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.deniedServiceDog.value === true">
                <h6 class="text-dark mb-0">Have you ever been denied a service dog by an organization?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.deniedServiceDog" class="form-check-input"
                            type="radio" name="deniedServiceDog" id="deniedServiceDog_Yes" [value]="true">
                        <label class="form-check-label" for="deniedServiceDog_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.deniedServiceDog" class="form-check-input"
                            type="radio" name="deniedServiceDog" id="deniedServiceDog_No" [value]="false">
                        <label class="form-check-label" for="deniedServiceDog_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.deniedServiceDog.value === true"
                label="Which organization?"
                [showBottomBorder]="false"
                [control]="formState.controls.deniedServiceDogWhichOrg"
            ></app-warrior-form-input>
        </div>

        <app-warrior-form-input label="How did you learn about K9s For Warriors?"
            [control]="formState.controls.learnAboutK9" placeholder="Leave a comment here">
        </app-warrior-form-input>
        <div class="px-3 py-4">
            <div class="d-flex">
                <input [ngrxFormControlState]="formState.controls.providingContinuousPetInsurance" type="checkbox"
                    id="acknowledge" name="acknowledge" class="mr-1">
                <label for="acknowledge" class="w-100"><b>Please remember that having any dog is a serious personal
                        commitment.</b> Having a service dog also carries a financial responsibility, we estimate that
                    this
                    responsibility is near $1000 per year. This dollar amount considers veterinary expenses, food, and
                    preventative
                    medications. Having Pet insurance is a way to ensure you have financial options and provide care in
                    case
                    of
                    unforeseen medical emergencies. K9S For Warriors is not responsible for covering Veterinary Care or
                    Treatment.
                    Will you able to provide continuous pet insurance? (By clicking this checkbox, you are committing to
                    providing
                    continuous pet insurance)</label>
            </div>
        </div>
        <div class="border-bottom px-3 py-4 d-flex justify-content-between align-items-center btn-container">
            <button class="btn btn-back" (click)="backToPreviousStep()">BACK TO SECTION 8 - LEGAL HISTORY</button>
            <button class="btn btn-primary" (click)="continueToNextStep()">CONTINUE TO SECTION 10 - CONSENT</button>
        </div>
    </form>
</ng-container>
