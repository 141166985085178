import { setErrors, setValue, StateUpdateFns, validate } from "ngrx-forms";
import { maxLength, required, requiredTrue } from "ngrx-forms/validation";
import { ApplicationForm } from "../application-form.state";
import { initIsOptional, setIsOptional } from "./common-functions";

export const MiscellaneousRequiredInputStateFns: StateUpdateFns<ApplicationForm['miscellaneous']> = {
    ownedPetDog: validate(required),
    familiarWithDogCare: validate(required),
    cynophobia: validate(required),
    householdSupportApplication: validate(required),
    obstaclesAndIssues: validate(maxLength(1000)),
    acceptedToAlternateDogOrg: validate(required),
    haveAlternateDog: validate(required),
    deniedServiceDog: validate(required),
    joinInprogressClass: validate(required),
    learnAboutK9: validate(required),
    providingContinuousPetInsurance: validate(required, requiredTrue)
}


export const MiscellaneousOptionalInputStateFns: StateUpdateFns<ApplicationForm['miscellaneous']> = {
    householdSupportApplicationNonSupportingPerson: (c, frm) => !frm.controls.householdSupportApplication.value
        ? c
        : setValue(c, null),
    acceptedToAlternateDogOrgWhichOrg: (c, frm) => frm.controls.acceptedToAlternateDogOrg.value
        ? c
        : setValue(c, null),
    haveAlternateDog: (c, frm) => frm.controls.acceptedToAlternateDogOrg.value
        ? c
        : setValue(c, null),
    deniedServiceDogWhichOrg: (c, frm) => frm.controls.deniedServiceDog.value
        ? c
        : setValue(c, null),
    joinInprogressClassTimeFrame: (c, frm) => frm.controls.joinInprogressClass.value
        ? c
        : setValue(c, null),
}


export const MiscellaneousSetOptionalInputStateFns: StateUpdateFns<ApplicationForm['miscellaneous']> = {
    householdSupportApplicationNonSupportingPerson: (c, frm) => !frm.controls.householdSupportApplication.value
        ? setIsOptional(c, false)
        : setIsOptional(c, true),
    acceptedToAlternateDogOrgWhichOrg: (c, frm) => frm.controls.acceptedToAlternateDogOrg.value
        ? setIsOptional(c, false)
        : setIsOptional(c, true),
    haveAlternateDog: (c, frm) => setIsOptional(c, !frm.controls.acceptedToAlternateDogOrg.value),
    deniedServiceDogWhichOrg: (c, frm) => frm.controls.deniedServiceDog.value
        ? setIsOptional(c, false)
        : setIsOptional(c, true),
    obstaclesAndIssues: initIsOptional(),
    joinInprogressClassTimeFrame: (c, frm) => frm.controls.joinInprogressClass.value
        ? setIsOptional(c, false)
        : setIsOptional(c, true),
}


export const MiscellaneousOptionalInputValidatorStateFns: StateUpdateFns<ApplicationForm['miscellaneous']> = {
    householdSupportApplicationNonSupportingPerson: (c, frm) => !frm.controls.householdSupportApplication.value
        ? validate(c, required)
        : setErrors(c, {}),
    acceptedToAlternateDogOrgWhichOrg: (c, frm) => frm.controls.acceptedToAlternateDogOrg.value
        ? validate(c, required)
        : setErrors(c, {}),
    haveAlternateDog: (c, frm) => frm.controls.acceptedToAlternateDogOrg.value
        ? validate(c, required)
        : setErrors(c, {}),
    deniedServiceDogWhichOrg: (c, frm) => frm.controls.deniedServiceDog.value
        ? validate(c, required)
        : setErrors(c, {}),
    joinInprogressClassTimeFrame: (c, frm) => frm.controls.joinInprogressClass.value
        ? validate(c, required)
        : setErrors(c, {}),
}
