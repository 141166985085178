<div class="border-bottom px-3 py-4">
    <h5 class="section-title mb-0">Legal History</h5>
    <p class="p-small text-dark mb-0">It is the policy of K9s For Warriors to conduct multiple background checks on all
        applicants. Being charged with or
        convicted of a crime does not necessarily disqualify an applicant. Please be honest and thorough with your
        answers.</p>
</div>
<ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState">

        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-center mb-3 responsive-form-field">
                <h6 class="text-dark w-50 mb-0">Have you been charged with, or convicted of a felony?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.felony" class="form-check-input" type="radio"
                        name="felony" id="felony_Yes" [value]="true">
                        <label class="form-check-label" for="felony_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.felony" class="form-check-input" type="radio"
                        name="felony" id="felony_No" [value]="false">
                        <label class="form-check-label" for="felony_No">
                            No
                        </label>
                    </div>
                </div>
            </div>

            <div *ngIf="formState.controls.felony.value">
                <app-warrior-form-input
                    label="Please list the felony"
                    [control]="formState.controls.chargeStatement"
                    placeholder="">
                </app-warrior-form-input>

                <app-warrior-form-input
                    label="What year were you charged?"
                    [control]="formState.controls.yearChargedStatement"
                    placeholder="">
                </app-warrior-form-input>

                <app-warrior-form-select
                    label="State"
                    sublabel="What state were you charged in?"
                    [control]="formState.controls.stateChargedIn"
                    [options]="statesAndTerritoriesOptions">
                </app-warrior-form-select>

                <app-warrior-form-input
                    label="What was the verdict?"
                    [control]="formState.controls.finalVerdictStatement"
                    placeholder="">
                </app-warrior-form-input>

                <div class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
                    <h6 class="text-dark mb-0">Is your case still open?</h6>
                    <div class="d-flex responsive-form-field">
                        <div class="form-check mr-3">
                            <input [ngrxFormControlState]="formState.controls.isCaseOpen" class="form-check-input" type="radio"
                            name="isCaseOpen" id="isCaseOpen_Yes" [value]="true">
                            <label class="form-check-label" for="isCaseOpen_Yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check">
                            <input [ngrxFormControlState]="formState.controls.isCaseOpen" class="form-check-input" type="radio"
                            name="isCaseOpen" id="isCaseOpen_No" [value]="false">
                            <label class="form-check-label" for="isCaseOpen_No">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
                    <h6 class="text-dark mb-0">Are you currently on parole?</h6>
                    <div class="d-flex responsive-form-field">
                        <div class="form-check mr-3">
                            <input [ngrxFormControlState]="formState.controls.isCurrentlyOnParole" class="form-check-input" type="radio"
                            name="isCurrentlyOnParole" id="isCurrentlyOnParole_Yes" [value]="true">
                            <label class="form-check-label" for="isCurrentlyOnParole_Yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check">
                            <input [ngrxFormControlState]="formState.controls.isCurrentlyOnParole" class="form-check-input" type="radio"
                            name="isCurrentlyOnParole" id="isCurrentlyOnParole_No" [value]="false">
                            <label class="form-check-label" for="isCurrentlyOnParole_No">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
                    <h6 class="text-dark mb-0">Are you currently on probation?</h6>
                    <div class="d-flex responsive-form-field">
                        <div class="form-check mr-3">
                            <input [ngrxFormControlState]="formState.controls.isCurrentlyOnProbation" class="form-check-input" type="radio"
                            name="isCurrentlyOnProbation" id="isCurrentlyOnProbation_Yes" [value]="true">
                            <label class="form-check-label" for="isCurrentlyOnProbation_Yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check">
                            <input [ngrxFormControlState]="formState.controls.isCurrentlyOnProbation" class="form-check-input" type="radio"
                            name="isCurrentlyOnProbation" id="isCurrentlyOnProbation_No" [value]="false">
                            <label class="form-check-label" for="isCurrentlyOnProbation_No">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <app-warrior-form-input
                    label="Please explain"
                    [control]="formState.controls.felonyStatement"
                    placeholder=""
                    [showBottomBorder]="false">
                </app-warrior-form-input>
            </div>
        </div>

        <div *ngIf="showParoleOrProbationWarning" class="p-4 border-bottom">
            <div class="alert-required">
                <strong class="text-primary">Legal History Qualification</strong>
                <div class="text-dark alert-text mb-0">
                    <p>
                        K9s For Warriors is unable to process applications from veterans with open legal
                        cases, or from veterans currently on parole or probation. Once your case is fully
                        closed and you are no longer on parole or probation, please feel free to reapply
                        at that time. Depending on the nature of the charges and your overall criminal
                        history, we may be able to proceed with your application once the case is fully
                        closed. Please feel free to reach out to our Warrior Relations team at
                        info@k9sforwarriors.org with any questions. We would be happy to provide
                        additional resources that may be of interest to you.
                    </p>
                </div>
            </div>
        </div>

        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-center mb-3 responsive-form-field">
                <h6 class="text-dark w-50 mb-0">Have you been charged with, or convicted of a misdemeanor?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.misdemeanor" class="form-check-input" type="radio"
                        name="misdemeanor" id="misdemeanor_Yes" [value]="true">
                        <label class="form-check-label" for="misdemeanor_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.misdemeanor" class="form-check-input" type="radio"
                        name="misdemeanor" id="misdemeanor_No" [value]="false">
                        <label class="form-check-label" for="misdemeanor_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.misdemeanor.value"
                label="Please explain"
                [control]="formState.controls.misdemeanorStatement"
                placeholder=""
                [showBottomBorder]="false">
            </app-warrior-form-input>
        </div>

        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-center mb-3 responsive-form-field">
                <h6 class="text-dark w-50 mb-0">Have you been charged with, or convicted of any criminal traffic violations?
                </h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.criminalTrafficViolation" class="form-check-input" type="radio"
                        name="criminalTrafficViolation" id="criminalTrafficViolation_Yes" [value]="true">
                        <label class="form-check-label" for="criminalTrafficViolation_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.criminalTrafficViolation" class="form-check-input" type="radio"
                        name="criminalTrafficViolation" id="criminalTrafficViolation_No" [value]="false">
                        <label class="form-check-label" for="criminalTrafficViolation_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.criminalTrafficViolation.value"
                label="Please explain"
                [control]="formState.controls.criminalTrafficViolationStatement"
                placeholder=""
                [showBottomBorder]="false">
            </app-warrior-form-input>
        </div>

        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-center mb-3 responsive-form-field">
                <h6 class="text-dark w-50 mb-0">Have you ever received other traffic violations?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.otherTrafficViolation" class="form-check-input" type="radio"
                        name="otherTrafficViolation" id="otherTrafficViolation_Yes" [value]="true">
                        <label class="form-check-label" for="otherTrafficViolation_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.otherTrafficViolation" class="form-check-input" type="radio"
                        name="otherTrafficViolation" id="otherTrafficViolation_No" [value]="false">
                        <label class="form-check-label" for="otherTrafficViolation_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.otherTrafficViolation.value"
                label="Please explain"
                [control]="formState.controls.otherTrafficViolationStatement"
                placeholder=""
                [showBottomBorder]="false">
            </app-warrior-form-input>
        </div>

        <app-warrior-form-input
            label="Have you been arrested?"
            sublabel="Have you been arrested at any time, for anything, in the last 36 months? Please
            annotate arrest(s) even if it did not result in a conviction."
            [control]="formState.controls.arrestedStatement"
            placeholder="Leave a comment here">
        </app-warrior-form-input>

        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Do you have a history of violence?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.historyOfViolence" class="form-check-input" type="radio"
                    name="historyOfViolence" id="historyOfViolence_Yes" [value]="true">
                    <label class="form-check-label" for="historyOfViolence_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.historyOfViolence" class="form-check-input" type="radio"
                    name="historyOfViolence" id="historyOfViolence_No" [value]="false">
                    <label class="form-check-label" for="historyOfViolence_No">
                        No
                    </label>
                </div>
            </div>
        </div>

        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Have you ever become so angry/frustrated that you have struck someone?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.struckPerson" class="form-check-input" type="radio"
                    name="struckPerson" id="struckPerson_Yes" [value]="true">
                    <label class="form-check-label" for="struckPerson_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.struckPerson" class="form-check-input" type="radio"
                    name="struckPerson" id="struckPerson_No" [value]="false">
                    <label class="form-check-label" for="struckPerson_No">
                        No
                    </label>
                </div>
            </div>
        </div>

        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Have you ever become so angry/frustrated that you have struck an animal?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.struckAnimal" class="form-check-input" type="radio"
                    name="struckAnimal" id="struckAnimal_Yes" [value]="true">
                    <label class="form-check-label" for="struckAnimal_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.struckAnimal" class="form-check-input" type="radio"
                    name="struckAnimal" id="struckAnimal_No" [value]="false">
                    <label class="form-check-label" for="struckAnimal_No">
                        No
                    </label>
                </div>
            </div>
        </div>

        <div class="border-bottom px-3 py-4 d-flex justify-content-between align-items-center btn-container">
            <button class="btn btn-back" (click)="backToPreviousStep()">BACK TO SECTION 7 - MEDICAL/MENTAL</button>
            <button class="btn btn-primary" (click)="continueToNextStep()">CONTINUE TO SECTION 9 - MISCELLANEOUS</button>
        </div>
    </form>
</ng-container>
