import { setErrors, setUserDefinedProperty, setValue, StateUpdateFns, validate } from "ngrx-forms";
import { maxLength, required } from "ngrx-forms/validation";
import { isNotOnParoleOrProbation } from "../custom-validators";
import { ApplicationForm } from "../application-form.state";
import { initIsOptional } from "./common-functions";

export const LegalHistoryRequiredInputStateFns: StateUpdateFns<ApplicationForm['legalHistory']> = {
    felony: validate(required),
    misdemeanor: validate(required),
    criminalTrafficViolation: validate(required),
    otherTrafficViolation: validate(required),
    arrestedStatement: validate(maxLength(1000)),
    historyOfViolence: validate(required),
    struckPerson: validate(required),
    struckAnimal: validate(required)
}


export const LegalHistoryOptionalInputStateFns: StateUpdateFns<ApplicationForm['legalHistory']> = {
    chargeStatement: (c, frm) => frm.controls.felony.value
        ? c
        : setValue(c, null),
    yearChargedStatement: (c, frm) => frm.controls.felony.value
        ? c
        : setValue(c, null),
    stateChargedIn: (c, frm) => frm.controls.felony.value
        ? c
        : setValue(c, null),
    finalVerdictStatement: (c, frm) => frm.controls.felony.value
        ? c
        : setValue(c, null),
    isCaseOpen: (c, frm) => frm.controls.felony.value
        ? c
        : setValue(c, null),
    isCurrentlyOnParole: (c, frm) => frm.controls.felony.value
        ? c
        : setValue(c, null),
    isCurrentlyOnProbation: (c, frm) => frm.controls.felony.value
        ? c
        : setValue(c, null),
    felonyStatement: (c, frm) => frm.controls.felony.value
        ? c
        : setValue(c, null),
    misdemeanorStatement: (c, frm) => frm.controls.misdemeanor.value
        ? c
        : setValue(c, null),
    criminalTrafficViolationStatement: (c, frm) => frm.controls.criminalTrafficViolation.value
        ? c
        : setValue(c, null),
    otherTrafficViolationStatement: (c, frm) => frm.controls.otherTrafficViolation.value
        ? c
        : setValue(c, null)
}


export const LegalHistorySetOptionalInputStateFns: StateUpdateFns<ApplicationForm['legalHistory']> = {
    arrestedStatement: initIsOptional(),
    chargeStatement: (c, frm) => frm.controls.felony.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    yearChargedStatement: (c, frm) => frm.controls.felony.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    stateChargedIn: (c, frm) => frm.controls.felony.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    finalVerdictStatement: (c, frm) => frm.controls.felony.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    isCaseOpen: (c, frm) => frm.controls.felony.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    isCurrentlyOnParole: (c, frm) => frm.controls.felony.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    isCurrentlyOnProbation: (c, frm) => frm.controls.felony.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    felonyStatement: (c, frm) => frm.controls.felony.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    misdemeanorStatement: (c, frm) => frm.controls.misdemeanor.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    criminalTrafficViolationStatement: (c, frm) => frm.controls.criminalTrafficViolation.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    otherTrafficViolationStatement: (c, frm) => frm.controls.otherTrafficViolation.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true)
}


export const LegalHistoryOptionalInputValidatorStateFns: StateUpdateFns<ApplicationForm['legalHistory']> = {
    chargeStatement: (c, frm) => frm.controls.felony.value
        ? validate(c, required, maxLength(1000))
        : setErrors(c, {}),
    yearChargedStatement: (c, frm) => frm.controls.felony.value
        ? validate(c, required, maxLength(1000))
        : setErrors(c, {}),
    stateChargedIn: (c, frm) => frm.controls.felony.value
        ? validate(c, required, maxLength(1000))
        : setErrors(c, {}),
    finalVerdictStatement: (c, frm) => frm.controls.felony.value
        ? validate(c, required, maxLength(1000))
        : setErrors(c, {}),
    isCaseOpen: (c, frm) => frm.controls.felony.value
        ? validate(c, required)
        : setErrors(c, {}),
    isCurrentlyOnParole: (c, frm) => frm.controls.felony.value
        ? validate(c, required, isNotOnParoleOrProbation(frm))
        : setErrors(c, {}),
    isCurrentlyOnProbation: (c, frm) => frm.controls.felony.value
        ? validate(c, required, isNotOnParoleOrProbation(frm))
        : setErrors(c, {}),
    felonyStatement: (c, frm) => frm.controls.felony.value
        ? validate(c, required, maxLength(1000))
        : setErrors(c, {}),
    misdemeanorStatement: (c, frm) => frm.controls.misdemeanor.value
        ? validate(c, required, maxLength(1000))
        : setErrors(c, {}),
    criminalTrafficViolationStatement: (c, frm) => frm.controls.criminalTrafficViolation.value
        ? validate(c, required, maxLength(1000))
        : setErrors(c, {}),
    otherTrafficViolationStatement: (c, frm) => frm.controls.otherTrafficViolation.value
        ? validate(c, required, maxLength(1000))
        : setErrors(c, {})
}