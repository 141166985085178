<header>
    <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white mb-5">
        <div class="container d-flex justify-items-between py-4">
            <a class="navbar-brand" [routerLink]="['/']">
                <img src="../../assets/menu-icon.png" class="menu-icon" />
                <span class="menu-text ml-2">MENU</span>
            </a>
            <a href="https://k9sforwarriors.org">
                <img src="../../assets/k9sforwarriors-logo.png" class="navbar-img"
                alt="image of three horizontal lines to represent a menu icon" />
            </a>
            <ul class="navbar-nav flex-grow">
                <li class="nav-item ml-2" *ngIf="isNotAuthenticated$ | async">
                    <a class="btn btn-primary" target="_blank"
                        href="http://support.k9sforwarriors.org/site/Donation2?df_id=4060&mfc_pref=T&4060.donation=form1">DONATE</a>
                </li>
                <app-login-menu></app-login-menu>
            </ul>
        </div>
    </nav>
</header>
