import { createAction, props } from '@ngrx/store';
import { WarriorApplicationViewModel } from 'src/app/services/application.service';
import { ApplicationSteps } from './application.state';

export const incrementLoading = createAction('[Application] Increment Loading');
export const decrementLoading = createAction('[Application] Decrement Loading');

export const loadApplication = createAction(
    '[Application] Load Application',
    props<{ viewModel: WarriorApplicationViewModel }>()
);

export const savingApplication = createAction(
    '[Application] Saving',
    props<{ saving: boolean }>()
)

export const setCurrentStep = createAction(
    '[Application] Set Current Step',
    props<{ step: ApplicationSteps }>()
);

export const setCurrentStepFromApplicationStep = createAction(
    '[Application] Set Current Step From Application Step');

export const showErrorSummary = createAction(
    '[Application] Show Error Summary');

// File Uploads
export const uploadedHeadshotPhoto = createAction(
    '[Application] Uploaded Headshot Photo');
export const uploadedDD214Form = createAction(
    '[Application] Uploaded DD214 Form');
export const uploadedMentalHealthVerificationForm = createAction(
    '[Application] Uploaded Mental Health Verification Form');
export const uploadedPhysicianReport = createAction(
    '[Application] Uploaded Physician Report');
export const uploadedAgreementToSupportForm = createAction(
    '[Application] Uploaded Agreement To Support Form');

// Previous Addresses
export const addPreviousAddress = createAction(
    '[Application] Add Previous Address');
export const removePreviousAddress = createAction(
    '[Application] Remove Previous Address',
    props<{ index: number }>()
);

// Emergency Contacts
export const addEmergencyContact = createAction(
    '[Application] Add Emergency Contact');
export const removeEmergencyContact = createAction(
    '[Application] Remove Emergency Contact',
    props<{ index: number }>()
);

// Personal References
export const addPersonalReference = createAction(
    '[Application] Add Personal Reference');
export const removePersonalReference = createAction(
    '[Application] Remove Personal Reference',
    props<{ index: number }>()
);

// Household Members
export const addHouseholdMember = createAction(
    '[Application] Add Household Member');
export const removeHouseholdMember = createAction(
    '[Application] Remove Household Member',
    props<{ index: number }>()
)

// Household Service Animals
export const addHouseholdServiceAnimal = createAction(
    '[Application] Add Household Service Animal');
export const removeHouseholdServiceAnimal = createAction(
    '[Application] Remove Household Service Animal',
    props<{ index: number }>()
);
export const clearHouseholdServiceAnimals = createAction(
    '[Application] Clear Household Service Animals');

// Household Pets
export const addHouseholdPet = createAction(
    '[Application] Add Household Pet');
export const removeHouseholdPet = createAction(
    '[Application] Remove Household Pet',
    props<{ index: number }>()
);
export const clearHouseholdPets = createAction(
    '[Application] Clear Household Pets');

// Periods of Service
export const addPeriodOfService = createAction(
    '[Application] Add Period of Service');
export const removePeriodOfService = createAction(
    '[Application] Remove Period of Service',
    props<{ index: number }>()
);

// Service Locations
export const addServiceLocation = createAction(
    '[Application] Add Service Location');
export const removeServiceLocation = createAction(
    '[Application] Remove Service Location',
    props<{ index: number }>()
);

// Deployment Locations
export const addDeploymentLocation = createAction(
    '[Application] Add Deployment Location');
export const removeDeploymentLocation = createAction(
    '[Application] Remove Deployment Location',
    props<{ index: number }>()
);

// Educational Degrees
export const addEducationalDegree = createAction(
    '[Application] Add Educational Degree');
export const removeEducationalDegree = createAction(
    '[Application] Remove Educational Degree',
    props<{ index: number }>()
);

// Medical Diagnoses
export const addMedicalDiagnosis = createAction(
    '[Application] Add Medical Diagnosis');
export const removeMedicalDiagnosis = createAction(
    '[Application] Remove Medical Diagnosis',
    props<{ index: number }>()
);

// Assistive Devices
export const addAssistiveDevice = createAction(
    '[Application] Add Assistive Device');
export const removeAssistiveDevice = createAction(
    '[Application] Remove Assistive Device',
    props<{ index: number }>()
);

// Primary Doctors
export const addPrimaryDoctor = createAction(
    '[Application] Add Primary Doctor');
export const removePrimaryDoctor = createAction(
    '[Application] Remove Primary Doctor',
    props<{ index: number }>()
);

// Mental Health Doctors
export const addMentalHealthDoctor = createAction(
    '[Application] Add Mental Health Doctor');
export const removeMentalHealthDoctor = createAction(
    '[Application] Remove Mental Health Doctor',
    props<{ index: number }>()
);

// Veterinarians
export const addVeterinarian = createAction(
    '[Application] Add Veterinarian');
export const removeVeterinarian = createAction(
    '[Application] Remove Veterinarian',
    props<{ index: number }>()
);
