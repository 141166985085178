import { StateUpdateFns, validate } from "ngrx-forms";
import { required, requiredTrue } from "ngrx-forms/validation";
import { ApplicationForm } from "../application-form.state";

export const CertificationAndSignatureRequiredInputStateFns: StateUpdateFns<ApplicationForm['certificationAndSignature']> = {
    accurateInfo: validate(required, requiredTrue),
    waitingListRemoval: validate(required, requiredTrue),
    notMedicalTreatment: validate(required, requiredTrue),
    noMedicalStaff: validate(required, requiredTrue),
    noFirearms: validate(required, requiredTrue),
    generalBackgroundInvestigation: validate(required, requiredTrue),
    reasonablePrivacyEffort: validate(required, requiredTrue),
    noAggressiveBehavior: validate(required, requiredTrue),
    noIllegalDrugs: validate(required, requiredTrue),
    cancellationAcknowledgement: validate(required, requiredTrue),
    criminalBackgroundInvestigation: validate(required, requiredTrue),
    serviceDogResponsibility: validate(required, requiredTrue)
}


export const CertificationAndSignatureOptionalInputStateFns: StateUpdateFns<ApplicationForm['certificationAndSignature']> = {
}


export const CertificationAndSignatureSetOptionalInputStateFns: StateUpdateFns<ApplicationForm['certificationAndSignature']> = {
}


export const CertificationAndSignatureOptionalInputValidatorStateFns: StateUpdateFns<ApplicationForm['certificationAndSignature']> = {
}