import { StateUpdateFns, updateGroup } from "ngrx-forms";
import { ApplicationForm } from "./application-form.state";
import { BiographicalInfoRequiredInputStateFns } from "./form-input-configurations/biographical-info-configuration";
import { CertificationAndSignatureRequiredInputStateFns } from "./form-input-configurations/certification-signature-configuration";
import { ConsentRequiredInputStateFns } from "./form-input-configurations/consent-configuration";
import { HouseholdInfoRequiredInputStateFns } from "./form-input-configurations/household-info-configuration";
import { LegalHistoryRequiredInputStateFns, LegalHistoryOptionalInputStateFns, LegalHistorySetOptionalInputStateFns, LegalHistoryOptionalInputValidatorStateFns } from "./form-input-configurations/legal-history-configuration";
import { MedicalAndMentalHealthRequiredInputStateFns, MedicalAndMentalHealthOptionalInputStateFns, MedicalAndMentalHealthSetOptionalInputStateFns, MedicalAndMentalHealthOptionalInputValidatorStateFns } from "./form-input-configurations/medical-mental-health-configuration";
import { MilitaryInfoOptionalInputStateFns, MilitaryInfoRequiredInputStateFns, MilitaryInfoSetOptionalInputStateFns } from "./form-input-configurations/military-info-configuration";
import { MiscellaneousOptionalInputStateFns, MiscellaneousOptionalInputValidatorStateFns, MiscellaneousRequiredInputStateFns, MiscellaneousSetOptionalInputStateFns } from "./form-input-configurations/miscellaneous-configuration";
import { PersonalInfoRequiredInputStateFns, PersonalInfoOptionalInputValidatorStateFns, PersonalInfoOptionalInputStateFns, PersonalInfoSetOptionalInputStateFns } from "./form-input-configurations/personal-info-configuration";
import { TransportationRequiredInputStateFns, TransportationOptionalInputStateFns, TransportationSetOptionalInputStateFns, TransportationOptionalInputValidatorStateFns } from "./form-input-configurations/transportation-configuration";
import { WorkAndEducationRequiredInputStateFns, WorkAndEducationOptionalInputStateFns, WorkAndEducationSetOptionalInputStateFns, WorkAndEducationOptionalInputValidatorStateFns } from "./form-input-configurations/work-education-configuration";

export const Validations: StateUpdateFns<ApplicationForm> = {
    personalInfo: updateGroup<ApplicationForm['personalInfo']>(
        PersonalInfoRequiredInputStateFns,
        PersonalInfoOptionalInputStateFns,
        PersonalInfoSetOptionalInputStateFns,
        PersonalInfoOptionalInputValidatorStateFns
    ),

    householdInfo: updateGroup<ApplicationForm['householdInfo']>(
        HouseholdInfoRequiredInputStateFns
    ),

    militaryInfo: updateGroup<ApplicationForm['militaryInfo']>(
        MilitaryInfoRequiredInputStateFns,
        MilitaryInfoOptionalInputStateFns,
        MilitaryInfoSetOptionalInputStateFns
    ),

    biographicalInfo: updateGroup<ApplicationForm['biographicalInfo']>(
        BiographicalInfoRequiredInputStateFns
    ),

    workAndEducation: updateGroup<ApplicationForm['workAndEducation']>(
        WorkAndEducationRequiredInputStateFns,
        WorkAndEducationOptionalInputStateFns,
        WorkAndEducationSetOptionalInputStateFns,
        WorkAndEducationOptionalInputValidatorStateFns
    ),

    transportation: updateGroup<ApplicationForm['transportation']>(
        TransportationRequiredInputStateFns,
        TransportationOptionalInputStateFns,
        TransportationSetOptionalInputStateFns,
        TransportationOptionalInputValidatorStateFns
    ),

    medicalAndMentalHealth: updateGroup<ApplicationForm['medicalAndMentalHealth']>(
        MedicalAndMentalHealthRequiredInputStateFns,
        MedicalAndMentalHealthOptionalInputStateFns,
        MedicalAndMentalHealthSetOptionalInputStateFns,
        MedicalAndMentalHealthOptionalInputValidatorStateFns
    ),

    legalHistory: updateGroup<ApplicationForm['legalHistory']>(
        LegalHistoryRequiredInputStateFns,
        LegalHistoryOptionalInputStateFns,
        LegalHistorySetOptionalInputStateFns,
        LegalHistoryOptionalInputValidatorStateFns
    ),

    miscellaneous: updateGroup<ApplicationForm['miscellaneous']>(
        MiscellaneousRequiredInputStateFns,
        MiscellaneousOptionalInputStateFns,
        MiscellaneousSetOptionalInputStateFns,
        MiscellaneousOptionalInputValidatorStateFns
    ),

    consent: updateGroup<ApplicationForm['consent']>(
        ConsentRequiredInputStateFns
    ),

    certificationAndSignature: updateGroup<ApplicationForm['certificationAndSignature']>(
        CertificationAndSignatureRequiredInputStateFns
    )
};

