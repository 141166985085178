<div class="d-flex justify-content-between align-items-center mb-3">
    <h6 class="text-dark m-0">{{addressLabel}}</h6>
    <button *ngIf="!isCurrentAddress && addressIndex > 0" class="remove-dynamic-item-button" type="button" (click)="handleRemoveButtonClick()">
        <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
    </button>
</div>

<app-warrior-nested-form-input
    inputType="date"
    label="Move In Date"
    [control]="formState.controls.moveInDate">
</app-warrior-nested-form-input>

<app-warrior-nested-form-input
    *ngIf="!isCurrentAddress"
    inputType="date"
    label="Move Out Date"
    [control]="formState.controls.moveOutDate">
</app-warrior-nested-form-input>

<app-warrior-nested-form-input
    label="Street Address"
    [control]="formState.controls.streetAddress">
</app-warrior-nested-form-input>

<app-warrior-nested-form-input
    label="Apartment/Unit/Suite/etc"
    [control]="formState.controls.unitNumber">
</app-warrior-nested-form-input>

<app-warrior-nested-form-input
    label="City"
    [control]="formState.controls.city">
</app-warrior-nested-form-input>

<div class="d-flex justify-content-between">
    <div class="mr-2">
        <app-warrior-nested-form-select
            label="State"
            placeholder="Please Select"
            [control]="formState.controls.stateOrProvince"
            [options]="statesAndTerritories"
            [valueSelector]="getCode"
            [nameSelector]="getName"
        ></app-warrior-nested-form-select>
    </div>

    <div>
        <app-warrior-nested-form-input
            label="Zip Code"
            [control]="formState.controls.postalCode">
        </app-warrior-nested-form-input>
    </div>
</div>

<!-- <app-warrior-nested-form-input
    label="Country"
    [control]="formState.controls.country">
</app-warrior-nested-form-input> -->
