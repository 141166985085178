<div class="border-bottom p-3 mb-0 py-4">
    <h5 class="section-title mb-0">Personal Info</h5>
</div>
<ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState">
        <app-warrior-form-input label="First Name" [control]="formState.controls.firstName"></app-warrior-form-input>
        <app-warrior-form-input label="Last Name" [control]="formState.controls.lastName"></app-warrior-form-input>
        <app-warrior-form-input label="Middle Name" [control]="formState.controls.middleName"></app-warrior-form-input>
        <app-warrior-form-input label="Maiden Name" [control]="formState.controls.maidenName"></app-warrior-form-input>
        <app-warrior-form-input label="Social Security Number" [control]="formState.controls.socialSecurityNumber" placeholder="Social Security Number (XXX-XX-XXXX)"></app-warrior-form-input>
        <app-warrior-form-input inputType="date" label="Date of Birth" [control]="formState.controls.dateOfBirth"></app-warrior-form-input>

        <app-warrior-form-select
            label="Sex/Gender Identity"
            [control]="formState.controls.sexGenderIdentity"
            [options]="genderIdentityOptions">
        </app-warrior-form-select>

        <app-warrior-form-select
            label="Race/Ethnicity Identity"
            [control]="formState.controls.raceEthnicityIdentity"
            [options]="raceEthnicityIdentityOptions">
        </app-warrior-form-select>

        <app-warrior-form-select
            label="Marital/Partnership Status"
            [control]="formState.controls.maritalPartnershipStatus"
            [options]="maritalPartnershipStatusOptions">
        </app-warrior-form-select>

        <app-warrior-form-input
            *ngIf="formState.value.maritalPartnershipStatus === 'Married'"
            label="Spouse Name"
            [control]="formState.controls.spouseName"
        ></app-warrior-form-input>

        <app-warrior-form-input
            *ngIf="formState.value.maritalPartnershipStatus === 'Married'"
            label="Spouse Phone Number"
            [control]="formState.controls.spousePhoneNumber"
        ></app-warrior-form-input>

        <app-warrior-form-input
            *ngIf="formState.value.maritalPartnershipStatus === 'Married'"
            label="Spouse Email Address"
            [control]="formState.controls.spouseEmailAddress"
        ></app-warrior-form-input>

        <app-warrior-form-input inputType="number" label="Height (inches)" [control]="formState.controls.height">
        </app-warrior-form-input>
        <app-warrior-form-input inputType="number" label="Weight (lbs)" [control]="formState.controls.weightPounds">
        </app-warrior-form-input>

        <app-warrior-form-input inputType="phone" label="Home Phone Number"
            [control]="formState.controls.homePhone"></app-warrior-form-input>
        <app-warrior-form-input inputType="phone" label="Cell Phone Number"
            [control]="formState.controls.cellPhone"></app-warrior-form-input>
        <app-warrior-form-input inputType="email" label="Primary Email Address"
            [control]="formState.controls.primaryEmailAddress"></app-warrior-form-input>

        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center p-3 py-4 responsive-form-field">
            <h6 class="text-dark w-50 mb-0">Upload a photo of yourself</h6>
            <p *ngIf="hasUploadedHeadshotPhoto" class="text-completed mb-0">
                <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
            </p>
            <button [disabled]="isUploading" type="button" (click)="uploader.click()" class="btn btn-secondary" type="file">
                <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{isUploading ? 'UPLOADING...' : 'UPLOAD A PICTURE'}}
            </button>
            <input hidden type="file" accept="image/*" #uploader (change)="uploadHeadshot($event)" />
        </div>
    </form>
</ng-container>
<div class="border-bottom px-3 py-4">
    <h5 class="section-title mb-0">Home Addresses</h5>
</div>
<ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState">
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class=" d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <h6 class="text-dark w-25 mb-0">Please include home addresses for the entire last ten (10) years. At least one previous address is required.</h6>
                <div class="d-flex flex-column w-50 border-bottom pb-4 form-field-container">
                    <app-home-address-form [isCurrentAddress]="true" [formState]="formState.controls.currentAddress"></app-home-address-form>
                </div>
            </div>

            <ng-container *ngIf="previousAddresses$ | async as previousAddresses">
                <div class="d-flex flex-column w-50 ml-auto border-bottom mb-3 pb-4 form-field-container"
                    *ngFor="let group of previousAddresses.controls; trackBy: trackByIndex; let i = index">
                    <app-home-address-form
                        [id]="group.id"
                        [formState]="group"
                        [isCurrentAddress]="false"
                        [addressIndex]="i"
                        (removeAddress)="removePreviousAddress($event)">
                    </app-home-address-form>
                </div>
            </ng-container>

            <div class="d-flex justify-content-end">
                <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="addPreviousAddress()">
                    ADD A PREVIOUS ADDRESS
                </button>
            </div>
        </div>

        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Emergency Contacts</h5>
        </div>
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <h6 class="text-dark mb-0">Please provide at least one emergency contact.</h6>

                <div class="d-flex flex-column w-50" *ngIf="emergencyContacts$ | async as emergencyContacts">
                    <div class="border-bottom pb-3 pt-2 form-field-container"
                        *ngFor="let group of emergencyContacts.controls; trackBy: trackByIndex; let i = index">

                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-dark mb-0">Emergency Contact {{i + 1}}</h6>
                            <button *ngIf="i > 0" class="remove-dynamic-item-button" type="button" (click)="removeEmergencyContact(i)">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>
                        </div>

                        <app-warrior-nested-form-input
                            label="Emergency Contact Name"
                            [control]="group.controls.name">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-select
                            label="Relationship to you"
                            [control]="group.controls.relation"
                            [options]="emergencyContactRelationOptions"
                        ></app-warrior-nested-form-select>

                        <app-warrior-nested-form-input
                            label="Phone Number"
                            [control]="group.controls.phoneNumber"
                            inputType="phone">
                        </app-warrior-nested-form-input>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button type="button" (click)="addEmergencyContact()" class="btn btn-secondary">ADD AN EMERGENCY CONTACT</button>
            </div>
        </div>

        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Advocate Contact Info</h5>
        </div>
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <h6 class="text-dark mb-0">Please select an advocate identity.</h6>

                <div class="d-flex flex-column w-50 form-field-container">
                    <p class="text-dark p-small">
                        Identification of Advocate (by advocate is meant a spouse/partner; designated relative, or friend).
                    </p>

                    <app-warrior-nested-form-select
                        label="Advocate Contact Identity"
                        [control]="formState.controls.advocateContactIdentity"
                        [options]="advocateContactIdentityOptions"
                    ></app-warrior-nested-form-select>
                </div>
            </div>

            <div *ngIf="showAdvocateContactInfo" class="d-flex justify-content-end mt-4">
                <div class="w-50 form-field-container ">
                    <p class="text-dark p-small">Please provide the advocate's contact information.</p>

                    <app-warrior-nested-form-input
                        label="Advocate Contact Name"
                        [control]="formState.controls.advocateContactName"
                    ></app-warrior-nested-form-input>

                    <app-warrior-nested-form-input
                        label="Advocate Contact Phone Number"
                        [control]="formState.controls.advocateContactPhoneNumber"
                        inputType="phone"
                    ></app-warrior-nested-form-input>

                    <app-warrior-nested-form-input
                        label="Advocate Contact Email"
                        [control]="formState.controls.advocateContactEmailAddress"
                        inputType="email"
                    ></app-warrior-nested-form-input>
                </div>
            </div>
        </div>

        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Personal References</h5>
        </div>
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <h6 class="text-dark w-25 mb-0">Please provide exactly two personal references.</h6>

                <div class="d-flex flex-column w-50" *ngIf="personalReferences$ | async as personalReferences">
                    <div class="border-bottom pb-3 pt-2 form-field-container"
                        *ngFor="let group of personalReferences.controls; trackBy: trackByIndex; let i = index">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-dark mb-0">Personal Reference {{i + 1}}</h6>
                            <!-- <button *ngIf="i > 0" class="remove-dynamic-item-button" type="button" (click)="removePersonalReference(i)">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button> -->
                        </div>

                        <app-warrior-nested-form-input
                            label="Personal Reference Name"
                            [control]="group.controls.name"
                        ></app-warrior-nested-form-input>

                        <app-warrior-nested-form-input
                            label="Personal Reference Phone Number"
                            [control]="group.controls.phoneNumber"
                            inputType="phone"
                        ></app-warrior-nested-form-input>

                        <app-warrior-nested-form-input
                            label="Personal Reference Email"
                            [control]="group.controls.email"
                            inputType="email"
                        ></app-warrior-nested-form-input>
                    </div>
                </div>
            </div>
            <!-- <div class="d-flex justify-content-end">
                <button type="button" (click)="addPersonalReference()" class="btn btn-secondary">ADD A PERSONAL REFERENCE</button>
            </div> -->
        </div>


        <div class="px-3 py-4">
            <h5 class="section-title mb-0">Please Initial</h5>
            <small class="text-dark">These checkboxes will count as your initial.</small>

            <div class="d-flex mb-1 mt-4">
                <input type="checkbox" [ngrxFormControlState]="formState.controls.personalReferenceContactConsent" id="authorization" name="authorization" class="mr-1">
                <label for="authorization" class="w-100">I authorize K9s For Warriors to contact my
                    personal
                    references listed
                    above and to request of them information about me as an applicant.</label>
            </div>

            <div class="d-flex">
                <input type="checkbox" [ngrxFormControlState]="formState.controls.personalReferenceInfoDisclosureConsent" id="discloseInfo" name="discloseInfo" class="mr-1">
                <label for="discloseInfo" class="w-100">
                    I authorize my personal references to disclose all information about me
                    which may be requested by K9s For Warriors.</label>
            </div>
        </div>

        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Assistance Filling Out Your Application</h5>
            <p class="text-dark p-small mb-0">So that we may give your request for a Service Dog the full
                consideration it deserves,
                your application
                must be in your own words; however, we understand that some applicants may need assistance
                putting their own words into this application form.
            </p>
        </div>

        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-center responsive-form-field">
                <h6 class="text-dark mb-0">Did you have assistance filling out this application?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.applicationAssistanceProvidedIndicator" class="form-check-input" type="radio" name="applicationAssistanceProvidedIndicator" id="applicationAssistanceProvidedIndicator-yes" [value]="true">
                        <label class="form-check-label" for="applicationAssistanceProvidedIndicator-yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.applicationAssistanceProvidedIndicator" class="form-check-input" type="radio" name="applicationAssistanceProvidedIndicator" id="applicationAssistanceProvidedIndicator-no" [value]="false">
                        <label class="form-check-label" for="applicationAssistanceProvidedIndicator-no">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end mt-4" *ngIf="formState.controls.applicationAssistanceProvidedIndicator.value">
                <div class="w-50 form-field-container">
                    <p class="text-dark p-small">
                        Please list the name and contact information
                        of the person who assisted you.
                    </p>
                    <app-warrior-nested-form-input
                        label="Assistant's Name"
                        [control]="formState.controls.applicationAssistantName"
                    ></app-warrior-nested-form-input>

                    <app-warrior-nested-form-select
                        label="Relationship to you"
                        [control]="formState.controls.applicationAssistantRelation"
                        [options]="applicationAssistantRelationOptions"
                    ></app-warrior-nested-form-select>

                    <app-warrior-nested-form-input
                        label="Phone Number"
                        [control]="formState.controls.applicationAssistantPhoneNumber"
                    ></app-warrior-nested-form-input>
                </div>
            </div>
        </div>

        <div class="border-bottom px-3 py-4 d-flex justify-content-end btn-container">
            <button class="btn btn-primary" (click)="continueToNextStep()">CONTINUE TO SECTION 2 - HOUSEHOLD
                INFO</button>
        </div>
    </form>
</ng-container>
