import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormArrayState, FormControlState, FormControlValueTypes, FormGroupState, NgrxValueConverter } from 'ngrx-forms';
import { Observable, Subscription } from 'rxjs';
import { addMentalHealthDoctor, addPrimaryDoctor, addVeterinarian, removeMentalHealthDoctor, removePrimaryDoctor, removeVeterinarian, setCurrentStep } from '../store/application.actions';
import { ApplicationState, ApplicationSteps } from '../store/application.state';
import { ApplicationForm, DoctorOrVeterinarian } from '../store/application-form.state';
import { faTimesCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { StatesAndTerritories } from 'src/app/application/store/reference-data';

type CodeAndName = {
    code: string;
    name: string;
};

@Component({
    selector: 'app-consent',
    templateUrl: './consent.component.html',
    styleUrls: ['./consent.component.css']
})
export class ConsentComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    faTimesCircle: IconDefinition = faTimesCircle;

    statesAndTerritories = Object
        .keys(StatesAndTerritories)
        .map(k => ({ code: k, name: StatesAndTerritories[k] }));

    formState$: Observable<FormGroupState<ApplicationForm['consent']>>;
    primaryDoctors$: Observable<FormArrayState<DoctorOrVeterinarian>>;
    mentalHealthDoctors$: Observable<FormArrayState<DoctorOrVeterinarian>>;
    veterinarians$: Observable<FormArrayState<DoctorOrVeterinarian>>;

    userFirstName?: string;
    userLastName?: string;

    constructor(private store: Store<{ application: ApplicationState }>,
                private authorizeService: AuthorizeService) {
        this.formState$ = store.select(s => s.application.applicationForm.controls.consent);
        this.primaryDoctors$ = store.select(s => s.application.applicationForm.controls.consent.controls.doctors);
        this.mentalHealthDoctors$ = store.select(s => s.application.applicationForm.controls.consent.controls.psychologistsAndPsychiatrists);
        this.veterinarians$ = store.select(s => s.application.applicationForm.controls.consent.controls.veterinarians);
    }

    ngOnInit() {
        this.subscription.add(this.authorizeService.getUser().subscribe(user => {
            this.userFirstName = user.given_name;
            this.userLastName = user.family_name;
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getCode(item: CodeAndName) {
        return item.code;
    }

    getName(item: CodeAndName) {
        return item.name;
        // return `${item.code} - ${item.name}`;
    }

    addPrimaryDoctor() {
        this.store.dispatch(addPrimaryDoctor());
    }

    removePrimaryDoctor(index: number) {
        this.store.dispatch(removePrimaryDoctor({ index }));
    }

    addMentalHealthDoctor() {
        this.store.dispatch(addMentalHealthDoctor());
    }

    removeMentalHealthDoctor(index: number) {
        this.store.dispatch(removeMentalHealthDoctor({ index }));
    }

    addVeterinarian() {
        this.store.dispatch(addVeterinarian());
    }

    removeVeterinarian(index: number) {
        this.store.dispatch(removeVeterinarian({ index }));
    }

    backToPreviousStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.Miscellaneous }));
        window.scrollTo(0,0);
    }

    continueToNextStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.CertificationAndSignature }));
        window.scrollTo(0,0);
    }

    trackByIndex(index, item) {
        return index;
    }

    get userFullName() {
        if (this.userFirstName && this.userLastName) {
            return `${this.userFirstName} ${this.userLastName}`;
        } else if (this.userFirstName) {
            return this.userFirstName;
        } else if (this.userLastName) {
            return this.userLastName;
        } else {
            return null;
        }
    }

    selectValueConverter: NgrxValueConverter<string, string> = {
        convertViewToStateValue(value: string): string {
            if (!value)
                return null;
            return value;
        },

        convertStateToViewValue(value: string): string {
            if (!value)
                return '';
            return value;
        }
    }

    isInvalid<TValue extends FormControlValueTypes>(control: FormControlState<TValue>): boolean {
        return control.isTouched && control.isInvalid
    }
}
