import { Component, Input } from '@angular/core';
import { FormControlState, NgrxValueConverter } from 'ngrx-forms';

interface ValueOrNameSelector<T, TOut> {
    (input: T): TOut;
}

@Component({
    selector: 'app-warrior-nested-form-select',
    templateUrl: './warrior-nested-form-select.component.html',
    styleUrls: ['./warrior-nested-form-select.component.css']
})
export class WarriorNestedFormSelectComponent<TOption> {
    @Input() label: string;
    @Input() placeholder?: string = 'Please select';
    @Input('aria-label') ariaLabel?: string;
    @Input() control: FormControlState<string>
    @Input() options: TOption[]
    @Input() valueSelector?: ValueOrNameSelector<TOption, string>
    @Input() nameSelector?: ValueOrNameSelector<TOption, string>

    constructor() { }

    getValue(option: TOption): string {
        if (this.valueSelector) {
            return this.valueSelector(option);
        } else {
            return `${option}`;
        }
    }

    getName(option: TOption): string {
        if (this.nameSelector) {
            return this.nameSelector(option);
        } else {
            return `${option}`;
        }
    }

    trackByValue(index, item) {
        if (this.valueSelector) {
            return this.valueSelector(item);
        } else {
            return index;
        }
    }

    selectValueConverter: NgrxValueConverter<string, string> = {
        convertViewToStateValue(value: string): string {
            if (!value)
                return null;
            return value;
        },

        convertStateToViewValue(value: string): string {
            if (!value)
                return '';
            return value;
        }
    }
}
