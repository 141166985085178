import { setErrors, setUserDefinedProperty, setValue, StateUpdateFns, validate } from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import { ApplicationForm } from "../application-form.state";

export const TransportationRequiredInputStateFns: StateUpdateFns<ApplicationForm['transportation']> = {
    currentAndValidDriversLicense: validate(required),
    driveYourself: validate(required),
    transportationAccess: validate(required),
    adaptiveVehicle: validate(required),
}


export const TransportationOptionalInputStateFns: StateUpdateFns<ApplicationForm['transportation']> = {
    primaryDriver: (c, frm) => !frm.controls.driveYourself.value
        ? c
        : setValue(c, null),
    primaryTravelMeans: (c, frm) => !frm.controls.transportationAccess.value
        ? c
        : setValue(c, null),
    adaptiveExplanation: (c, frm) => frm.controls.adaptiveVehicle.value
        ? c
        : setValue(c, null)
}


export const TransportationSetOptionalInputStateFns: StateUpdateFns<ApplicationForm['transportation']> = {
    primaryDriver: (c, frm) => !frm.controls.driveYourself.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    primaryTravelMeans: (c, frm) => !frm.controls.transportationAccess.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    adaptiveExplanation: (c, frm) => frm.controls.adaptiveVehicle.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true)
}


export const TransportationOptionalInputValidatorStateFns: StateUpdateFns<ApplicationForm['transportation']> = {
    primaryDriver: (c, frm) => !frm.controls.driveYourself.value
        ? validate(c, required)
        : setErrors(c, {}),
    primaryTravelMeans: (c, frm) => !frm.controls.transportationAccess.value
        ? validate(c, required)
        : setErrors(c, {}),
    adaptiveExplanation: (c, frm) => frm.controls.adaptiveVehicle.value
        ? validate(c, required)
        : setErrors(c, {})
}