import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

@Component({
    selector: 'app-application-submitted',
    templateUrl: './application-submitted.component.html',
    styleUrls: ['./application-submitted.component.css']
})
export class ApplicationSubmittedComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    userFirstName?: string;

    constructor(private authorizeService: AuthorizeService) { }

    ngOnInit() {
        this.subscription.add(this.authorizeService.getUser().subscribe(user => {
            this.userFirstName = user.given_name;
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
