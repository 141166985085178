import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateAccountRequest, CreateAccountResponse } from '../models/account/create-account-request';
import { BadRequestResponse } from '../models/common';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    constructor(private http: HttpClient) { }

    public async getUserInfo() {
        return await this.http
            .get<{ type: string, value: string }[]>('/api/account/user-info')
            .toPromise();
    }

    public async getImpersonatableUsers(): Promise<({ id: string, name: string })[]> {
        return await this.http
            .get<({ id: string, name: string })[]>('/api/account/impersonate/users')
            .toPromise();
    }

    public async impersonate(userId: string) {
        return await this.http
            .post<null>(`/api/account/impersonate/${userId}`, null)
            .toPromise();
    }

    public async createAccount(request: CreateAccountRequest): Promise<CreateAccountResponse | BadRequestResponse> {
        try {
            return await this.http
                .post<CreateAccountResponse>('/api/account/create', request)
                .toPromise();
        } catch (err) {
            if (isHttpErrorResponse(err) && err.status === 400) {
                return { errors: err.error[''] };
            } else {
                throw err;
            }
        }
    }
}

function isHttpErrorResponse(err: any): err is HttpErrorResponse {
    return err && err.name && err.name === 'HttpErrorResponse';
}
