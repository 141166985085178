import { ApplicationForm } from "../application/store/application-form.state";
import { ApplicationSteps } from "../application/store/application.state";

export type StepMetadata = { index: number, name: string, step: ApplicationSteps };

export const STEP_METADATA: { [k in keyof ApplicationForm]: StepMetadata } = {
    personalInfo: {
        index: 1,
        name: 'Personal Info',
        step: ApplicationSteps.PersonalInfo
    },
    householdInfo: {
        index: 2,
        name: 'Household Info',
        step: ApplicationSteps.HouseholdInfo
    },
    militaryInfo: {
        index: 3,
        name: 'Military Info',
        step: ApplicationSteps.MilitaryInfo
    },
    biographicalInfo: {
        index: 4,
        name: 'Biographical Info',
        step: ApplicationSteps.BiographicalInfo
    },
    workAndEducation: {
        index: 5,
        name: 'Work & Education',
        step: ApplicationSteps.WorkAndEducation
    },
    transportation: {
        index: 6,
        name: 'Transportation',
        step: ApplicationSteps.Transportation
    },
    medicalAndMentalHealth: {
        index: 7,
        name: 'Medical & Mental Health',
        step: ApplicationSteps.MedicalAndMentalHealth
    },
    legalHistory: {
        index: 8,
        name: 'Legal History',
        step: ApplicationSteps.LegalHistory
    },
    miscellaneous: {
        index: 9,
        name: 'Miscellaneous',
        step: ApplicationSteps.Miscellaneous
    },
    consent: {
        index: 10,
        name: 'Consent',
        step: ApplicationSteps.Consent
    },
    certificationAndSignature: {
        index: 11,
        name: 'CERTIFICATION AND SIGNATURE',
        step: ApplicationSteps.CertificationAndSignature
    }
};
