<div class="border-bottom px-3 py-4">
    <h5 class="section-title mb-0">Work Information</h5>
</div>
<ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState">
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Are you presently employed?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.currentlyEmployed" class="form-check-input" type="radio" name="currentlyEmployed" id="currentlyEmployed-yes" [value]="true">
                    <label class="form-check-label" for="currentlyEmployed-yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.currentlyEmployed" class="form-check-input" type="radio" name="currentlyEmployed" id="currentlyEmployed-no" [value]="false">
                    <label class="form-check-label" for="currentlyEmployed-no">
                        No
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="presentlyEmployed">
            <app-warrior-form-input
                label="Employer Name"
                [control]="formState.controls.employerName"
            ></app-warrior-form-input>

            <app-warrior-form-input
                label="Job Title"
                [control]="formState.controls.jobTitle"
            ></app-warrior-form-input>

            <app-warrior-form-input
                label="Work Environment"
                sublabel="Please describe your work environment (large/small office, high rise, downtown, suburban, rural location, indoors, outdoors, etc.)"
                placeholder="Describe your work environment"
                [control]="formState.controls.workEnvironment"
                inputType="textarea"
            ></app-warrior-form-input>

            <app-warrior-form-input
                label="Job Functions"
                sublabel="Please describe your job function(s)."
                placeholder="Describe your job functions"
                [control]="formState.controls.jobFunctions"
                inputType="textarea"
            ></app-warrior-form-input>

            <app-warrior-form-input
                label="Typical Workday"
                sublabel="As accurately as possible, please describe your typical workday."
                placeholder="Describe your typical workday"
                [control]="formState.controls.typicalWorkday"
                inputType="textarea"
            ></app-warrior-form-input>

            <div
                class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
                <h6 class="text-dark mb-0">Is your employer aware of your decision to apply for a service dog?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input class="form-check-input" type="radio" name="employerAware" id="employerAware_Yes"
                            [ngrxFormControlState]="formState.controls.employerAwareOfApplicationForServiceDog"
                            [value]="true">
                        <label class="form-check-label" for="employerAware_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="employerAware" id="employerAware_No"
                            [ngrxFormControlState]="formState.controls.employerAwareOfApplicationForServiceDog"
                            [value]="false">
                        <label class="form-check-label" for="employerAware_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field"
            *ngIf="presentlyEmployed === false">
            <h6 class="text-dark mb-0">Do you plan on becoming employed?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.planToBecomeEmployed" class="form-check-input" type="radio" name="planToBecomeEmployed" id="planToBecomeEmployed-yes" [value]="true">
                    <label class="form-check-label" for="planToBecomeEmployed-yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.planToBecomeEmployed" class="form-check-input" type="radio" name="planToBecomeEmployed" id="planToBecomeEmployed-no" [value]="false">
                    <label class="form-check-label" for="planToBecomeEmployed-no">
                        No
                    </label>
                </div>
            </div>
        </div>
        <div class="px-3 py-4">
            <div class="alert-attention">
                <div class="d-flex align-items-start">
                    <fa-icon [icon]="faExclamationCircle" class="text-yellow mr-3"></fa-icon>
                    <input [ngrxFormControlState]="formState.controls.employerAccommodationAcknowledgment" type="checkbox" id="employerAccommodationAcknowledgment" name="employerAccommodationAcknowledgment" class="mr-1">
                    <label for="employerAccommodationAcknowledgment" class="w-100">
                        If you will need your employer to modify or
                        provide an exception to any policies so that you may be accompanied at work by your Service Dog, it
                        behooves you to begin the interactive process of arranging reasonable accommodation(s) at least 90 days in
                        advance of your training. For example, many of our Warriors have reasonable accommodations in their workplace
                        that allow them to be accompanied by a Service Dog despite a no pets policy, have a crate in their workspace,
                        and/or take additional breaks. K9s For Warriors does not request reasonable accommodations for veterans;
                        however, It is your responsibility to request accommodations from your employer 90 days prior to training.</label>
                </div>
            </div>
        </div>
        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Education Information</h5>
        </div>
        <app-warrior-form-select
            label="Highest level of education"
            [control]="formState.controls.educationLevel"
            [options]="educationLevelOptions">
        </app-warrior-form-select>
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <h6 class="text-dark mb-0">Educational Degrees</h6>

                <div class="d-flex flex-column w-50" *ngIf="educationalDegrees$ | async as educationalDegrees">
                    <div class="border-bottom pb-4 form-field-container"
                        *ngFor="let group of educationalDegrees.controls; trackBy: trackByIndex; let i = index">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-dark m-0">Degree {{i + 1}}</h6>
                            <button type="button" class="remove-dynamic-item-button" (click)="removeEducationalDegree(i)">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>

                        </div>

                        <app-warrior-nested-form-input
                            label="Name of Degree"
                            [control]="group.controls.name">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input
                            label="Date of Degree"
                            [control]="group.controls.date"
                            inputType="date">
                        </app-warrior-nested-form-input>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <button type="button" (click)="addEducationalDegree()" class="btn btn-secondary">ADD DEGREE</button>
            </div>
        </div>
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Are you presently a student?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.currentlyStudent" class="form-check-input" type="radio" name="currentlyStudent" id="currentlyStudent_Yes" [value]="true">
                    <label class="form-check-label" for="currentlyStudent_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.currentlyStudent" class="form-check-input" type="radio" name="currentlyStudent" id="currentlyStudent_No" [value]="false">
                    <label class="form-check-label" for="currentlyStudent_No">
                        No
                    </label>
                </div>
            </div>
        </div>

        <app-warrior-form-input
            *ngIf="formState.controls.currentlyStudent.value"
            label="Where do you attend school?"
            [control]="formState.controls.currentEducationalInstitution">
        </app-warrior-form-input>

        <div *ngIf="formState.controls.currentlyStudent.value"
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Where do you attend classes?</h6>
            <div class="d-flex responsive-form-field">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.currentEducationAttendanceMode" class="form-check-input" type="radio"
                    name="currentEducationAttendanceMode" id="Both Online and on campus" value="Both Online and On Campus">
                    <label class="form-check-label" for="Both Online and on campus">
                        Both Online and on campus
                    </label>
                </div>
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.currentEducationAttendanceMode" class="form-check-input" type="radio"
                    name="currentEducationAttendanceMode" id="Online" value="Online">
                    <label class="form-check-label" for="Online">
                        Online
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.currentEducationAttendanceMode" class="form-check-input" type="radio"
                    name="currentEducationAttendanceMode" id="On campus" value="On Campus">
                    <label class="form-check-label" for="On campus">
                        On campus
                    </label>
                </div>
            </div>
        </div>

        <app-warrior-form-input
            *ngIf="formState.controls.currentlyStudent.value"
            label="What degree are you pursuing?"
            [control]="formState.controls.currentEducationDegreeType">
        </app-warrior-form-input>

        <div class="d-flex px-3 py-4" *ngIf="formState.controls.currentlyStudent.value">
            <input [ngrxFormControlState]="formState.controls.educationServiceDogPolicyAcknowledgement" type="checkbox" id="educationServiceDogPolicyAcknowledgement" name="educationServiceDogPolicyAcknowledgement" class="mr-1">
            <label for="educationServiceDogPolicyAcknowledgement" class="w-100">Are you aware of the service dog policy for your school?
                If <strong>YES</strong>, please thoroughly review the policy dictated by your school. If <strong>NO</strong>, please obtain and
                thoroughly review a copy of the policy.</label>
        </div>

        <div class="border-bottom px-3 py-4 d-flex justify-content-between align-items-center btn-container">
            <button class="btn btn-back" (click)="backToPreviousStep()">BACK TO SECTION 4 - BIOGRAPHICAL INFO</button>
            <button class="btn btn-primary" (click)="continueToNextStep()">CONTINUE TO SECTION 6 - TRANSPORTATION</button>
        </div>
    </form>
</ng-container>
