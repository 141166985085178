import { setErrors, setUserDefinedProperty, setValue, StateUpdateFns, updateArray, updateGroup, validate } from "ngrx-forms";
import { greaterThanOrEqualTo, maxLength, notEqualTo, number, required, requiredTrue } from "ngrx-forms/validation";
import { greaterThanDate } from "../custom-validators";
import { ApplicationForm, ServiceLocation, ServicePeriod } from "../application-form.state";
import { initIsOptional } from "./common-functions";
import { MIN_DATE } from "./common-constants";


export const MilitaryInfoRequiredInputStateFns: StateUpdateFns<ApplicationForm['militaryInfo']> = {
    serviceDetailsFoiaConsent: validate(required, requiredTrue),
    branch: validate(required),
    rank: validate(required),
    militaryOccupationalSpecialty: validate(required),
    payGrade: validate(required),
    age: validate(required, number, greaterThanOrEqualTo(18)),
    servicePeriods: updateArray(updateGroup<ServicePeriod>({
        enteredDate: validate(required, notEqualTo(MIN_DATE)),
        dischargeDate: (c, frm) => validate(c,
            required,
            greaterThanDate(frm.controls.enteredDate.value)
        ),
        dischargeType: validate(required),
        dischargeDetails: (c, frm) => frm.value.dischargeType === 'General, Under Honorable Conditions'
            ? validate(c, required)
            : setErrors(c, {})
    })),
    serviceLocations: updateArray(updateGroup<ServiceLocation>({
        startDate: validate(required, notEqualTo(MIN_DATE)),
        endDate: (c, frm) => validate(c,
            required,
            greaterThanDate(frm.controls.startDate.value)
        ),
        location: validate(required)
    })),
    deploymentLocations: updateArray(updateGroup<ServiceLocation>({
        startDate: validate(required, notEqualTo(MIN_DATE)),
        endDate: (c, frm) => validate(c,
            required,
            greaterThanDate(frm.controls.startDate.value)
        ),
        location: validate(required)
    })),
    awardsOrDecorations: validate(maxLength(1000)),
    era: validate(required)
}


export const MilitaryInfoOptionalInputStateFns: StateUpdateFns<ApplicationForm['militaryInfo']> = {
    servicePeriods: updateArray(updateGroup<ServicePeriod>({
        dischargeDetails: (c, frm) => frm.controls.dischargeType.value === 'General, Under Honorable Conditions'
            ? c
            : setValue(c, null)
    }))
}


export const MilitaryInfoSetOptionalInputStateFns: StateUpdateFns<ApplicationForm['militaryInfo']> = {
    awardsOrDecorations: initIsOptional(),
    servicePeriods: updateArray(updateGroup<ServicePeriod>({
        dischargeDetails: (c, frm) => frm.controls.dischargeType.value === 'General, Under Honorable Conditions'
            ? setUserDefinedProperty(c, 'isOptional', false)
            : setUserDefinedProperty(c, 'isOptional', true)
    }))
}


export const MilitaryInfoOptionalInputValidatorStateFns: StateUpdateFns<ApplicationForm['militaryInfo']> = {
}