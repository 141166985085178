import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IconDefinition, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { Observable, Subscription } from 'rxjs';
import { ApplicationForm } from 'src/app/application/store/application-form.state';
import { ApplicationState, ApplicationSteps } from 'src/app/application/store/application.state';
import { STEP_METADATA } from '../step-metadata';

@Component({
    selector: 'app-application-nav-step',
    templateUrl: './application-nav-step.component.html',
    styleUrls: ['./application-nav-step.component.css']
})
export class ApplicationNavStepComponent implements OnInit, OnDestroy {
    @Input() applicationFormSection: keyof ApplicationForm;
    @Output() select = new EventEmitter<ApplicationSteps>();

    isActiveStep: boolean = false;
    progress: number = 0;

    faCheckCircle: IconDefinition = faCheckCircle;

    private subscription = new Subscription();
    private currentStep$: Observable<ApplicationSteps>;
    private formGroupState$: Observable<FormGroupState<ApplicationForm[keyof ApplicationForm]>>

    constructor(private store: Store<{ application: ApplicationState }>) {
        this.currentStep$ = store.select(s => s.application.currentTab)
        this.formGroupState$ = store.select(s => s.application.applicationForm.controls[this.applicationFormSection])
    }

    ngOnInit(): void {
        this.subscription.add(this.currentStep$.subscribe(
            step => this.isActiveStep = step === this.applicationStep));
        this.subscription.add(this.formGroupState$.subscribe(state => {
            this.progress = <number>state.userDefinedProperties['progress'];
        }))
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    handleClick(): void {
        this.select.emit(this.applicationStep);
    }

    get applicationStep(): ApplicationSteps {
        return STEP_METADATA[this.applicationFormSection].step;
    }

    get stepIndex(): number {
        return STEP_METADATA[this.applicationFormSection].index;
    }

    get stepLabel(): string {
        return STEP_METADATA[this.applicationFormSection].name;
    }

    get tooltipText(): string {
        return `${this.progress.toFixed(0)}% Complete`;
    }

    get styleWidth() { return `${this.progress}%` };
}
