<li class="nav-item border-bottom" (click)="handleClick()">
    <a class="nav-link d-flex flex-column pb-0" [class.active]="isActiveStep"
        tabindex="-1" aria-disabled="true">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <small>Section {{stepIndex}}</small>
                <p>{{stepLabel}}</p>
            </div>
            <fa-icon [icon]="faCheckCircle" *ngIf="progress === 100" class="text-progress"></fa-icon>
        </div>
        <div class="progress mx-0" [title]="tooltipText">
            <div class="progress-bar bg-progress" role="progressbar" [attr.aria-valuenow]="progress" aria-valuemin="0" aria-valuemax="100" [style.width]="styleWidth"></div>
        </div>
    </a>
</li>
