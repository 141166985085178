import { FormGroupState, InferenceWrapper, InferredFormState, setUserDefinedProperty, StateUpdateFns } from "ngrx-forms";
import { ApplicationForm } from '../store/application-form.state';

function computeProgress<T>(formGroup: FormGroupState<T>) {
    const requiredFields = getTotalFields(formGroup);
    const errorFields = getErrorFields(formGroup);

    const total = requiredFields.length;
    const errors = errorFields.length;

    return (total - errors) / total * 100;
}

function getTotalFields(formGroup: FormGroupState<any>) {
    let fields = [];

    for (let controlId in formGroup.controls) {
        const control = formGroup.controls[controlId];

        if (control.userDefinedProperties['isOptional'] === true) {
            continue;
        }

        if ((<any>control).controls) {
            const controls = (<any>control).controls;

            if (Array.isArray(controls)) {
                for (let item of controls) {
                    for (let subItem of getTotalFields(item)) {
                        fields.push(subItem);
                    }
                }
            } else {
                for (let subItem in getTotalFields(controls)) {
                    fields.push(subItem);
                }
            }
        } else {
            fields.push(control);
        }
    }

    return fields;
}

function getErrorFields(formGroup: FormGroupState<any>) {
    let fields = [];

    for (let controlId in formGroup.controls) {
        const control = formGroup.controls[controlId];

        if (control.userDefinedProperties['isOptional'] === true) {
            continue;
        }

        if ((<any>control).controls) {
            const controls = (<any>control).controls;

            if (Array.isArray(controls)) {
                for (let item of controls) {
                    for (let subItem of getErrorFields(item)) {
                        fields.push(subItem);
                    }
                    // errors += getErrorFields(item);
                }
            } else {
                for (let subItem of getErrorFields(controls)) {
                    fields.push(subItem);
                }
                // errors += getErrorFields(controls)
            }
        } else {
            if (Object.keys(control.errors).length) {
                fields.push(control);
                // errors++;
            }
        }
    }

    return fields;
}

function setProgress<T>(formGroup: FormGroupState<T>, _: FormGroupState<ApplicationForm>): InferredFormState<InferenceWrapper<T>> {
    return setUserDefinedProperty('progress', computeProgress(formGroup))(formGroup);
}


export const FormProgress: StateUpdateFns<ApplicationForm> = {
    personalInfo: setProgress,
    householdInfo: setProgress,
    militaryInfo: setProgress,
    biographicalInfo: setProgress,
    workAndEducation: setProgress,
    transportation: setProgress,
    medicalAndMentalHealth: setProgress,
    legalHistory: setProgress,
    miscellaneous: setProgress,
    consent: setProgress,
    certificationAndSignature: setProgress
}
