<div class="border-bottom px-3 py-4">
    <h5 class="section-title mb-0">CERTIFICATION AND SIGNATURE</h5>
</div>
<ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState">
        <div class="d-flex px-3 py-4">
            <input [ngrxFormControlState]="formState.controls.accurateInfo" id="acknowledgement_accurateInfo"
                type="checkbox" class="mr-1">
            <label for="acknowledgement_accurateInfo" class="mb-4 w-100 w-100">I certify that, to the best of my knowledge and belief,
                the information
                provided in this document truly represents
                my needs and present situation. I understand that my failure to provide complete, accurate, and honest
                information
                herein will permanently disqualify me from the K9s For Warriors, Inc’s (“K9s”) program and will result in my
                immediate removal from either the program or waiting list.</label>
        </div>

        <div class="d-flex px-3 py-4">
            <input [ngrxFormControlState]="formState.controls.waitingListRemoval" id="acknowledgement_waitingListRemoval"
                type="checkbox" class="mr-1">
            <label for="acknowledgement_waitingListRemoval" class="mb-4 w-100">I understand that K9s For Warriors reserves the right to remove
                any accepted, or scheduled, applicant/candidate
                from the waiting list, at any time, for any reason.</label>
        </div>

        <div class="d-flex px-3 py-4">
            <input [ngrxFormControlState]="formState.controls.notMedicalTreatment" id="acknowledgement_notMedicalTreatment"
                type="checkbox" class="mr-1">
            <label for="acknowledgement_notMedicalTreatment" class="mb-4 w-100">I understand that K9s For Warriors is NOT a medical treatment
                facility, hospital, mental health facility, or any
                other variant of the previously listed. While the use of a service canine may mitigate symptoms related to
                PTSD,
                TBI, and/or MST (and we encourage you to do your research), K9s For Warriors is ONLY a service dog agency
                providing a trained, certified service dog and training related to how to use a service dog, we do not
                provide any
                healthcare whatsoever.</label>
        </div>

        <div class="d-flex px-3 py-4">
            <input [ngrxFormControlState]="formState.controls.noMedicalStaff" id="acknowledgement_noMedicalStaff"
                type="checkbox" class="mr-1">
            <label for="acknowledgement_noMedicalStaff" class="mb-4 w-100">K9s For Warriors does not staff medical professionals of any
                kind on campus or during the three- week program. In
                the event of an emergency while on campus or off-site conducting training, emergency medical services (911)
                will
                be called, and you are responsible for any financial costs related to that.</label>
        </div>

        <div class="d-flex px-3 py-4">
            <input [ngrxFormControlState]="formState.controls.noFirearms" id="acknowledgement_noFirearms"
                type="checkbox" class="mr-1">
            <label for="acknowledgement_noFirearms" class="mb-4 w-100">I understand that as an accepted applicant, I am not allowed to
                bring firearms onto K9s For Warriors’ property.
                Furthermore, while attending K9s For Warriors, I am not authorized to obtain firearms from any source(s).
                Possession of, or obtaining of, firearms or other weapons (mace, tasers, etc.) will result in immediate
                dismissal
                from the program.</label>
        </div>

        <div class="d-flex px-3 py-4">
            <input [ngrxFormControlState]="formState.controls.generalBackgroundInvestigation" id="acknowledgement_generalBackgroundInvestigation"
            type="checkbox" class="mr-1">
            <label for="acknowledgement_generalBackgroundInvestigation" class="mb-4 w-100"> I understand that K9s must make some investigation into my
                background, and I hereby authorize K9s to research
                and/or confirm any statements made in this document and further permit educational institutions, employers,
                medical professionals, criminal justice agencies, and others to furnish whatever detail or documentation is
                available concerning this application and the statements I made herein.</label>
        </div>

        <div class="d-flex px-3 py-4">
            <input [ngrxFormControlState]="formState.controls.reasonablePrivacyEffort" id="acknowledgement_reasonablePrivacyEffort"
            type="checkbox" class="mr-1">
            <label for="acknowledgement_reasonablePrivacyEffort" class="mb-4 w-100">I further acknowledge that K9s is not a healthcare provider and
                is not subject to the privacy rules contained
                in the Health Insurance Portability and Accountability Act (“HIPAA”) and/or other state or federal privacy
                laws.
                Though these laws do not apply to K9s, I understand that K9s will make reasonable efforts to keep the
                contents of
                this application, any supporting documentation, and/or any information discovered
                during K9s’ verification process confidential and will not share such information outside of K9s
                without my written consent.</label>
        </div>

        <div class="d-flex px-3 py-4">
            <input [ngrxFormControlState]="formState.controls.noAggressiveBehavior" id="acknowledgement_noAggressiveBehavior"
                 type="checkbox" class="mr-1">
            <label for="acknowledgement_noAggressiveBehavior" class="mb-4 w-100">I acknowledge that K9s For Warriors will not tolerate behavior
                that is construed as aggressive. Aggressive
                behavior, behavior that intimidates others whether verbally or physically, will be grounds for immediate
                dismissal
                from K9s For Warriors. This policy applies to Warriors, staff, volunteers both on campus and off-site during
                the
                training day.</label>
        </div>

        <div class="d-flex px-3 py-4">
            <input [ngrxFormControlState]="formState.controls.noIllegalDrugs" id="acknowledgement_noIllegalDrugs"
                type="checkbox" class="mr-1">
            <label for="acknowledgement_noIllegalDrugs" class="mb-4 w-100">I understand that illegal drugs, prescription drugs not
                prescribed to me, or other innocuous substances masked to
                be intoxicants are a violation of policy and will result in immediate dismissal from the program and
                potential
                prosecution by local authorities.</label>
        </div>

        <div class="d-flex px-3 py-4">
            <input [ngrxFormControlState]="formState.controls.cancellationAcknowledgement" id="acknowledgement_cancellationAcknowledgement"
                type="checkbox" class="mr-1">
            <label for="acknowledgement_cancellationAcknowledgement" class="mb-4 w-100">I understand that if I am accepted to the program, scheduled,
                placed on the waitlist, and then cancel my
                attendance within 60-days of my class start date, I may be ineligible to reapply. Considerations for
                rescheduling
                will be taken on a case-by-case basis.</label>
        </div>

        <div class="d-flex px-3 py-4">
            <input [ngrxFormControlState]="formState.controls.criminalBackgroundInvestigation" id="acknowledgement_criminalBackgroundInvestigation"
                type="checkbox" class="mr-1">
            <label for="acknowledgement_criminalBackgroundInvestigation" class="mb-4 w-100">My signature below further authorizes K9s to obtain criminal
                background information for determining my ability to
                care for and protect a service dog if provided by K9s. Please be advised that K9s For Warriors may request
                multiple background investigations at intervals of our discretion. A photographic or facsimile copy of this
                authorization bearing a photographic facsimile copy of the signature of the undersigned may be deemed to be
                equivalent to the original hereof and may be used as a duplicate original.</label>
        </div>

        <div class="d-flex px-3 py-4">
            <input [ngrxFormControlState]="formState.controls.serviceDogResponsibility" id="acknowledgement_serviceDogResponsibility"
                type="checkbox" class="mr-1">
            <label for="acknowledgement_serviceDogResponsibility" class="mb-4 w-100">Lastly, having a service dog is a great commitment. A service
                dog is not a pet, and as such requires a great deal
                of additional time, effort, and energy. Your signature denotes you being willing, able and prepared to take
                on
                this added responsibility.</label>
        </div>

        <div class="alert-required ml-4 mr-4" *ngIf="showErrorSummary">
            <strong class="text-primary">Please Correct the Following Errors Before Submitting</strong>
            <ul class="text-dark alert-text mb-0">
                <li *ngFor="let error of errors" [innerHTML]="error"></li>
            </ul>
        </div>


        <div class="border-bottom px-3 py-4 d-flex justify-content-between align-items-center btn-container">
            <button class="btn btn-back" (click)="backToPreviousStep()">BACK TO SECTION 10 - CONSENT</button>
            <button type="button" class="btn btn-primary" (click)="submitApplication()">
                <span *ngIf="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{isSubmitting ? 'SUBMITTING...' : 'SUBMIT YOUR APPLICATION'}}
                <fa-icon *ngIf="!isSubmitting" [icon]="faArrowRight" class="ml-2"></fa-icon>
            </button>
        </div>
    </form>
</ng-container>
