import { createAction, createReducer, on } from '@ngrx/store';
import { addArrayControl, onNgrxForms, removeArrayControl, setValue, SetValueAction, StateUpdateFns, updateGroup, wrapReducerWithFormStateUpdate } from 'ngrx-forms';

import { ApplicationState, ApplicationSteps, initialState  } from './application.state';
import { ApplicationForm, DoctorOrVeterinarian, HomeAddress, HouseholdAnimal } from '../store/application-form.state';
import * as A from './application.actions';
import { Validations } from './application.form-validations';
import { FormProgress } from './application.form-metadata';
import { WarriorApplicationViewModel } from 'src/app/services/application.service';

let loadingOperations = 0;

type OnLoadApplicationActionPayload = { viewModel: WarriorApplicationViewModel };
type OnLoadApplicationReducer = (state: ApplicationState, payload: OnLoadApplicationActionPayload) => ApplicationState;

const runPostProcessors = updateGroup<ApplicationForm>(
    Validations,
    FormProgress
);

const propertiesOf = <TObj>(_obj: (TObj | undefined) = undefined) => <T extends keyof TObj>(name: T): T => name;

const _onLoadApplication: OnLoadApplicationReducer = (state, { viewModel }) => {
    function removeExtraneousServerProperties(serverForm: ApplicationForm): ApplicationForm {
        return serverForm;
        // const newForm = <ApplicationForm>{};

        // return newForm;

        // const localForm = state.applicationForm;
        // const newForm = <ApplicationForm>{};

        // for (let k of Object.keys(localForm.controls)) {
        //     newForm[k] = {};

        //     const subForm = localForm.controls[k].controls;
        //     const serverSubForm = serverForm[k];

        //     for (let sk of Object.keys(serverSubForm)) {
        //         if (subForm[sk]) {
        //             newForm[k][sk] = serverSubForm[sk];
        //         } else {
        //             console.log(`Deleting extraneous form property ${k}.${sk}`);
        //         }
        //     }
        // }

        // return newForm;
    }

    return {
        ...state,
        metadata: viewModel.metadata,
        applicationForm: runPostProcessors(setValue(
            state.applicationForm,
            removeExtraneousServerProperties(viewModel.application)
        ))
    };
};

function updateForm<K extends keyof ApplicationForm>(state: ApplicationState, key: K, fn: StateUpdateFns<ApplicationForm[K]>): ApplicationState {
    const update = { [key]: updateGroup(fn) };
    const applicationForm = updateGroup<ApplicationForm>(update)(state.applicationForm);
    return { ...state, applicationForm };
}

function createHomeAddress(): HomeAddress {
    return {
        moveInDate: '',
        moveOutDate: null,
        streetAddress: '',
        unitNumber: '',
        city: '',
        stateOrProvince: '',
        postalCode: ''
    };
}

function createHouseholdAnimal(serviceAnimal: boolean): HouseholdAnimal {
    return {
        species: serviceAnimal ? 'Dog' : null,
        otherSpecies: serviceAnimal ? undefined : '',
        breed: '',
        ageYears: null,
        ageMonths: null,
        weight: null,
        behavioralConcerns: ''
    };
}

function createDoctorOrVeterinarian(): DoctorOrVeterinarian {
    return {
        name: '',
        phone: '',
        streetAddress: '',
        unit: null,
        city: '',
        stateOrProvince: '',
        postalCode: ''
    };
}

const _applicationReducer = createReducer(
    initialState,

    // Form Updates
    onNgrxForms(),

    // Loading
    on(A.incrementLoading, (state) => ({ ...state, loading: ++loadingOperations > 0, })),
    on(A.decrementLoading, (state) => ({ ...state, loading: --loadingOperations > 0, })),

    // Application Actions
    on(A.loadApplication, _onLoadApplication),
    on(A.savingApplication, (state, { saving }) => ({
        ...state,
        saving
    })),
    on(A.setCurrentStep, (state, { step }) => ({
        ...state,
        currentTab: step,
        metadata: {
            ...state.metadata,
            applicationStep: step
        }
    })),
    on(A.setCurrentStepFromApplicationStep, (state) => ({
        ...state,
        currentTab: <ApplicationSteps>state.metadata.applicationStep
    })),
    on(A.showErrorSummary, (state) => ({
        ...state,
        showErrorSummary: true
    })),
    on(createAction(SetValueAction.TYPE), (state) => ({
        ...state,
        showErrorSummary: false
    })),

    // File Uploads
    on(A.uploadedHeadshotPhoto, (state) => updateForm(state, 'personalInfo', {
        hasUploadedHeadshotPhoto: setValue(true)
    })),
    on(A.uploadedDD214Form, (state) => updateForm(state, 'personalInfo', {
        has_uploaded_discharge_document_214_form: setValue(true)
    })),
    on(A.uploadedAgreementToSupportForm, (state) => updateForm(state, 'personalInfo', {
        hasUploadedAgreementToSupportForm: setValue(true)
    })),
    on(A.uploadedMentalHealthVerificationForm, (state) => updateForm(state, 'medicalAndMentalHealth', {
        hasUploadedMentalHealthVerificationForm: setValue(true)
    })),
    on(A.uploadedPhysicianReport, (state) => updateForm(state, 'medicalAndMentalHealth', {
        hasUploadedPhysicianReport: setValue(true)
    })),

    // Previous Addresses
    on(A.addPreviousAddress, (state) => updateForm(state, 'personalInfo', {
        previousAddresses: addArrayControl(createHomeAddress())
    })),
    on(A.removePreviousAddress, (state, { index }) => updateForm(state, 'personalInfo', {
        previousAddresses: removeArrayControl(index)
    })),

    // Emergency Contacts
    on(A.addEmergencyContact, (state) => updateForm(state, 'personalInfo', {
        emergencyContacts: addArrayControl({ name: '', relation: '', phoneNumber: '' })
    })),
    on(A.removeEmergencyContact, (state, { index }) => updateForm(state, 'personalInfo', {
        emergencyContacts: removeArrayControl(index)
    })),

    // Personal References
    on(A.addPersonalReference, (state) => updateForm(state, 'personalInfo', {
        personalReferences: addArrayControl({ name: '', phoneNumber: '', email: '' })
    })),
    on(A.removePersonalReference, (state, { index }) => updateForm(state, 'personalInfo', {
        personalReferences: removeArrayControl(index)
    })),

    // Household Members
    on(A.addHouseholdMember, (state) => updateForm(state, 'householdInfo', {
        householdMembers: addArrayControl({ name: '', relation: '', age: null })
    })),
    on(A.removeHouseholdMember, (state, { index }) => updateForm(state, 'householdInfo', {
        householdMembers: removeArrayControl(index)
    })),

    // Household Service Animals
    on(A.addHouseholdServiceAnimal, (state) => updateForm(state, 'householdInfo', {
        serviceAnimals: addArrayControl(createHouseholdAnimal(true))
    })),
    on(A.removeHouseholdServiceAnimal, (state, { index }) => updateForm(state, 'householdInfo', {
        serviceAnimals: removeArrayControl(index)
    })),
    on(A.clearHouseholdServiceAnimals, (state) => updateForm(state, 'householdInfo', {
        serviceAnimals: setValue([])
    })),

    // Household Pets
    on(A.addHouseholdPet, (state) => updateForm(state, 'householdInfo', {
        pets: addArrayControl(createHouseholdAnimal(false))
    })),
    on(A.removeHouseholdPet, (state, { index }) => updateForm(state, 'householdInfo', {
        pets: removeArrayControl(index)
    })),
    on(A.clearHouseholdPets, (state) => updateForm(state, 'householdInfo', {
        pets: setValue([])
    })),

    // Periods of Service
    on(A.addPeriodOfService, (state) => updateForm(state, 'militaryInfo', {
        servicePeriods: addArrayControl({ enteredDate: null, dischargeDate: null, dischargeType: '' })
    })),
    on(A.removePeriodOfService, (state, { index }) => updateForm(state, 'militaryInfo', {
        servicePeriods: removeArrayControl(index)
    })),

    // Service Locations
    on(A.addServiceLocation, (state) => updateForm(state, 'militaryInfo', {
        serviceLocations: addArrayControl({ startDate: null, endDate: null, location: '' })
    })),
    on(A.removeServiceLocation, (state, { index }) => updateForm(state, 'militaryInfo', {
        serviceLocations: removeArrayControl(index)
    })),

    // Deployment Locations
    on(A.addDeploymentLocation, (state) => updateForm(state, 'militaryInfo', {
        deploymentLocations: addArrayControl({ startDate: null, endDate: null, location: '' })
    })),
    on(A.removeDeploymentLocation, (state, { index }) => updateForm(state, 'militaryInfo', {
        deploymentLocations: removeArrayControl(index)
    })),

    // Educational Degrees
    on(A.addEducationalDegree, (state) => updateForm(state, 'workAndEducation', {
        educationalDegrees: addArrayControl({ name: '', date: null })
    })),
    on(A.removeEducationalDegree, (state, { index }) => updateForm(state, 'workAndEducation', {
        educationalDegrees: removeArrayControl(index)
    })),

    // Medical Diagnoses
    on(A.addMedicalDiagnosis, (state) => updateForm(state, 'medicalAndMentalHealth', {
        diagnoses: addArrayControl({ diagnosisName: '', dateOfDiagnosis: null })
    })),
    on(A.removeMedicalDiagnosis, (state, { index }) => updateForm(state, 'medicalAndMentalHealth', {
        diagnoses: removeArrayControl(index)
    })),

    // Assistive Device
    on(A.addAssistiveDevice, (state) => updateForm(state, 'medicalAndMentalHealth', {
        assistedDevices: addArrayControl({ equipmentPiece: '', specificDetails: '' })
    })),
    on(A.removeAssistiveDevice, (state, { index }) => updateForm(state, 'medicalAndMentalHealth', {
        assistedDevices: removeArrayControl(index)
    })),

    // Primary Doctors
    on(A.addPrimaryDoctor, (state) => updateForm(state, 'consent', {
        doctors: addArrayControl(createDoctorOrVeterinarian())
    })),
    on(A.removePrimaryDoctor, (state, { index }) => updateForm(state, 'consent', {
        doctors: removeArrayControl(index)
    })),

    // Mental Health Doctors
    on(A.addMentalHealthDoctor, (state) => updateForm(state, 'consent', {
        psychologistsAndPsychiatrists: addArrayControl(createDoctorOrVeterinarian())
    })),
    on(A.removeMentalHealthDoctor, (state, { index }) => updateForm(state, 'consent', {
        psychologistsAndPsychiatrists: removeArrayControl(index)
    })),

    // Veterinarians
    on(A.addVeterinarian, (state) => updateForm(state, 'consent', {
        veterinarians: addArrayControl(createDoctorOrVeterinarian())
    })),
    on(A.removeVeterinarian, (state, { index }) => updateForm(state, 'consent', {
        veterinarians: removeArrayControl(index)
    }))

    // TODO.JB - Other action/reducers
);



export const applicationReducer = wrapReducerWithFormStateUpdate(
    _applicationReducer,
    s => s.applicationForm,
    runPostProcessors
);
