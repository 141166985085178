import { setErrors, setUserDefinedProperty, setValue, StateUpdateFns, updateArray, updateGroup, validate } from "ngrx-forms";
import { maxLength, number, required, email, requiredTrue, notEqualTo } from "ngrx-forms/validation";
import { ApplicationForm, HomeAddress } from "../application-form.state";
import { MIN_DATE } from "./common-constants";
import { initIsOptional, setIsOptional } from "./common-functions";

export const PersonalInfoRequiredInputStateFns: StateUpdateFns<ApplicationForm['personalInfo']> = {
    firstName: validate(required, maxLength(256)),
    lastName: validate(required, maxLength(256)),
    middleName: validate(maxLength(256)),
    maidenName: validate(maxLength(256)),
    socialSecurityNumber: validate(required),
    dateOfBirth: validate(required),
    sexGenderIdentity: validate(required),
    raceEthnicityIdentity: validate(required),
    maritalPartnershipStatus: validate(required),
    height: validate(required, number),
    weightPounds: validate(required, number),
    homePhone: validate(required, maxLength(14)),
    cellPhone: validate(required, maxLength(14)),
    primaryEmailAddress: validate(required, email),

    hasUploadedHeadshotPhoto: validate(required, requiredTrue),

    currentAddress: updateGroup({
        moveInDate: validate(required, notEqualTo(MIN_DATE)),
        streetAddress: validate(required),
        unitNumber: validate(maxLength(100)),
        city: validate(required),
        stateOrProvince: validate(required, maxLength(2)),
        postalCode: validate(required, maxLength(5))
    }, {
        unitNumber: initIsOptional()
    }),
    previousAddresses: updateArray(updateGroup<HomeAddress>({
        moveInDate: validate(required, notEqualTo(MIN_DATE)),
        moveOutDate: validate(required, notEqualTo(MIN_DATE)),
        streetAddress: validate(required),
        city: validate(required),
        stateOrProvince: validate(required, maxLength(2)),
        postalCode: validate(required, maxLength(5))
    }, {
        unitNumber: initIsOptional()
    })),
    emergencyContacts: updateArray(updateGroup({
        name: validate(required),
        relation: validate(required),
        phoneNumber: validate(required)
    })),

    advocateContactIdentity: validate(required),

    personalReferences: updateArray(updateGroup({
        name: validate(required),
        phoneNumber: validate(required),
        email: validate(required)
    })),
    personalReferenceContactConsent: validate(required, requiredTrue),
    personalReferenceInfoDisclosureConsent: validate(required, requiredTrue),

    applicationAssistanceProvidedIndicator: validate(required)
}


export const PersonalInfoOptionalInputStateFns: StateUpdateFns<ApplicationForm['personalInfo']> = {
    spouseName: (c, frm) => frm.controls.maritalPartnershipStatus.value === 'Married'
        ? c
        : setValue(c, null),
    spousePhoneNumber: (c, frm) => frm.controls.maritalPartnershipStatus.value === 'Married'
        ? c
        : setValue(c, null),
    spouseEmailAddress: (c, frm) => frm.controls.maritalPartnershipStatus.value === 'Married'
        ? c
        : setValue(c, null),

    advocateContactName: (c, frm) => frm.controls.advocateContactIdentity.value !== 'Decline to identify'
        ? c
        : setValue(c, null),
    advocateContactPhoneNumber: (c, frm) => frm.controls.advocateContactIdentity.value !== 'Decline to identify'
        ? c
        : setValue(c, null),
    advocateContactEmailAddress: (c, frm) => frm.controls.advocateContactIdentity.value !== 'Decline to identify'
        ? c
        : setValue(c, null),

    applicationAssistantName: (c, frm) => frm.controls.applicationAssistanceProvidedIndicator.value
        ? c
        : setValue(c, null),
    applicationAssistantRelation: (c, frm) => frm.controls.applicationAssistanceProvidedIndicator.value
        ? c
        : setValue(c, null),
    applicationAssistantPhoneNumber: (c, frm) => frm.controls.applicationAssistanceProvidedIndicator.value
        ? c
        : setValue(c, null)
}


export const PersonalInfoSetOptionalInputStateFns: StateUpdateFns<ApplicationForm['personalInfo']> = {
    middleName: initIsOptional(),
    maidenName: initIsOptional(),

    spouseName: (c, frm) => setIsOptional(c, frm.value.maritalPartnershipStatus !== 'Married'),
    spousePhoneNumber: (c, frm) => setIsOptional(c, frm.value.maritalPartnershipStatus !== 'Married'),
    spouseEmailAddress: (c, frm) => setIsOptional(c, frm.value.maritalPartnershipStatus !== 'Married'),

    advocateContactName: (c, frm) => frm.controls.advocateContactIdentity.value !== 'Decline to identify'
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    advocateContactPhoneNumber: (c, frm) => frm.controls.advocateContactIdentity.value !== 'Decline to identify'
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    advocateContactEmailAddress: (c, frm) => frm.controls.advocateContactIdentity.value !== 'Decline to identify'
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),

    applicationAssistantName: (c, frm) => frm.controls.applicationAssistanceProvidedIndicator.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    applicationAssistantRelation: (c, frm) => frm.controls.applicationAssistanceProvidedIndicator.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    applicationAssistantPhoneNumber: (c, frm) => frm.controls.applicationAssistanceProvidedIndicator.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true)
}


export const PersonalInfoOptionalInputValidatorStateFns: StateUpdateFns<ApplicationForm['personalInfo']> = {
    spouseName: (c, frm) => frm.value.maritalPartnershipStatus === 'Married'
        ? validate(c, required)
        : setErrors(c, {}),
    spousePhoneNumber: (c, frm) => frm.value.maritalPartnershipStatus === 'Married'
        ? validate(c, required)
        : setErrors(c, {}),
    spouseEmailAddress: (c, frm) => frm.value.maritalPartnershipStatus === 'Married'
        ? validate(c, required)
        : setErrors(c, {}),

    advocateContactName: (c, frm) => frm.controls.advocateContactIdentity.value !== 'Decline to identify'
        ? validate(c, required)
        : setErrors(c, {}),
    advocateContactPhoneNumber: (c, frm) => frm.controls.advocateContactIdentity.value !== 'Decline to identify'
        ? validate(c, required)
        : setErrors(c, {}),
    advocateContactEmailAddress: (c, frm) => frm.controls.advocateContactIdentity.value !== 'Decline to identify'
        ? validate(c, required)
        : setErrors(c, {}),

    applicationAssistantName: (c, frm) => frm.controls.applicationAssistanceProvidedIndicator.value
        ? validate(c, required)
        : setErrors(c, {}),
    applicationAssistantRelation: (c, frm) => frm.controls.applicationAssistanceProvidedIndicator.value
        ? validate(c, required)
        : setErrors(c, {}),
    applicationAssistantPhoneNumber: (c, frm) => frm.controls.applicationAssistanceProvidedIndicator.value
        ? validate(c, required)
        : setErrors(c, {}),

    has_uploaded_discharge_document_214_form: initIsOptional(),
    hasUploadedAgreementToSupportForm: initIsOptional()
}
