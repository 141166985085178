<div class="application d-flex mb-5">
    <div class="container shadow d-flex flex-grow-1 p-0 mr-2 mobile-container">
        <ul class="nav border-right flex-column w-25">
            <li class="nav-item border-bottom" name="app-application-intro">
                <a class="nav-link" [ngClass]="{'active': currentTab === 'ApplicationIntro'}" aria-current="page">
                    <small>Landing</small>
                    <p>Application Intro</p>
                </a>
            </li>

            <li class="nav-item border-bottom" *ngIf="!applicationMetadata">
                <a class="nav-link d-flex flex-column pb-0" tabindex="-1" aria-disabled="true">
                    <div class="-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center mb-2">
                            <strong>Loading...</strong>
                            <div class="spinner-grow spinner-grow-sm ml-auto" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div class="progress mx-0">
                        <div class="progress-bar bg-progress" role="progressbar" aria-valuenow="0" aria-valuemin="0"
                            aria-valuemax="0" style="width: 0%"></div>
                    </div>
                </a>
            </li>

            <ng-container *ngIf="!!applicationMetadata">
                <app-application-nav-step applicationFormSection="personalInfo" (select)="setApplicationStep2($event)">
                </app-application-nav-step>

                <app-application-nav-step applicationFormSection="householdInfo" (select)="setApplicationStep2($event)">
                </app-application-nav-step>

                <!-- <app-application-nav-step
                    *ngFor="let step of applicationSteps"
                    [applicationFormSection]="step."
                    (select)="setApplicationStep2($event)">
                </app-application-nav-step> -->

                <app-application-nav-step applicationFormSection="militaryInfo" (select)="setApplicationStep2($event)">
                </app-application-nav-step>

                <app-application-nav-step applicationFormSection="biographicalInfo"
                    (select)="setApplicationStep2($event)">
                </app-application-nav-step>

                <app-application-nav-step applicationFormSection="workAndEducation"
                    (select)="setApplicationStep2($event)">
                </app-application-nav-step>

                <app-application-nav-step applicationFormSection="transportation"
                    (select)="setApplicationStep2($event)">
                </app-application-nav-step>

                <app-application-nav-step applicationFormSection="medicalAndMentalHealth"
                    (select)="setApplicationStep2($event)">
                </app-application-nav-step>

                <app-application-nav-step applicationFormSection="legalHistory" (select)="setApplicationStep2($event)">
                </app-application-nav-step>

                <app-application-nav-step applicationFormSection="miscellaneous" (select)="setApplicationStep2($event)">
                </app-application-nav-step>

                <app-application-nav-step applicationFormSection="consent" (select)="setApplicationStep2($event)">
                </app-application-nav-step>

                <app-application-nav-step applicationFormSection="certificationAndSignature"
                    (select)="setApplicationStep2($event)">
                </app-application-nav-step>
            </ng-container>
        </ul>
        <div class="container p-0 w-100">
            <!-- Application sections  -->
            <app-application-intro *ngIf=" currentTab === 'ApplicationIntro'"></app-application-intro>
            <app-personal-info *ngIf=" currentTab === 'PersonalInfo'"></app-personal-info>
            <app-household-info *ngIf=" currentTab === 'HouseholdInfo'"></app-household-info>
            <app-military-info *ngIf=" currentTab === 'MilitaryInfo'"></app-military-info>
            <app-biographical-info *ngIf=" currentTab === 'BiographicalInfo'"></app-biographical-info>
            <app-work-education *ngIf=" currentTab === 'WorkAndEducation'"></app-work-education>
            <app-transportation *ngIf=" currentTab === 'Transportation'"></app-transportation>
            <app-medical-mental-health *ngIf=" currentTab === 'MedicalAndMentalHealth'"></app-medical-mental-health>
            <app-legal-history *ngIf=" currentTab === 'LegalHistory'"></app-legal-history>
            <app-miscellaneous *ngIf=" currentTab === 'Miscellaneous'"></app-miscellaneous>
            <app-consent *ngIf=" currentTab === 'Consent'"></app-consent>
            <app-certification-signature *ngIf=" currentTab === 'CertificationAndSignature'">
            </app-certification-signature>
        </div>
    </div>
    <div class="required-items w-25 container p-0 shadow flex-shrink-1">
        <div class="p-4 border-bottom" *ngIf="!applicationMetadata">
            <div class="d-flex align-items-center mb-2">
                <strong>Loading...</strong>
                <div class="spinner-grow spinner-grow-sm ml-auto" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!!applicationMetadata">
            <div class="p-4 border-bottom">
                <div class="alert-required">
                    <strong class="text-primary">Required Items</strong>
                    <p class="text-dark alert-text mb-0">
                        Outside of fully completing this application, the items below are required items needed
                        alongside
                        your
                        application. Please make sure that these are completed before the application is submitted.
                    </p>
                </div>
            </div>
            <div class="p-4 border-bottom">
                <p *ngIf="hasUploadedHeadshotPhoto && isHeadshotPhotoValid; else headshotPhotoFailureToUpload" class="text-completed">
                    <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
                </p>
                <ng-template #headshotPhotoFailureToUpload>
                    <p *ngIf="!hasUploadedHeadshotPhoto && !isHeadshotPhotoValid" class="alert-required text-primary">
                        <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>{{ headshotPhotoErrorMsg }}
                    </p>

                    <div *ngIf="hasUploadedHeadshotPhoto && !isHeadshotPhotoValid">
                        <p class="alert-required text-primary">
                            <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>{{ headshotPhotoErrorMsg }}
                        </p>
                        <p class="text-completed">
                            <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
                        </p>
                    </div>
                </ng-template>
                <strong class="text-dark">Attach a headshot</strong>
                <p class="text-dark alert-text mt-1 mb-3">
                    Your headshot should at least be 1000x1000. <br><br>

                    Supported file extensions are as follows: {{ headshotPhotoSupportedExtensions }}.
                </p>
                <input hidden type="file" accept="image/*" #uploader (change)="uploadFile($event, 'Headshot')" />
                <button [disabled]="hasAnyActiveUpload" class="btn btn-block btn-yellow" (click)="uploader.click()">
                    <span *ngIf="isActiveUpload('Headshot')" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span>
                    {{isActiveUpload('Headshot') ? 'UPLOADING...' : 'SELECT PHOTO'}}
                </button>
            </div>
            <div class="p-4 border-bottom">
                <p *ngIf="hasUploadedMentalHealthVerificationForm && isMentalHealthVerificationFormValid; else mentalHealthVerificationFormFailureToUpload" class="text-completed">
                    <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
                </p>
                <ng-template #mentalHealthVerificationFormFailureToUpload>
                    <p *ngIf="!hasUploadedMentalHealthVerificationForm && !isMentalHealthVerificationFormValid" class="alert-required text-primary">
                        <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>{{ mentalHealthVerificationFormErrorMsg }}
                    </p>

                    <div *ngIf="hasUploadedMentalHealthVerificationForm && !isMentalHealthVerificationFormValid">
                        <p class="alert-required text-primary">
                            <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>{{ mentalHealthVerificationFormErrorMsg }}
                        </p>
                        <p class="text-completed">
                            <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
                        </p>
                    </div>
                </ng-template>
                <strong class="text-dark">Mental Health Verification Form</strong>
                <p class="text-dark alert-text mt-1 mb-3">
                    This form is to be completed by your Mental Health Provider. We can accept it from a VA provider, or a provider in a private practice.
                    The provider completing this form <strong><u>must</u></strong> be licensed as a Licensed Clinical Social Worker (LCSW) or higher. Please download/print this form, provide it to your provider to complete,
                    and upload the completed form here. Alternatively, you can submit your application without this form, and you will be given 60 days to complete it. <br><br>

                    Note - K9s For Warriors <strong><u>does not</u></strong> accept VA disability findings or disability rating letters to determine eligibility or acceptance. <br><br>

                    Supported file extensions are as follows: {{ mentalHealthFormSupportedExtensions }}.
                </p>
                <div class="d-flex flex-column justify-content-center">
                    <a href="/assets/Mental Health Verification Form.pdf" target="_blank" download
                       class="btn btn-block btn-yellow mb-3">
                        DOWNLOAD FORM
                    </a>
                    <input hidden type="file" accept="application/pdf,image/*" #uploaderDeux
                           (change)="uploadFile($event, 'MentalHealthVerificationForm')" />
                    <button [disabled]="hasAnyActiveUpload" class="btn btn-block btn-yellow"
                            (click)="uploaderDeux.click()">
                        <span *ngIf="isActiveUpload('MentalHealthVerificationForm')"
                              class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {{isActiveUpload('MentalHealthVerificationForm') ? 'UPLOADING...' : 'UPLOAD MENTAL HEALTH FORM'}}
                    </button>
                </div>
            </div>
            <div class="p-4 border-bottom">
                <p *ngIf="hasUploadedPhysicianReport && isPhysicianReportValid; else physicianReportFailureToUpload" class="text-completed">
                    <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
                </p>
                <ng-template #physicianReportFailureToUpload>
                    <p *ngIf="!hasUploadedPhysicianReport && !isPhysicianReportValid" class="alert-required text-primary">
                        <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>{{ physicianReportErrorMsg }}
                    </p>

                    <div *ngIf="hasUploadedPhysicianReport && !isPhysicianReportValid">
                        <p class="alert-required text-primary">
                            <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>{{ physicianReportErrorMsg }}
                        </p>
                        <p class="text-completed">
                            <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
                        </p>
                    </div>
                </ng-template>
                <strong class="text-dark">Primary Care Physician Report</strong>
                <p class="text-dark alert-text mt-1 mb-3">
                    This form is to be completed by your Primary Care Provider. We can accept it from a VA provider, or a provider in a private practice.
                    Please download/print this form, provide it to your provider to complete, and upload the completed form here. Alternatively, you can submit your application without this form,
                    and you will be given 60 days to complete it. <br><br>

                    Note - K9s For Warriors <strong><u>does not</u></strong> accept VA disability findings or disability rating letters to determine eligibility or acceptance. <br><br>

                    Supported file extensions are as follows: {{ primaryCareFormSupportedExtensions }}.
                </p>
                <div class="d-flex flex-column justify-content-center">
                    <a href="/assets/Primary Care Physician Report.pdf" target="_blank" download class="btn btn-block btn-yellow mb-3">
                        DOWNLOAD FORM
                    </a>
                    <input hidden type="file" accept="application/pdf,image/*" #uploaderTrois
                           (change)="uploadFile($event, 'PrimaryCarePhysicianReport')" />
                    <button [disabled]="hasAnyActiveUpload" class="btn btn-block btn-yellow"
                            (click)="uploaderTrois.click()">
                        <span *ngIf="isActiveUpload('PrimaryCarePhysicianReport')"
                              class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {{isActiveUpload('PrimaryCarePhysicianReport') ? 'UPLOADING...' : 'UPLOAD PHYSICIAN REPORT'}}
                    </button>
                </div>
            </div>
            <div class="p-4 border-bottom">
                <p *ngIf="has_uploaded_discharge_document_214_form && isDD214FormValid; else dd214FormFailureToUpload" class="text-completed">
                    <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
                </p>
                <ng-template #dd214FormFailureToUpload>
                    <p *ngIf="!has_uploaded_discharge_document_214_form && !isDD214FormValid" class="alert-required text-primary">
                        <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>{{ dd214FormErrorMsg }}
                    </p>

                    <div *ngIf="has_uploaded_discharge_document_214_form && !isDD214FormValid">
                        <p class="alert-required text-primary">
                            <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>{{ dd214FormErrorMsg }}
                        </p>
                        <p class="text-completed">
                            <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
                        </p>
                    </div>
                </ng-template>
                <strong class="text-dark">DD-214 / NGB-22 Form</strong>
                <p class="text-dark alert-text mt-1 mb-3">
                    Please upload the most recent Member-4 copy of your DD-214. If you do not have the Member-4 copy of your DD-214, one can be requested by following this <a href="https://www.archives.gov/veterans/military-service-records" target="_blank">link</a>.
                    If you served in the National Guard, rather than a DD-214, we would require a copy of your final NGB-22. If you do not have the NGB-22, one can be requested by following this
                    <a href="https://www.ngbpmc.ng.mil/Service-Records/" target="_blank">link</a>. If you are still serving in any capacity, please submit the application without these forms, and a
                    Warrior Relations Specialist will contact you to discuss next steps. <br><br>

                    Supported file extensions are as follows: {{ dd214FormSupportedExtensions }}.
                </p>
                <div class="d-flex flex-column justify-content-center">
                    <input hidden type="file" accept="application/pdf,image/*" #uploaderQuatre
                           (change)="uploadFile($event, 'DD214Form')" />
                    <button [disabled]="hasAnyActiveUpload" class="btn btn-block btn-yellow"
                            (click)="uploaderQuatre.click()">
                        <span *ngIf="isActiveUpload('DD214Form')"
                              class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {{isActiveUpload('DD214Form') ? 'UPLOADING...' : 'UPLOAD DD-214 FORM'}}
                    </button>
                </div>
            </div>
            <div class="p-4 border-bottom">
                <p *ngIf="hasUploadedAgreementToSupportForm && isAgreementToSupportFormValid; else agreementToSupportFormFailureToUpload" class="text-completed">
                    <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
                </p>
                <ng-template #agreementToSupportFormFailureToUpload>
                    <p *ngIf="!hasUploadedAgreementToSupportForm && !isAgreementToSupportFormValid" class="alert-required text-primary">
                        <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>{{ agreementToSupportFormErrorMsg }}
                    </p>

                    <div *ngIf="hasUploadedAgreementToSupportForm && !isAgreementToSupportFormValid">
                        <p class="alert-required text-primary">
                            <fa-icon [icon]="faTimes" class="mr-1"></fa-icon>{{ agreementToSupportFormErrorMsg }}
                        </p>
                        <p class="text-completed">
                            <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
                        </p>
                    </div>
                </ng-template>
                <strong class="text-dark">Agreement To Support Form</strong>
                <p class="text-dark alert-text mt-1 mb-3">
                    This form is required to be completed by one person, friend or family, who you would trust to care for your service dog on a temporary basis, in the event you need their support.
                    Please download/print this form, provide it to your support person to complete, and upload the completed form here. <br><br>

                    Note - The person you choose as your Agreement to Support person <strong><u>cannot</u></strong> be the same as your Emergency Contact person listed in your application. <br><br>

                    Supported file extensions are as follows: {{ agreementToSupportFormSupportedExtensions }}.
                </p>
                <div class="d-flex flex-column justify-content-center">
                    <a href="/assets/AgreementToSupportForm.pdf" target="_blank" download
                       class="btn btn-block btn-yellow mb-3">
                        DOWNLOAD FORM
                    </a>
                    <input hidden type="file" accept="application/pdf,image/*" #uploaderCinq
                           (change)="uploadFile($event, 'AgreementToSupportForm')" />
                    <button [disabled]="hasAnyActiveUpload" class="btn btn-block btn-yellow"
                            (click)="uploaderCinq.click()">
                        <span *ngIf="isActiveUpload('AgreementToSupportForm')"
                              class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {{isActiveUpload('AgreementToSupportForm') ? 'UPLOADING...' : 'UPLOAD AGREEMENT TO SUPPORT FORM'}}
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="fixed-bottom bg-white d-flex justify-content-end p-3">
    <button *ngIf="!saving" class="btn btn-primary" type="button" (click)="saveApplication()">SAVE APPLICATION</button>
    <button *ngIf="saving" class="btn btn-primary" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Saving...
    </button>
</div>
