import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { faTimesCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { addDeploymentLocation, addPeriodOfService, addServiceLocation, removeDeploymentLocation, removePeriodOfService, removeServiceLocation, setCurrentStep } from '../store/application.actions';
import { FormArrayState, FormControlState, FormControlValueTypes, FormGroupState, NgrxValueConverter } from 'ngrx-forms';
import { ApplicationState, ApplicationSteps } from '../store/application.state';
import { ApplicationForm, ServiceLocation, ServicePeriod } from '../store/application-form.state';
import { ServiceBranches, ServiceRanks, PayGrades } from '../store/application.state';

@Component({
    selector: 'app-military-info',
    templateUrl: './military-info.component.html',
    styleUrls: ['./military-info.component.css']
})
export class MilitaryInfoComponent implements OnInit {
    faTimesCircle: IconDefinition = faTimesCircle;

    formState$: Observable<FormGroupState<ApplicationForm['militaryInfo']>>;
    periodsOfService$: Observable<FormArrayState<ServicePeriod>>;
    serviceLocations$: Observable<FormArrayState<ServiceLocation>>;
    deploymentLocations$: Observable<FormArrayState<ServiceLocation>>;

    branchOptions = ServiceBranches;
    paygradeOptions = PayGrades;

    constructor(private store: Store<{ application: ApplicationState }>) {
        this.formState$ = store.select(s => s.application.applicationForm.controls.militaryInfo)
        this.periodsOfService$ = store.select(s => s.application.applicationForm.controls.militaryInfo.controls.servicePeriods);
        this.serviceLocations$ = store.select(s => s.application.applicationForm.controls.militaryInfo.controls.serviceLocations);
        this.deploymentLocations$ = store.select(s => s.application.applicationForm.controls.militaryInfo.controls.deploymentLocations);
    }

    ngOnInit() {
    }

    getRankOptions(branch?: typeof ServiceBranches[number]) {
        if (!branch)
            return [];
        return ServiceRanks[branch];
    }

    addPeriodOfService() {
        this.store.dispatch(addPeriodOfService());
    }

    removePeriodOfService(index: number) {
        this.store.dispatch(removePeriodOfService({ index }));
    }

    addServiceLocation() {
        this.store.dispatch(addServiceLocation());
    }

    removeServiceLocation(index: number) {
        this.store.dispatch(removeServiceLocation({ index }));
    }

    addDeploymentLocation() {
        this.store.dispatch(addDeploymentLocation());
    }

    removeDeploymentLocation(index: number) {
        this.store.dispatch(removeDeploymentLocation({ index }));
    }

    backToPreviousStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.HouseholdInfo }));
        window.scrollTo(0,0);
    }

    continueToNextStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.BiographicalInfo }));
        window.scrollTo(0,0);
    }

    trackByIndex(index, item) {
        return index;
    }

    selectValueConverter: NgrxValueConverter<string, string> = {
        convertViewToStateValue(value: string): string {
            if (!value)
                return null;
            return value;
        },

        convertStateToViewValue(value: string): string {
            if (!value)
                return '';
            return value;
        }
    }

    isInvalid<TValue extends FormControlValueTypes>(control: FormControlState<TValue>): boolean {
        return control.isTouched && control.isInvalid
    }
}

