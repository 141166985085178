<div class="border-bottom px-3 py-4">
    <h5 class="section-title mb-0">Household Info</h5>
</div>
<ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState">
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-start mb-4 responsive-form-field">
                <div class="d-flex flex-column w-25 form-field-container mb-3">
                    <h6 class="text-dark mb-0">How many people live in your household?</h6>
                    <small class="text-dark">If you have people in your household, please provide names, ages, and
                        relationship
                        to you.</small>
                </div>
                <div class="d-flex flex-column w-50" *ngIf="householdMembers$ | async as householdMembers">
                    <div *ngIf="householdMembers.controls.length === 0">
                        No Household Members
                    </div>
                    <div class="border-bottom pb-4 form-field-container"
                        *ngFor="let group of householdMembers.controls; trackBy: trackByIndex; let i = index">

                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-dark m-0">Family Member {{i + 1}}</h6>
                            <button type="button" (click)="removeHouseholdMember(i)" class="remove-dynamic-item-button">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>
                        </div>

                        <app-warrior-nested-form-input label="Family Member Name" [control]="group.controls.name">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-select label="Relationship To You" [control]="group.controls.relation"
                            [options]="householdMemberRelationOptions">
                        </app-warrior-nested-form-select>

                        <app-warrior-nested-form-input label="Age" [control]="group.controls.age" inputType="number">
                        </app-warrior-nested-form-input>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button type="button" (click)="addHouseholdMember()" class="btn btn-secondary">ADD A NEW HOUSEHOLD
                    MEMBER</button>
            </div>
        </div>

        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Pets In The Home</h5>
            <small class="text-dark">This will cover service animals and pets.</small>
        </div>
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-center responsive-form-field">
                <h6 class="text-dark mb-0">Are there any service animals in the home?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.serviceAnimalsInHome" class="form-check-input"
                            type="radio" name="serviceAnimalsInHome" id="serviceAnimalsInHome_Yes" [value]="true">
                        <label class="form-check-label" for="serviceAnimalsInHome_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.serviceAnimalsInHome" class="form-check-input"
                            type="radio" name="serviceAnimalsInHome" id="serviceAnimalsInHome_No" [value]="false">
                        <label class="form-check-label" for="serviceAnimalsInHome_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="serviceDogs && householdServiceAnimals$ | async as householdServiceAnimals">
                <div class="d-flex justify-content-end mt-4"
                *ngFor="let group of householdServiceAnimals.controls; trackBy: trackByIndex; let i = index">
                    <div class="w-50">
                        <p class="text-dark p-small d-flex justify-content-between">
                            Please list all applicable information.
                            <button type="button" class="remove-dynamic-item-button" *ngIf="i > 0" (click)="removeServiceAnimal(i)">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>
                        </p>

                        <label [htmlFor]="'group.controls.species.' + i + '.species'" class="form-label mb-0">Species</label>
                        <select class="form-control form-select w-100 mb-3"
                            [id]="'group.controls.species.' + i + '.species'"
                            aria-label="Species"
                            disabled>
                            <option>Dog</option>
                        </select>

                        <app-warrior-nested-form-input label="Breed" [control]="group.controls.breed">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Weight (lbs)" [control]="group.controls.weight"
                            inputType="number">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Age (Years)" [control]="group.controls.ageYears"
                            inputType="number">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Age (Months)" [control]="group.controls.ageMonths"
                            inputType="number">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Behavior Concerns"
                            [control]="group.controls.behavioralConcerns">
                        </app-warrior-nested-form-input>
                    </div>
                </div>
            </ng-container>

            <div class="d-flex justify-content-end mt-4" *ngIf="serviceDogs">
                <div class="w-50">
                    <div class="d-flex justify-content-end">
                        <button type="button" (click)="addServiceAnimal()" class="btn btn-secondary ml-auto">
                            ADD ANOTHER SERVICE ANIMAL
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-center responsive-form-field">
                <h6 class="text-dark mb-0">Are there any pets in your home?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.petsInHome" class="form-check-input"
                            type="radio" name="petsInHome" id="petsInHome_Yes" [value]="true">
                        <label class="form-check-label" for="petsInHome_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.petsInHome" class="form-check-input"
                            type="radio" name="petsInHome" id="petsInHome_No" [value]="false">
                        <label class="form-check-label" for="petsInHome_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="additionalPets && householdPets$ | async as householdPets">
                <div class="d-flex justify-content-end mt-4"
                    *ngFor="let group of householdPets.controls; trackBy: trackByIndex; let i = index">
                    <div class="w-50">
                        <p class="text-dark p-small d-flex justify-content-between">
                            Please list all applicable information.
                            <button type="button" class="remove-dynamic-item-button" *ngIf="i > 0" (click)="removePet(i)">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>
                        </p>

                        <app-warrior-nested-form-select label="Species" [control]="group.controls.species"
                            [options]="['Dog', 'Cat', 'Other']">
                        </app-warrior-nested-form-select>

                        <app-warrior-nested-form-input
                            *ngIf="group.controls.species.value === 'Other'"
                            [control]="group.controls.otherSpecies"
                            label="Other Species"
                        ></app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Breed" [control]="group.controls.breed">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Weight (lbs)" [control]="group.controls.weight"
                            inputType="number">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Age (Years)" [control]="group.controls.ageYears"
                            inputType="number">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Age (Months)" [control]="group.controls.ageMonths"
                            inputType="number">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Behavior Concerns"
                            [control]="group.controls.behavioralConcerns">
                        </app-warrior-nested-form-input>
                    </div>
                </div>
            </ng-container>

            <div class="d-flex justify-content-end mt-4" *ngIf="additionalPets">
                <div class="w-50">
                    <div class="d-flex justify-content-end">
                        <button type="button" (click)="addPet()" class="btn btn-secondary ml-auto">
                            ADD ANOTHER PET
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-center responsive-form-field">
                <h6 class="text-dark mb-0">Are you or anyone in your home allergic to dogs?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.dogAllergyInHome" class="form-check-input"
                            type="radio" name="dogAllergyInHome" id="dogAllergyInHome_Yes" [value]="true">
                        <label class="form-check-label" for="dogAllergyInHome_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.dogAllergyInHome" class="form-check-input"
                            type="radio" name="dogAllergyInHome" id="dogAllergyInHome_No" [value]="false">
                        <label class="form-check-label" for="dogAllergyInHome_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <div *ngIf="allergic" class="mt-4">
                <div class="hypoallergenic-dog-notice d-flex align-items-start text-white p-3">
                    <fa-icon [icon]="faExclamationCircle" class="mr-2"></fa-icon>
                    <p class="mb-0">
                        Before we consider pairing a veteran with a hypoallergenic dog, we must have written
                        verification from an allergy specialist that you or a member of your household has tested
                        positive for an allergy to dogs which should not be triggered by a hypoallergenic dog. Please be
                        aware that our
                        ability to pair veterans with a hypoallergenic dog is constrained by our limited ability to
                        acquire
                        hypoallergenic dogs, and therefore, veterans requiring a hypoallergenic dog experience a delay
                        in placement in the program.
                    </p>
                </div>
            </div>
        </div>

        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Home Details</h5>
            <small class="text-dark">This will cover service animals and pets.</small>
        </div>
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center responsive-form-field px-3 py-4">
            <h6 class="text-dark mb-0">Do you own or rent your home?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.homeIsOwned" class="form-check-input" type="radio"
                        name="homeIsOwned" id="Own" [value]="true">
                    <label class="form-check-label" for="Own">
                        Own
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.homeIsOwned" class="form-check-input" type="radio"
                        name="homeIsOwned" id="Rent" [value]="false">
                    <label class="form-check-label" for="Rent">
                        Rent
                    </label>
                </div>
            </div>
        </div>

        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-start px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Describe your home and neighborhood</h6>
            <div class="d-flex flex-column w-50 form-field-container">

                <app-warrior-nested-form-select label="Home Type" [control]="formState.controls.homeType"
                    [options]="homeTypeOptions">
                </app-warrior-nested-form-select>

                <app-warrior-nested-form-select label="Has a yard?" [control]="formState.controls.hasAYard"
                    [options]="['Yes','No']">
                </app-warrior-nested-form-select>

                <app-warrior-nested-form-select label="Household location" [control]="formState.controls.homeLocationType"
                    [options]="homeLocationOptions">
                </app-warrior-nested-form-select>

            </div>
        </div>

        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center responsive-form-field px-3 py-4">
            <h6 class="text-dark mb-0">Do you have a fence around your yard?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.yardIsFenced" class="form-check-input"
                        type="radio" name="yardIsFenced" id="yardIsFenced_Yes" [value]="true">
                    <label class="form-check-label" for="yardIsFenced_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.yardIsFenced" class="form-check-input"
                        type="radio" name="yardIsFenced" id="yardIsFenced_No" [value]="false">
                    <label class="form-check-label" for="yardIsFenced_No">
                        No
                    </label>
                </div>
            </div>
        </div>

        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center responsive-form-field px-3 py-4">
            <h6 class="text-dark mb-0">Is your home fully accessible to you?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.homeIsFullyAccessible" class="form-check-input"
                        type="radio" name="homeIsFullyAccessible" id="homeIsFullyAccessible_Yes" [value]="true">
                    <label class="form-check-label" for="homeIsFullyAccessible_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.homeIsFullyAccessible" class="form-check-input"
                        type="radio" name="homeIsFullyAccessible" id="homeIsFullyAccessible_No" [value]="false">
                    <label class="form-check-label" for="homeIsFullyAccessible_No">
                        No
                    </label>
                </div>
            </div>
        </div>

        <div class="border-bottom d-flex justify-content-between align-items-center btn-container px-3 py-4">
            <button class="btn btn-back" (click)="backToPreviousStep()">BACK TO SECTION 1 - PERSONAL INFO</button>
            <button class="btn btn-primary" (click)="continueToNextStep()">CONTINUE TO SECTION 3 - MILITARY
                INFO</button>
        </div>
    </form>
</ng-container>
