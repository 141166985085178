<div class="bg-form-field d-flex justify-content-between p-3 responsive-form-field"
    [class.border-bottom]="showBottomBorder"
    [class.py-4]="showBottomBorder"
    [class.align-items-center]="inputType !== 'textarea'"
    [class.align-items-stretch]="inputType === 'textarea'">
    <div class="d-flex flex-column w-25 form-field-container">
        <label [htmlFor]="id" class="text-dark mb-0">{{label}}</label>
        <small class="text-dark">{{sublabel}}</small>
    </div>
    <textarea *ngIf="control && inputType === 'textarea'"
        [id]="id"
        [ngrxFormControlState]="control"
        class="form-control"
        [class.is-valid]="control.isValid"
        [class.is-invalid]="control.isInvalid"
        [placeholder]="placeholder || label"
        [attr.aria-label]="ariaLabel || label">
    </textarea>
    <input *ngIf="control && inputType !== 'textarea'" [type]="inputType"
        [id]="id"
        [ngrxFormControlState]="control"
        [ngrxValueConverter]="valueConverter"
        class="form-control"
        [class.is-valid]="control.isValid"
        [class.is-invalid]="control.isInvalid"
        [placeholder]="placeholder || label"
        [attr.aria-label]="ariaLabel || label" />
    <span *ngIf="!control" class="text-danger text-right">Error loading form field.</span>
    <!-- <small class="invalid-feedback" *ngIf="control.isTouched && control.errors.required">{{label}} is a required field.</small> -->
</div>
