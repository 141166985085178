import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationForm } from '../application/store/application-form.state';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {

    constructor(private http: HttpClient) { }

    async getApplication(): Promise<WarriorApplicationViewModel> {
        return await this.http
            .get<WarriorApplicationViewModel>('/api/warrior-applications/current-user')
            .toPromise();
    }

    async saveApplication(viewModel: WarriorApplicationViewModel): Promise<void> {
        await this.http
            .put('/api/warrior-applications/current-user', viewModel)
            .toPromise();
    }

    async submitApplication(): Promise<void> {
        await this.http
            .post('/api/warrior-applications/current-user/submit-application', null)
            .toPromise();
    }
}

export type WarriorApplicationViewModel = {
    metadata: WarriorApplicationMetadata;
    application: ApplicationForm;
}

export type WarriorApplicationMetadata = {
    id: string;
    userId: string;
    applicationState: string;
    applicationStep: string;
}

