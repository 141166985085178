import { Component, Input } from '@angular/core';
import { Boxed, FormControlState, FormControlValueTypes, NgrxValueConverter, NgrxValueConverters, unbox } from 'ngrx-forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-warrior-form-input',
    templateUrl: './warrior-form-input.component.html',
    styleUrls: ['./warrior-form-input.component.css']
})
export class WarriorFormInputComponent<TValue extends FormControlValueTypes> {
    @Input() inputType: string = "text";
    @Input() label: string;
    @Input() placeholder?: string;
    @Input() sublabel?: string;
    @Input('aria-label') ariaLabel?: string;
    @Input() control: FormControlState<TValue>
    @Input() showBottomBorder: boolean = true;

    id: string

    constructor() {
        this.id= uuidv4();
    }

    get valueConverter(): NgrxValueConverter<any, any> {
        if (this.inputType === 'date') {
            return ShortDateValueConverter.instance;
        } else if (this.inputType !== 'number') {
            return NgrxValueConverters.default<TValue>();
        } else {
            return {
                convertViewToStateValue(value: TValue): Boxed<TValue> | TValue {
                    return value ? +value as TValue : null;
                },

                convertStateToViewValue(value: Boxed<TValue> | TValue): TValue {
                    return unbox(value);
                }
            }
        }
    }
}

class ShortDateValueConverter implements NgrxValueConverter<string, string> {
    static instance = new ShortDateValueConverter();

    convertViewToStateValue(value: string): string {
        if (!value) {
            return value;
        }

        return new Date(value).toISOString();
    }

    convertStateToViewValue(value: string): string {
        if (!value) {
            return value;
        }

        return value.substring(0, value.indexOf('T'));
    }

}
