import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setCurrentStep } from '../store/application.actions';
import { Observable } from 'rxjs';
import { FormControlState, FormControlValueTypes, FormGroupState, NgrxValueConverter } from 'ngrx-forms';
import { ApplicationState, ApplicationSteps } from '../store/application.state';
import { ApplicationForm } from '../store/application-form.state';

@Component({
    selector: 'app-transportation',
    templateUrl: './transportation.component.html',
    styleUrls: ['./transportation.component.css']
})
export class TransportationComponent implements OnInit {

    formState$: Observable<FormGroupState<ApplicationForm['transportation']>>

    constructor(private store: Store<{ application: ApplicationState }>) {
        this.formState$ = store.select(s => s.application.applicationForm.controls.transportation)
    }

    ngOnInit() {
    }

    backToPreviousStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.WorkAndEducation }));
        window.scrollTo(0,0);
    }

    continueToNextStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.MedicalAndMentalHealth }));
        window.scrollTo(0,0);
    }

    selectValueConverter: NgrxValueConverter<string, string> = {
        convertViewToStateValue(value: string): string {
            if (!value)
                return null;
            return value;
        },

        convertStateToViewValue(value: string): string {
            if (!value)
                return '';
            return value;
        }
    }

    isInvalid<TValue extends FormControlValueTypes>(control: FormControlState<TValue>): boolean {
        return control.isTouched && control.isInvalid
    }
}
