import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { STEP_METADATA } from 'src/app/common/step-metadata';
import { WarriorApplicationMetadata } from 'src/app/services/application.service';
import { setCurrentStepFromApplicationStep } from '../store/application.actions';
import { ApplicationState } from '../store/application.state';

@Component({
    selector: 'app-application-intro',
    templateUrl: './application-intro.component.html',
    styleUrls: ['./application-intro.component.css']
})
export class ApplicationIntroComponent implements OnInit {
    private subscription = new Subscription();
    private metadata$: Observable<WarriorApplicationMetadata>

    userFirstName?: string;
    leftOffStepLabel?: string;

    constructor(private authorizeService: AuthorizeService,
                private store: Store<{ application: ApplicationState }>) {
        this.metadata$ = store.select(s => s.application.metadata);
    }

    ngOnInit() {
        this.subscription.add(this.authorizeService.getUser().subscribe(user => {
            this.userFirstName = user.given_name;
        }));

        this.subscription.add(this.metadata$.subscribe(md => {
            if (!md) return;

            const step = Object
                .values(STEP_METADATA)
                .find(i => i.step == md.applicationStep);

            this.leftOffStepLabel = `Section ${step.index} - ${step.name}`
        }))
    }

    continueTheApplication() {
        this.store.dispatch(setCurrentStepFromApplicationStep());
    }
}
