import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { faExclamationCircle, faTimesCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FormArrayState, FormControlState, FormControlValueTypes, FormGroupState, NgrxValueConverter } from 'ngrx-forms';
import { addEducationalDegree, removeEducationalDegree, setCurrentStep } from '../store/application.actions';
import { ApplicationState, ApplicationSteps } from '../store/application.state';
import { ApplicationForm, EducationalDegree } from '../store/application-form.state';

@Component({
    selector: 'app-work-education',
    templateUrl: './work-education.component.html',
    styleUrls: ['./work-education.component.css']
})
export class WorkEducationComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    faExclamationCircle: IconDefinition = faExclamationCircle;
    faTimesCircle: IconDefinition = faTimesCircle;

    formState$: Observable<FormGroupState<ApplicationForm['workAndEducation']>>;
    educationalDegrees$: Observable<FormArrayState<EducationalDegree>>;

    presentlyEmployed: boolean = false;

    educationLevelOptions: string[] = ['High School Diploma / GED', 'Associate Degree', 'Bachelor\'s Degree', 'Master\'s Degree', 'Doctorate Degree', 'Professional Degree'];

    constructor(private store: Store<{ application: ApplicationState }>) {
        this.formState$ = store.select(s => s.application.applicationForm.controls.workAndEducation);
        this.educationalDegrees$ = store.select(s => s.application.applicationForm.controls.workAndEducation.controls.educationalDegrees);
    }

    ngOnInit() {
        const presentlyEmployed$ = this.store.select(s => s.application.applicationForm.controls.workAndEducation.controls.currentlyEmployed.value);

        this.subscription.add(presentlyEmployed$.subscribe(presentlyEmployed => {
            this.presentlyEmployed = presentlyEmployed;
        }))
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addEducationalDegree() {
        this.store.dispatch(addEducationalDegree());
    }

    removeEducationalDegree(index: number) {
        this.store.dispatch(removeEducationalDegree({ index }));
    }

    backToPreviousStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.BiographicalInfo }));
        window.scrollTo(0,0);
    }

    continueToNextStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.Transportation }));
        window.scrollTo(0,0);
    }

    trackByIndex(index, item) {
        return index;
    }

    selectValueConverter: NgrxValueConverter<string, string> = {
        convertViewToStateValue(value: string): string {
            if (!value)
                return null;
            return value;
        },

        convertStateToViewValue(value: string): string {
            if (!value)
                return '';
            return value;
        }
    }

    isInvalid<TValue extends FormControlValueTypes>(control: FormControlState<TValue>): boolean {
        return control.isTouched && control.isInvalid
    }
}
