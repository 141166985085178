import { StateUpdateFns, updateArray, updateGroup, validate } from "ngrx-forms";
import { maxLength, required, requiredTrue } from "ngrx-forms/validation";
import { ApplicationForm, DoctorOrVeterinarian } from "../application-form.state";
import { initIsOptional } from "./common-functions";

export const ConsentRequiredInputStateFns: StateUpdateFns<ApplicationForm['consent']> = {
    informationReleaseConsent: validate(required, requiredTrue),
    doctors: updateArray(updateGroup<DoctorOrVeterinarian>({
        name: validate(required),
        phone: validate(required),
        streetAddress: validate(required),
        city: validate(required),
        stateOrProvince: validate(required),
        postalCode: validate(required, maxLength(10))
    }, {
        unit: initIsOptional()
    })),
    psychologistsAndPsychiatrists: updateArray(updateGroup<DoctorOrVeterinarian>({
        name: validate(required),
        phone: validate(required),
        streetAddress: validate(required),
        city: validate(required),
        stateOrProvince: validate(required),
        postalCode: validate(required, maxLength(10))
    }, {
        unit: initIsOptional()
    })),
    veterinarians: updateArray(updateGroup<DoctorOrVeterinarian>({
        name: validate(required),
        phone: validate(required),
        streetAddress: validate(required),
        city: validate(required),
        stateOrProvince: validate(required),
        postalCode: validate(required, maxLength(10))
    }, {
        unit: initIsOptional()
    }))
}


export const ConsentOptionalInputStateFns: StateUpdateFns<ApplicationForm['consent']> = {
}


export const ConsentSetOptionalInputStateFns: StateUpdateFns<ApplicationForm['consent']> = {
}


export const ConsentOptionalInputValidatorStateFns: StateUpdateFns<ApplicationForm['consent']> = {
}