<div class="border-bottom px-3 py-4">
    <h5 class="section-title">Military Info</h5>
    <div class="d-flex" *ngIf="formState$ | async as formState">
        <input type="checkbox"
            [ngrxFormControlState]="formState.controls.serviceDetailsFoiaConsent"
            id="personalReferenceContact"
            name="personalReferenceContact"
            class="mr-1">
        <label for="personalReferenceContact" class="w-100">
            I authorize my personal references to disclose all information about me
            which may be requested by K9s For Warriors.</label>
    </div>
</div>
<ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState">
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-start responsive-form-field p-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Branch(es) of Service</h6>
            <div class="d-flex flex-column w-50 form-field-container">
                <app-warrior-form-select
                    label="Branch"
                    [control]="formState.controls.branch"
                    [options]="branchOptions"
                ></app-warrior-form-select>

                <app-warrior-form-select
                    label="Rank"
                    [control]="formState.controls.rank"
                    [options]="getRankOptions(formState.controls.branch.value)"
                ></app-warrior-form-select>

                <app-warrior-form-select
                    label="Paygrade"
                    [control]="formState.controls.payGrade"
                    [options]="paygradeOptions"
                ></app-warrior-form-select>

                <app-warrior-form-input
                    label="Military Occupational Specialty (MOS)"
                    [control]="formState.controls.militaryOccupationalSpecialty"
                ></app-warrior-form-input>

                <app-warrior-form-input label="Age" inputType="number" [control]="formState.controls.age"></app-warrior-form-input>
            </div>
        </div>

        <div class="bg-form-field border-bottom p-3 py-4">
            <div class=" d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <h6 class="text-dark mb-0">List all periods of service.</h6>

                <div class="d-flex flex-column w-50 form-field-container"
                    *ngIf="periodsOfService$ | async as periodsOfService">
                    <div *ngFor="let group of periodsOfService.controls; trackBy: trackByIndex; let i = index"
                        class="border-bottom pb-4" [class.pt-2]="i > 0">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-dark m-0">Service {{i + 1}}</h6>
                            <button type="button" class="remove-dynamic-item-button" *ngIf="i > 0"
                                (click)="removePeriodOfService(i)">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>
                        </div>

                        <app-warrior-nested-form-input label="Entered Service Date"
                            [control]="group.controls.enteredDate" inputType="date">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Exited Service Date"
                            [control]="group.controls.dischargeDate" inputType="date">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-select label="Type of Discharge"
                            [control]="group.controls.dischargeType"
                            [options]="['Honorable','General, Under Honorable Conditions']"
                        ></app-warrior-nested-form-select>

                        <app-warrior-nested-form-input
                            *ngIf="group.value.dischargeType === 'General, Under Honorable Conditions'"
                            label="Discharge Details"
                            inputType="textarea"
                            [control]="group.controls.dischargeDetails"
                        ></app-warrior-nested-form-input>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <button type="button" (click)="addPeriodOfService()" class="btn btn-secondary">ADD A SERVICE DATE</button>
            </div>
        </div>

        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Service History</h5>
            <small class="text-dark">Please list your last four permanent duty stations and the dates.</small>
        </div>

        <div class="bg-form-field border-bottom px-3 py-4" *ngIf="serviceLocations$ | async as serviceLocations">
            <div class=" d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <h6 class="text-dark mb-0">Service Locations</h6>

                <div class="d-flex flex-column w-50 form-field-container">
                    <div *ngFor="let group of serviceLocations.controls; trackBy: trackByIndex; let i = index"
                        class="border-bottom pb-4" [class.pt-2]="i > 0">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-dark m-0">Duty Station {{i + 1}}</h6>
                            <button type="button" class="remove-dynamic-item-button" *ngIf="i > 0"
                                (click)="removeServiceLocation(i)">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>
                        </div>

                        <app-warrior-nested-form-input label="Entered Duty Station Date"
                            [control]="group.controls.startDate" inputType="date">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Exited Duty Station Date"
                            [control]="group.controls.endDate" inputType="date">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input
                            label="Stationed"
                            placeholder="Stationed (e.g., Fort Bragg)"
                            [control]="group.controls.location"
                        ></app-warrior-nested-form-input>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end" *ngIf="serviceLocations.controls.length < 4">
                <button type="button" (click)="addServiceLocation()" class="btn btn-secondary">ADD A LOCATION</button>
            </div>
        </div>

        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Deployment History</h5>
            <small class="text-dark">Please list deployments (i.e. Iraq, Afghanistan) and the dates.</small>
        </div>

        <div class="bg-form-field border-bottom px-3 py-4" *ngIf="deploymentLocations$ | async as deploymentLocations">
            <div class="d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <h6 class="text-dark mb-0">Deployment Locations</h6>

                <div class="d-flex flex-column w-50 form-field-container">
                    <div *ngFor="let group of deploymentLocations.controls; trackBy: trackByIndex; let i = index"
                        class="border-bottom pb-4"
                        [class.pt-2]="i > 0">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-dark m-0">Location 1</h6>
                            <button type="button" class="remove-dynamic-item-button" (click)="removeDeploymentLocation(i)">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>
                        </div>

                        <app-warrior-nested-form-input label="Entered Location Date"
                            [control]="group.controls.startDate" inputType="date">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Exited Location Date"
                            [control]="group.controls.endDate" inputType="date">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Operation Location" [control]="group.controls.location">
                        </app-warrior-nested-form-input>
                    </div>

                </div>
            </div>

            <div class="d-flex justify-content-end">
                <button type="button" (click)="addDeploymentLocation()" class="btn btn-secondary">ADD A LOCATION</button>
            </div>
        </div>

        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Military Awards</h5>
            <small class="text-dark">Please list your highest or most notable military award or decoration(s).</small>
        </div>


        <app-warrior-form-input label="List your Awards or Decorations"
            [control]="formState.controls.awardsOrDecorations" placeholder="Leave a comment here">
        </app-warrior-form-input>

        <app-warrior-form-select label="Era"
            sublabel="Please select the most recent era during which you served."
            [control]="formState.controls.era"
            [options]="['Post-9/11', 'Gulf War', 'Bosnia', 'Vietnam', 'Korea']"
        ></app-warrior-form-select>

        <div class="border-bottom px-3 py-4 d-flex justify-content-between align-items-center btn-container">
            <button class="btn btn-back" (click)="backToPreviousStep()">BACK TO SECTION 2 - HOUSEHOLD INFO</button>
            <button class="btn btn-primary" (click)="continueToNextStep()">CONTINUE TO SECTION 4 - BIOGRAPHICAL
                INFO</button>
        </div>
    </form>
</ng-container>
