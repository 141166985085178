import { setErrors, setValue, StateUpdateFns, updateArray, updateGroup, validate } from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import { atLeastOneQualifyingMedicalCondition } from "../custom-validators";
import { ApplicationForm, AssistiveDevice, MedicalDiagnosis } from "../application-form.state";
import { initIsOptional, setIsOptional } from "./common-functions";

export const MedicalAndMentalHealthRequiredInputStateFns: StateUpdateFns<ApplicationForm['medicalAndMentalHealth']> = {
    postTraumaticStressDisability: (c, frm) => validate(c, required, atLeastOneQualifyingMedicalCondition(frm)),
    traumaticBrainInjury: (c, frm) => validate(c, required, atLeastOneQualifyingMedicalCondition(frm)),
    militarySexualTrauma: (c, frm) => validate(c, required, atLeastOneQualifyingMedicalCondition(frm)),
    diagnoses: updateArray(updateGroup<MedicalDiagnosis>({
        diagnosisName: validate(required),
        dateOfDiagnosis: validate(required)
    })),
    receiveMedicalServices: validate(required),
    verbalSkill: validate(required),
    primaryDisability: validate(required),
    physicalStrength: validate(required),
    activityLevel: validate(required),
    mobilityLevel: validate(required),
    ableToManeuver: validate(required),
    braceFrequency: validate(required),
    prescriptionMedications: validate(required),
    allergies: validate(required),
    dominantHandRight: validate(required),
    historyOfFalling: validate(required),
    primaryPhysicianName: validate(required),
    primaryPhysicianPhoneNumber: validate(required),
    assistedDevices: updateArray(updateGroup<AssistiveDevice>({
        equipmentPiece: validate(required),
        specificDetails: validate(required)
    })),
    physicalLimitations: validate(required),
    dailyLifeEffects: validate(required),
    otherLimitations: validate(required),
    doOwnRoutineMedications: validate(required),
    doOwnFinances: validate(required),
    doHousecleaning: validate(required),
    doOwnMeals: validate(required),
    dressSelf: validate(required),
    runOwnErrands: validate(required),
    doOwnPersonalCare: validate(required),
    historyOfSubstanceAbuse: validate(required),
    foodAllergies: validate(required),
    dietaryRestrictions: validate(required),
    howWouldServiceDogAssist: validate(required),
    otherProblems: validate(required)
}


export const MedicalAndMentalHealthOptionalInputStateFns: StateUpdateFns<ApplicationForm['medicalAndMentalHealth']> = {
    nearestVAFacility: (c, frm) => frm.controls.receiveMedicalServices.value
        ? c
        : setValue(c, null),
    allergiesStatement: (c, frm) => frm.controls.allergies.value
        ? c
        : setValue(c, null),
    historyOfFallingStatement: (c, frm) => frm.controls.historyOfFalling.value
        ? c
        : setValue(c, null),
    doOwnRoutineMedicationsAssistant: (c, frm) => !frm.controls.doOwnRoutineMedications.value
        ? c
        : setValue(c, null),
    financialAssistant: (c, frm) => !frm.controls.doOwnFinances.value
        ? c
        : setValue(c, null),
    doHousecleaningAssistant: (c, frm) => !frm.controls.doHousecleaning.value
        ? c
        : setValue(c, null),
    doOwnMealsAssistant: (c, frm) => !frm.controls.doOwnMeals.value
        ? c
        : setValue(c, null),
    dressSelfAssistant: (c, frm) => !frm.controls.dressSelf.value
        ? c
        : setValue(c, null),
    runOwnErrandsAssistant: (c, frm) => !frm.controls.runOwnErrands.value
        ? c
        : setValue(c, null),
    doOwnPersonalCareAssistant: (c, frm) => !frm.controls.doOwnPersonalCare.value
        ? c
        : setValue(c, null),
    foodAllergiesStatement: (c, frm) => frm.controls.foodAllergies.value
        ? c
        : setValue(c, null),
    dietaryRestrictionsStatement: (c, frm) => frm.controls.dietaryRestrictions.value
        ? c
        : setValue(c, null)
}


export const MedicalAndMentalHealthSetOptionalInputStateFns: StateUpdateFns<ApplicationForm['medicalAndMentalHealth']> = {
    hasUploadedMentalHealthVerificationForm: initIsOptional(),
    hasUploadedPhysicianReport: initIsOptional(),
    nearestVAFacility: (c, frm) => setIsOptional(c, !frm.controls.receiveMedicalServices.value),
    doOwnRoutineMedicationsAssistant: (c, frm) => setIsOptional(c, frm.controls.doOwnRoutineMedications.value),
    financialAssistant: (c, frm) => setIsOptional(c, frm.controls.doOwnFinances.value),
    doHousecleaningAssistant: (c, frm) => setIsOptional(c, frm.controls.doHousecleaning.value),
    doOwnMealsAssistant: (c, frm) => setIsOptional(c, frm.controls.doOwnMeals.value),
    dressSelfAssistant: (c, frm) => setIsOptional(c, frm.controls.dressSelf.value),
    runOwnErrandsAssistant: (c, frm) => setIsOptional(c, frm.controls.runOwnErrands.value),
    doOwnPersonalCareAssistant: (c, frm) => setIsOptional(c, frm.controls.doOwnPersonalCare.value),
    foodAllergiesStatement: (c, frm) => setIsOptional(c, !frm.controls.foodAllergies.value),
    dietaryRestrictionsStatement: (c, frm) => setIsOptional(c, !frm.controls.dietaryRestrictions.value),
    allergiesStatement: (c, frm) => setIsOptional(c, !frm.controls.allergies.value),
    historyOfFallingStatement: (c, frm) => setIsOptional(c, !frm.controls.historyOfFalling.value)
}


export const MedicalAndMentalHealthOptionalInputValidatorStateFns: StateUpdateFns<ApplicationForm['medicalAndMentalHealth']> = {
    nearestVAFacility: (c, frm) => frm.controls.receiveMedicalServices.value
        ? validate(c, required)
        : setErrors(c, {}),
    allergiesStatement: (c, frm) => frm.controls.allergies.value
        ? validate(c, required)
        : setErrors(c, {}),
    historyOfFallingStatement: (c, frm) => frm.controls.historyOfFalling.value
        ? validate(c, required)
        : setErrors(c, {}),
    doOwnRoutineMedicationsAssistant: (c, frm) => !frm.controls.doOwnRoutineMedications.value
        ? validate(c, required)
        : setErrors(c, {}),
    financialAssistant: (c, frm) => !frm.controls.doOwnFinances.value
        ? validate(c, required)
        : setErrors(c, {}),
    doHousecleaningAssistant: (c, frm) => !frm.controls.doHousecleaning.value
        ? validate(c, required)
        : setErrors(c, {}),
    doOwnMealsAssistant: (c, frm) => !frm.controls.doOwnMeals.value
        ? validate(c, required)
        : setErrors(c, {}),
    dressSelfAssistant: (c, frm) => !frm.controls.dressSelf.value
        ? validate(c, required)
        : setErrors(c, {}),
    runOwnErrandsAssistant: (c, frm) => !frm.controls.runOwnErrands.value
        ? validate(c, required)
        : setErrors(c, {}),
    doOwnPersonalCareAssistant: (c, frm) => !frm.controls.doOwnPersonalCare.value
        ? validate(c, required)
        : setErrors(c, {}),
    foodAllergiesStatement: (c, frm) => frm.controls.foodAllergies.value
        ? validate(c, required)
        : setErrors(c, {}),
    dietaryRestrictionsStatement: (c, frm) => frm.controls.dietaryRestrictions.value
        ? validate(c, required)
        : setErrors(c, {})
}
