import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState, FormControlValueTypes, FormGroupState, NgrxValueConverter } from 'ngrx-forms';
import { Observable, Subscription } from 'rxjs';
import { savingApplication, setCurrentStep, showErrorSummary } from '../store/application.actions';
import { ApplicationState, ApplicationSteps } from '../store/application.state';
import { ApplicationForm } from '../store/application-form.state';

//Icons
import { faArrowRight, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { ApplicationService, WarriorApplicationMetadata, WarriorApplicationViewModel } from 'src/app/services/application.service';
import { Router } from '@angular/router';
import { ValidationErrors } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

function lowerCase(str) {
    return str.toLowerCase();
}

function upperCase(str) {
    return str.toUpperCase();
}

function unCamelCase(str) {
    str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, "$1 $2");
    str = str.toLowerCase(); //add space between camelCase text
    return str;
}

function properCase(str) {
    return lowerCase(str).replace(/^\w|\s\w/g, upperCase);
}

@Component({
    selector: 'app-certification-signature',
    templateUrl: './certification-signature.component.html',
    styleUrls: ['./certification-signature.component.css']
})
export class CertificationSignatureComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    private currentTab: string;
    private applicationMetadata?: WarriorApplicationMetadata;
    private applicationForm?: ApplicationForm;

    faArrowRight: IconDefinition = faArrowRight;
    formState$: Observable<FormGroupState<ApplicationForm['certificationAndSignature']>>

    showErrorSummary: boolean;
    errors: SafeHtml[] = [];
    canSubmit: boolean;
    isSubmitting: boolean;

    constructor(private store: Store<{ application: ApplicationState }>,
        private router: Router,
        private sanitizer: DomSanitizer,
        private applicationService: ApplicationService) {
        this.formState$ = store.select(s => s.application.applicationForm.controls.certificationAndSignature)
    }

    ngOnInit() {
        this.subscription.add(this.store.select(s => s.application).subscribe(state => {
            this.currentTab = state.currentTab;
            this.applicationMetadata = state.metadata;
            this.applicationForm = state.applicationForm?.value;

            this.showErrorSummary = state.showErrorSummary;
            this.errors = this._transformErrors(state.applicationForm.errors);
            this.canSubmit = state.applicationForm?.isValid === true;
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    backToPreviousStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.Consent }));
        window.scrollTo(0, 0);
    }

    async submitApplication() {
        if (!this.canSubmit) {
            this.store.dispatch(showErrorSummary());
            return;
        }

        this.isSubmitting = true;

        try {
            await this._saveApplication();
            await this.applicationService.submitApplication();

            this.router.navigate(['/application/submitted']);
        } finally {
            this.isSubmitting = false;
        }
    }

    selectValueConverter: NgrxValueConverter<string, string> = {
        convertViewToStateValue(value: string): string {
            if (!value)
                return null;
            return value;
        },

        convertStateToViewValue(value: string): string {
            if (!value)
                return '';
            return value;
        }
    }

    isInvalid<TValue extends FormControlValueTypes>(control: FormControlState<TValue>): boolean {
        return control.isTouched && control.isInvalid
    }

    private _transformErrors(errors: ValidationErrors): SafeHtml[] {
        function hasError(item: any) {
            return Object.keys(item).length > 0;
        }

        function transformSectionKey(key: string): string {
            return properCase(unCamelCase(key.slice(1)));
        }

        function transformErrorType(item: any): string {
            if (item.required) {
                if (item.required.actual === false) {
                    return 'must be acknowledged';
                } else {
                    return 'is required';
                }
            } else {
                return 'is invalid';
            }
        }

        const urlParams = new URLSearchParams(window.location.search);
        const detailedErrors = (urlParams.get('detailedErrors') || '').toLowerCase() === 'true';

        return Object.keys(errors)
            .filter(k => {
                const section = errors[k];
                const sectionkeys = Object.keys(section);
                return sectionkeys.map(sk => section[sk]).some(hasError);
            })
            .map(k => {
                const sectionName = transformSectionKey(k);
                let html = `Invalid or missing fields in the <strong>${sectionName}</strong> section${detailedErrors ? ':' : '.'}`;

                if (detailedErrors) {
                    html += '<ul>';

                    for (let err in errors[k]) {
                        const item = errors[k][err];

                        const fieldName = transformSectionKey(err);
                        const errorType = transformErrorType(item);

                        html += `<li><strong>${fieldName}</strong> ${errorType}.</li>`;
                    }

                    html += '</ul>'
                }

                return this.sanitizer.bypassSecurityTrustHtml(html)
            });
    }

    // HACK.JB - Duplicated from application.component.ts
    private async _saveApplication() {
        let viewModel: WarriorApplicationViewModel = {
            application: this.applicationForm,
            metadata: {
                applicationStep: this.currentTab,
                ...this.applicationMetadata
            }
        }

        this.store.dispatch(savingApplication({ saving: true }));

        try {
            await this.applicationService.saveApplication(viewModel);
        } finally {
            this.store.dispatch(savingApplication({ saving: false }));
        }
    }
}
