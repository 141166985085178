<div class="border-bottom p-3 mb-0 py-4">
    <h5 class="section-title mb-0">Medical & Mental Health</h5>
    <small class="text-dark">Please be as precise as possible, as this section aids us in assigning and training a
        service dog that will be most suited to your conditions.</small>
</div>
<ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState">
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center p-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Do you have service-connected Post-traumatic Stress Disability (PTSD)?</h6>
            <div class="d-flex">                    
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.postTraumaticStressDisability" class="form-check-input" type="radio"
                    name="postTraumaticStressDisability" id="postTraumaticStressDisability_Yes" [value]="true">
                    <label class="form-check-label" for="postTraumaticStressDisability_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.postTraumaticStressDisability" class="form-check-input" type="radio"
                    name="postTraumaticStressDisability" id="postTraumaticStressDisability_No" [value]="false">
                    <label class="form-check-label" for="postTraumaticStressDisability_No">
                        No
                    </label>
                </div>
            </div>
        </div>
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center p-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Do you have service-connected Traumatic Brain Injury (TBI)?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.traumaticBrainInjury" class="form-check-input" type="radio"
                    name="traumaticBrainInjury" id="traumaticBrainInjury_Yes" [value]="true">
                    <label class="form-check-label" for="traumaticBrainInjury_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.traumaticBrainInjury" class="form-check-input" type="radio"
                    name="traumaticBrainInjury" id="traumaticBrainInjury_No" [value]="false">
                    <label class="form-check-label" for="traumaticBrainInjury_No">
                        No
                    </label>
                </div>
            </div>
        </div>
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center p-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Do you have Military Sexual Trauma (MST)?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.militarySexualTrauma" class="form-check-input" type="radio"
                    name="militarySexualTrauma" id="militarySexualTrauma_Yes" [value]="true">
                    <label class="form-check-label" for="militarySexualTrauma_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.militarySexualTrauma" class="form-check-input" type="radio"
                    name="militarySexualTrauma" id="militarySexualTrauma_No" [value]="false">
                    <label class="form-check-label" for="militarySexualTrauma_No">
                        No
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="showNotQualifiedWarning" class="p-4 border-bottom">
            <div class="alert-required">
                <strong class="text-primary">Medical & Mental Health Qualification</strong>
                <div class="text-dark alert-text mb-0">
                    <p>
                        K9s For Warriors is an Assistance Dogs International (ADI) accredited
                        provider of Service Dogs, serving veterans with a verifiable
                        diagnosis of PTSD, TBI, and/or MST, which must be service-related.
                        At this time, we do not train Service Dogs for any other disabilities.
                        Specifically, we do not train service dogs in the tasks that would be
                        necessary to aid someone who uses a wheelchair, mobility scooter, or walker,
                        is substantially visually or hearing impaired, needs retrieval assistance,
                        or has advanced mobility or medical alert issues.
                    </p>
                    <p>
                        If you do not have a verifiable diagnosis of service-related PTSD, TBI, and/or MST,
                        or if you have one or more of the additional disabilities mentioned above, we are
                        unable to process your application at this time. Please contact our Warrior
                        Relations team at <a href="mailto:info@k9sforwarriors.org">info@k9sforwarriors.org</a>.
                        We would be happy to provide resources of similar organizations that would
                        be better suited to support your needs.
                    </p>
                </div>
            </div>
        </div>
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <h6 class="text-dark mb-0">Diagnoses</h6>

                <div class="d-flex flex-column w-50 form-field-container"
                    *ngIf="medicalDiagnoses$ | async as medicalDiagnoses">
                    <div class="border-bottom pb-4" [class.pt-2]="i > 0"
                        *ngFor="let group of medicalDiagnoses.controls; trackBy: trackByIndex; let i = index">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-dark m-0">{{latinateDiagnosis(i)}}</h6>
                            <button *ngIf="i > 0" type="button" class="remove-dynamic-item-button" (click)="removeDiagnosis(i)">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>
                        </div>

                        <app-warrior-form-input
                            label="Diagnosis"
                            [control]="group.controls.diagnosisName"
                        ></app-warrior-form-input>

                        <app-warrior-form-input
                            label="Date of onset or diagnosis"
                            [control]="group.controls.dateOfDiagnosis"
                            inputType="date"
                        ></app-warrior-form-input>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-secondary" (click)="addDiagnosis()">ADD A DIAGNOSIS</button>
            </div>
        </div>
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center p-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Do you receive VA medical services?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.receiveMedicalServices" class="form-check-input" type="radio"
                    name="receiveMedicalServices" id="receiveMedicalServices_Yes" [value]="true">
                    <label class="form-check-label" for="receiveMedicalServices_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.receiveMedicalServices" class="form-check-input" type="radio"
                    name="receiveMedicalServices" id="receiveMedicalServices_No" [value]="false">
                    <label class="form-check-label" for="receiveMedicalServices_No">
                        No
                    </label>
                </div>
            </div>
        </div>
        <app-warrior-form-input
            *ngIf="formState.controls.receiveMedicalServices.value === true"
            label="At what VA facility do you receive treatment?"
            [control]="formState.controls.nearestVAFacility"
        ></app-warrior-form-input>
        <div class="border-bottom">
            <h5 class="section-title p-3 mb-0 py-4">Verbal Skills</h5>
        </div>
        <app-warrior-form-select
            label="Verbal Skills"
            sublabel="On a scale of 1 (nonverbal) to 5 (fluent with clear enunciation), please rate the
            quality of your verbal communications."
            [control]="formState.controls.verbalSkill"
            [options]="range(1,5)">
        </app-warrior-form-select>
        <div class="border-bottom">
            <h5 class="section-title p-3 mb-0 py-4">Disability</h5>
        </div>
        <app-warrior-form-input
            label="What is your primary disability?"
            [control]="formState.controls.primaryDisability"
            placeholder="Disability Name">
        </app-warrior-form-input>
        <app-warrior-form-select
            label="Physical Strength"
            sublabel="On a scale of 1 (weakest) to 10 (strongest), please rate your physical strength."
            [control]="formState.controls.physicalStrength"
            [options]="range(1,10)">
        </app-warrior-form-select>
        <app-warrior-form-select
            label="Activity Level"
            sublabel="On a scale of 1-10, please rate your activity level. (Activity is the amount of
            movement you may do throughout the day.)"
            [control]="formState.controls.activityLevel"
            [options]="range(1,10)">
        </app-warrior-form-select>
        <app-warrior-form-select
            label="Mobility Level"
            sublabel="On a scale of 1-10, please rate your mobility level. (Mobility is the ability to
            move your body.)"
            [control]="formState.controls.mobilityLevel"
            [options]="range(1,10)">
        </app-warrior-form-select>
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0 w-50 form-field-container">Are you able to maneuver your body to adjust your balance
                if pulled by service dog?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.ableToManeuver" class="form-check-input" type="radio"
                    name="ableToManeuver" id="ableToManeuver_Yes" [value]="true">
                    <label class="form-check-label" for="ableToManeuver_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.ableToManeuver" class="form-check-input" type="radio"
                    name="ableToManeuver" id="ableToManeuver_No" [value]="false">
                    <label class="form-check-label" for="ableToManeuver_No">
                        No
                    </label>
                </div>
            </div>
        </div>
        <app-warrior-form-select
            label="Braces"
            sublabel="How often do you use braces for assisted movement?"
            [control]="formState.controls.braceFrequency"
            [options]="braceFrequencyOptions">
        </app-warrior-form-select>

        <app-warrior-form-input
            inputType="textarea"
            label="Please list all current prescription medications"
            [control]="formState.controls.prescriptionMedications"
            placeholder="">
        </app-warrior-form-input>

        <!-- Allergies to Medications -->
        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.allergies.value === true">
                <h6 class="text-dark mb-0">Do you have any allergies to medications?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.allergies" class="form-check-input" type="radio"
                        name="allergies" id="allergies_Yes" [value]="true">
                        <label class="form-check-label" for="allergies_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.allergies" class="form-check-input" type="radio"
                        name="allergies" id="allergies_No" [value]="false">
                        <label class="form-check-label" for="allergies_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.allergies.value === true"
                label="What medications are you allergic to?"
                [showBottomBorder]="false"
                [control]="formState.controls.allergiesStatement"
            ></app-warrior-form-input>
        </div>

        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Dominant Hand?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.dominantHandRight" class="form-check-input" type="radio"
                    name="dominantHandRight" id="dominantHandRight_Left" [value]="false">
                    <label class="form-check-label" for="dominantHandRight_Left">
                        Left
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.dominantHandRight" class="form-check-input" type="radio"
                    name="dominantHandRight" id="dominantHandRight_Right" [value]="true">
                    <label class="form-check-label" for="dominantHandRight_Right">
                        Right
                    </label>
                </div>
            </div>
        </div>

        <!-- History of Falling -->
        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.historyOfFalling.value === true">
                <h6 class="text-dark mb-0">Do you have a history of falling?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.historyOfFalling" class="form-check-input" type="radio"
                        name="historyOfFalling" id="historyOfFalling_Yes" [value]="true">
                        <label class="form-check-label" for="historyOfFalling_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.historyOfFalling" class="form-check-input" type="radio"
                        name="historyOfFalling" id="historyOfFalling_No" [value]="false">
                        <label class="form-check-label" for="historyOfFalling_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.historyOfFalling.value === true"
                label="When was the last time you fell?"
                [showBottomBorder]="false"
                [control]="formState.controls.historyOfFallingStatement"
            ></app-warrior-form-input>
        </div>

        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-start p-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Who is your primary physician?</h6>
            <div class="d-flex flex-column w-50 form-field-container">
                <app-warrior-form-input
                    label="Physician’s Name"
                    [control]="formState.controls.primaryPhysicianName">
                </app-warrior-form-input>
                <app-warrior-form-input
                    label="Physician’s Phone Number"
                    [control]="formState.controls.primaryPhysicianPhoneNumber">
                </app-warrior-form-input>
            </div>
        </div>
        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Adaptive Equipment</h5>
            <small class="text-dark">Adaptive Equipment Being Used (please list specific details, if applicable, in the
                space provided for each assistive device).</small>
        </div>
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <h6 class="text-dark mb-0">Assistive Devices</h6>

                <div class="d-flex flex-column w-50 form-field-container"
                    *ngIf="assistiveDevices$ | async as assistiveDevices">
                    <div class="border-bottom pb-4" [class.pt-2]="i > 0"
                        *ngFor="let group of assistiveDevices.controls; trackBy: trackByIndex; let i = index">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-dark m-0">Device {{i + 1}}</h6>
                            <button class="remove-dynamic-item-button" type="button" (click)="removeAssistiveDevice(i)">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>
                        </div>

                        <app-warrior-nested-form-select
                            label="Select equipment piece"
                            [control]="group.controls.equipmentPiece"
                            [options]="['Cane','Walker','Scooter','Wheelchair','Other']"
                        ></app-warrior-nested-form-select>

                        <app-warrior-nested-form-input
                            label="List specific details"
                            [control]="group.controls.specificDetails"
                            inputType="textarea"
                        ></app-warrior-nested-form-input>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <button type="button" (click)="addAssistiveDevice()" class="btn btn-secondary">ADD A DEVICE</button>
            </div>
        </div>

        <app-warrior-form-input
            label="Physical Limitations"
            sublabel="Please list any physical limitations, significant medical conditions or
            illnesses, injuries, or surgeries about which we need to be aware."
            [control]="formState.controls.physicalLimitations"
            placeholder="Leave a comment here">
        </app-warrior-form-input>

        <app-warrior-form-input
            label="Daily Life Effects"
            sublabel="How does your disability affect your daily life? What are your functional
            limitations?
            Describe problems such as carrying items, walking distances, leaving home on your own, ability to be
            in
            crowds, ability to be in large groups, driving a car, or any others."
            [control]="formState.controls.dailyLifeEffects"
            placeholder="Leave a comment here">
        </app-warrior-form-input>

        <app-warrior-form-input
            label="Other Limitations"
            sublabel="Please describe any other limitations you may have such as mobility, reaction
            speed,
            balance, vision, speech, heat/cold sensitive, learning impairments, or anything else you feel we
            should know
            to best accommodate your needs."
            [control]="formState.controls.otherLimitations"
            placeholder="Leave a comment here">
        </app-warrior-form-input>
        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Daily Routines</h5>
            <small class="text-dark">Do you handle any of the following by yourself? Please note if any of the following is
                done with assistance and who provides the support.</small>
        </div>

        <!-- Routine Medications -->
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-center mb-3 responsive-form-field"
                [class.mb-3]="formState.controls.doOwnRoutineMedications.value === false">
                <h6 class="text-dark mb-0">Do you take routine medications yourself or require assistance?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.doOwnRoutineMedications" class="form-check-input" type="radio"
                        name="routineMedications" id="routineMedications_No" [value]="false">
                        <label class="form-check-label" for="routineMedications_No">
                            Other
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.doOwnRoutineMedications" class="form-check-input" type="radio"
                        name="routineMedications" id="routineMedications_Yes" [value]="true">
                        <label class="form-check-label" for="routineMedications_Yes">
                            Self
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.doOwnRoutineMedications.value === false"
                label="Who helps you with this?"
                [showBottomBorder]="false"
                [control]="formState.controls.doOwnRoutineMedicationsAssistant"
            ></app-warrior-form-input>
        </div>

        <!-- Do Own Finances -->
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-center mb-3 responsive-form-field"
                [class.mb-3]="formState.controls.doOwnFinances.value === false">
                <h6 class="text-dark mb-0">Do you do your own finances?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.doOwnFinances" class="form-check-input" type="radio"
                        name="doOwnFinances" id="doOwnFinances_No" [value]="false">
                        <label class="form-check-label" for="doOwnFinances_No">
                            Other
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.doOwnFinances" class="form-check-input" type="radio"
                        name="doOwnFinances" id="doOwnFinances_Yes" [value]="true">
                        <label class="form-check-label" for="doOwnFinances_Yes">
                            Self
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.doOwnFinances.value === false"
                label="Who helps you with this?"
                [showBottomBorder]="false"
                [control]="formState.controls.financialAssistant"
            ></app-warrior-form-input>
        </div>

        <!-- Do Own Housecleaning -->
        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.doHousecleaning.value === false">
                <h6 class="text-dark mb-0">Do you do your own housecleaning?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.doHousecleaning" class="form-check-input" type="radio"
                        name="doHousecleaning" id="doHousecleaning_No" [value]="false">
                        <label class="form-check-label" for="doHousecleaning_No">
                            Other
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.doHousecleaning" class="form-check-input" type="radio"
                        name="doHousecleaning" id="doHousecleaning_Yes" [value]="true">
                        <label class="form-check-label" for="doHousecleaning_Yes">
                            Self
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.doHousecleaning.value === false"
                label="Who helps you with this?"
                [showBottomBorder]="false"
                [control]="formState.controls.doHousecleaningAssistant"
            ></app-warrior-form-input>
        </div>

        <!-- Do Own Meals -->
        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.doOwnMeals.value === false">
                <h6 class="text-dark mb-0">Do you do your own meals?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.doOwnMeals" class="form-check-input" type="radio"
                        name="doOwnMeals" id="doOwnMeals_No" [value]="false">
                        <label class="form-check-label" for="doOwnMeals_No">
                            Other
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.doOwnMeals" class="form-check-input" type="radio"
                        name="doOwnMeals" id="doOwnMeals_Yes" [value]="true">
                        <label class="form-check-label" for="doOwnMeals_Yes">
                            Self
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.doOwnMeals.value === false"
                label="Who helps you with this?"
                [showBottomBorder]="false"
                [control]="formState.controls.doOwnMealsAssistant"
            ></app-warrior-form-input>
        </div>

        <!-- Dress Self -->
        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.dressSelf.value === false">
                <h6 class="text-dark mb-0">Do you dress yourself?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.dressSelf" class="form-check-input" type="radio"
                        name="dressSelf" id="dressSelf_No" [value]="false">
                        <label class="form-check-label" for="dressSelf_No">
                            Other
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.dressSelf" class="form-check-input" type="radio"
                        name="dressSelf" id="dressSelf_Yes" [value]="true">
                        <label class="form-check-label" for="dressSelf_Yes">
                            Self
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.dressSelf.value === false"
                label="Who helps you with this?"
                [showBottomBorder]="false"
                [control]="formState.controls.dressSelfAssistant"
            ></app-warrior-form-input>
        </div>

        <!-- Run Own Errands -->
        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.runOwnErrands.value === false">
                <h6 class="text-dark mb-0">Do you run your own errands?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.runOwnErrands" class="form-check-input" type="radio"
                        name="runOwnErrands" id="runOwnErrands_No" [value]="false">
                        <label class="form-check-label" for="runOwnErrands_No">
                            Other
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.runOwnErrands" class="form-check-input" type="radio"
                        name="runOwnErrands" id="runOwnErrands_Yes" [value]="true">
                        <label class="form-check-label" for="runOwnErrands_Yes">
                            Self
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.runOwnErrands.value === false"
                label="Who helps you with this?"
                [showBottomBorder]="false"
                [control]="formState.controls.runOwnErrandsAssistant"
            ></app-warrior-form-input>
        </div>

        <!-- Do Personal Care -->
        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.doOwnPersonalCare.value === false">
                <h6 class="text-dark mb-0">Do you do your own personal care?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.doOwnPersonalCare" class="form-check-input" type="radio"
                        name="doOwnPersonalCare" id="doOwnPersonalCare_No" [value]="false">
                        <label class="form-check-label" for="doOwnPersonalCare_No">
                            Other
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.doOwnPersonalCare" class="form-check-input" type="radio"
                        name="doOwnPersonalCare" id="doOwnPersonalCare_Yes" [value]="true">
                        <label class="form-check-label" for="doOwnPersonalCare_Yes">
                            Self
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.doOwnPersonalCare.value === false"
                label="Who helps you with this?"
                [showBottomBorder]="false"
                [control]="formState.controls.doOwnPersonalCareAssistant"
            ></app-warrior-form-input>
        </div>

        <div class="border-bottom px-3 py-4">
            <h5 class="section-title mb-0">Miscellaneous Questions</h5>
        </div>

        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Do you have a history of alcohol/substance abuse?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.historyOfSubstanceAbuse" class="form-check-input" type="radio"
                    name="historyOfSubstanceAbuse" id="historyOfSubstanceAbuse_Yes" [value]="true">
                    <label class="form-check-label" for="historyOfSubstanceAbuse_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.historyOfSubstanceAbuse" class="form-check-input" type="radio"
                    name="historyOfSubstanceAbuse" id="historyOfSubstanceAbuse_No" [value]="false">
                    <label class="form-check-label" for="historyOfSubstanceAbuse_No">
                        No
                    </label>
                </div>
            </div>
        </div>

        <!-- Food Allergies -->
        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.foodAllergies.value === true">
                <h6 class="text-dark mb-0">Do you have any food allergies?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.foodAllergies" class="form-check-input" type="radio"
                        name="foodAllergies" id="foodAllergies_Yes" [value]="true">
                        <label class="form-check-label" for="foodAllergies_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.foodAllergies" class="form-check-input" type="radio"
                        name="foodAllergies" id="foodAllergies_No" [value]="false">
                        <label class="form-check-label" for="foodAllergies_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.foodAllergies.value === true"
                label="What are you allergic to?"
                [showBottomBorder]="false"
                [control]="formState.controls.foodAllergiesStatement"
            ></app-warrior-form-input>
        </div>

        <!-- Dietary Restrictions -->
        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.dietaryRestrictions.value === true">
                <h6 class="text-dark mb-0">Do you have any dietary restrictions?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.dietaryRestrictions" class="form-check-input" type="radio"
                        name="dietaryRestrictions" id="dietaryRestrictions_Yes" [value]="true">
                        <label class="form-check-label" for="dietaryRestrictions_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.dietaryRestrictions" class="form-check-input" type="radio"
                        name="dietaryRestrictions" id="dietaryRestrictions_No" [value]="false">
                        <label class="form-check-label" for="dietaryRestrictions_No">
                            No
                        </label>
                    </div>
                </div>
            </div>

            <app-warrior-form-input
                *ngIf="formState.controls.dietaryRestrictions.value === true"
                label="What are your dietary restrictions?"
                [showBottomBorder]="false"
                [control]="formState.controls.dietaryRestrictionsStatement"
            ></app-warrior-form-input>
        </div>

        <app-warrior-form-input
            label="How will a service dog assist you?"
            sublabel="In your own words, how would having a Service Dog aid you with your PTSD, TBI,
            and/or MST and any other mental or emotional wellness challenges? Attach an additional sheet if necessary."
            inputType="textarea"
            [control]="formState.controls.howWouldServiceDogAssist"
            placeholder="Leave a comment here">
        </app-warrior-form-input>

        <div class="border d-flex justify-content-between align-items-center p-3">
            <h6 class="text-dark mb-0">Upload Mental Health Verification Form</h6>
            <p *ngIf="hasUploadedMentalHealthVerificationForm" class="text-completed mb-0">
                <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
            </p>
            <button [disabled]="hasAnyActiveUpload" class="btn btn-secondary" (click)="uploader.click()">
                <span *ngIf="isActiveUpload('MentalHealthVerificationForm')" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{isActiveUpload('MentalHealthVerificationForm') ? 'UPLOADING...' : 'UPLOAD FORM'}}
            </button>
            <input hidden type="file" accept="application/pdf" #uploader (change)="uploadFileMentalHealthVerification($event)"/>
        </div>

        <div class="border d-flex justify-content-between align-items-center p-3">
            <h6 class="text-dark mb-0">Upload Primary Care Physician Report</h6>
            <p *ngIf="hasUploadedPhysicianReport" class="text-completed mb-0">
                <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>Completed
            </p>
            <button [disabled]="hasAnyActiveUpload" class="btn btn-secondary" (click)="uploader2.click()">
                <span *ngIf="isActiveUpload('PrimaryCarePhysicianReport')" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{isActiveUpload('PrimaryCarePhysicianReport') ? 'UPLOADING...' : 'UPLOAD FORM'}}
            </button>
            <input hidden type="file" accept="application/pdf" #uploader2 (change)="uploadPrimaryCarePhysicianReport($event)"/>
        </div>

        <app-warrior-form-input
            label="Other diagnoses or problems"
            sublabel="Please list any other mental/psychological diagnoses or problems that may help us
            assist in pairing you with a service dog."
            [control]="formState.controls.otherProblems"
            placeholder="Leave a comment here">
        </app-warrior-form-input>
        <div class="border-bottom p-3 d-flex justify-content-between align-items-center btn-container">
            <button class="btn btn-back" (click)="backToPreviousStep()">BACK TO SECTION 6 - TRANSPORTATION</button>
            <button class="btn btn-primary" (click)="continueToNextStep()">CONTINUE TO SECTION 8 - LEGAL HISTORY</button>
        </div>
    </form>
</ng-container>
