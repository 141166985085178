import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState, FormControlValueTypes, FormGroupState, NgrxValueConverter } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { setCurrentStep } from '../store/application.actions';
import { ApplicationState, ApplicationSteps } from '../store/application.state';
import { ApplicationForm } from '../store/application-form.state';

@Component({
    selector: 'app-miscellaneous',
    templateUrl: './miscellaneous.component.html',
    styleUrls: ['./miscellaneous.component.css']
})
export class MiscellaneousComponent implements OnInit {

    formState$: Observable<FormGroupState<ApplicationForm['miscellaneous']>>

    constructor(private store: Store<{ application: ApplicationState }>) {
        this.formState$ = store.select(s => s.application.applicationForm.controls.miscellaneous)
    }

    ngOnInit() {
    }

    backToPreviousStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.LegalHistory }));
        window.scrollTo(0,0);
    }

    continueToNextStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.Consent }));
        window.scrollTo(0,0);
    }

    selectValueConverter: NgrxValueConverter<string, string> = {
        convertViewToStateValue(value: string): string {
            if (!value)
                return null;
            return value;
        },

        convertStateToViewValue(value: string): string {
            if (!value)
                return '';
            return value;
        }
    }

    isInvalid<TValue extends FormControlValueTypes>(control: FormControlState<TValue>): boolean {
        return control.isTouched && control.isInvalid
    }
}
