import { setErrors, setUserDefinedProperty, setValue, StateUpdateFns, updateArray, updateGroup, validate } from "ngrx-forms";
import { required, requiredTrue } from "ngrx-forms/validation";
import { ApplicationForm, EducationalDegree } from "../application-form.state";

export const WorkAndEducationRequiredInputStateFns: StateUpdateFns<ApplicationForm['workAndEducation']> = {
    currentlyEmployed: validate(required),
    educationLevel: validate(required),
    educationalDegrees: updateArray(updateGroup<EducationalDegree>({
        name: validate(required),
        date: validate(required)
    })),
    currentlyStudent: validate(required)
}


export const WorkAndEducationOptionalInputStateFns: StateUpdateFns<ApplicationForm['workAndEducation']> = {
    workEnvironment: (c, frm) => frm.controls.currentlyEmployed.value
        ? c
        : setValue(c, null),
    jobFunctions: (c, frm) => frm.controls.currentlyEmployed.value
        ? c
        : setValue(c, null),
    typicalWorkday: (c, frm) => frm.controls.currentlyEmployed.value
        ? c
        : setValue(c, null),
    employerAwareOfApplicationForServiceDog: (c, frm) => frm.controls.currentlyEmployed.value
        ? c
        : setValue(c, null),
    planToBecomeEmployed: (c, frm) => !frm.controls.currentlyEmployed.value
        ? c
        : setValue(c, null),
    currentEducationalInstitution: (c, frm) => frm.controls.currentlyStudent.value
        ? c
        : setValue(c, null),
    currentEducationAttendanceMode: (c, frm) => frm.controls.currentlyStudent.value
        ? c
        : setValue(c, null),
    currentEducationDegreeType: (c, frm) => frm.controls.currentlyStudent.value
        ? c
        : setValue(c, null),
    educationServiceDogPolicyAcknowledgement: (c, frm) => frm.controls.currentlyStudent.value
        ? c
        : setValue(c, null),
    employerName: (c, frm) => frm.controls.currentlyEmployed.value
        ? c
        : setValue(c, null),
    jobTitle: (c, frm) => frm.controls.currentlyEmployed.value
        ? c
        : setValue(c, null)
}


export const WorkAndEducationSetOptionalInputStateFns: StateUpdateFns<ApplicationForm['workAndEducation']> = {
    jobTitle: (c, frm) => frm.controls.currentlyEmployed.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    employerName: (c, frm) => frm.controls.currentlyEmployed.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    workEnvironment: (c, frm) => frm.controls.currentlyEmployed.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    jobFunctions: (c, frm) => frm.controls.currentlyEmployed.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    typicalWorkday: (c, frm) => frm.controls.currentlyEmployed.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    employerAwareOfApplicationForServiceDog: (c, frm) => frm.controls.currentlyEmployed.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    planToBecomeEmployed: (c, frm) => !frm.controls.currentlyEmployed.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    employerAccommodationAcknowledgment: (c, frm) =>
        frm.controls.currentlyEmployed.value || frm.controls.planToBecomeEmployed.value
            ? setUserDefinedProperty(c, 'isOptional', false)
            : setUserDefinedProperty(c, 'isOptional', true),
    currentEducationalInstitution: (c, frm) => frm.controls.currentlyStudent.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    currentEducationAttendanceMode: (c, frm) => frm.controls.currentlyStudent.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    currentEducationDegreeType: (c, frm) => frm.controls.currentlyStudent.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true),
    educationServiceDogPolicyAcknowledgement: (c, frm) => frm.controls.currentlyStudent.value
        ? setUserDefinedProperty(c, 'isOptional', false)
        : setUserDefinedProperty(c, 'isOptional', true)
}


export const WorkAndEducationOptionalInputValidatorStateFns: StateUpdateFns<ApplicationForm['workAndEducation']> = {
    employerName: (c, frm) => frm.controls.currentlyEmployed.value
        ? validate(c, required)
        : setErrors(c, {}),
    jobTitle: (c, frm) => frm.controls.currentlyEmployed.value
        ? validate(c, required)
        : setErrors(c, {}),
    workEnvironment: (c, frm) => frm.controls.currentlyEmployed.value
        ? validate(c, required)
        : setErrors(c, {}),
    jobFunctions: (c, frm) => frm.controls.currentlyEmployed.value
        ? validate(c, required)
        : setErrors(c, {}),
    typicalWorkday: (c, frm) => frm.controls.currentlyEmployed.value
        ? validate(c, required)
        : setErrors(c, {}),
    employerAwareOfApplicationForServiceDog: (c, frm) => frm.controls.currentlyEmployed.value
        ? validate(c, required)
        : setErrors(c, {}),
    planToBecomeEmployed: (c, frm) => !frm.controls.currentlyEmployed.value
        ? validate(c, required)
        : setErrors(c, {}),
    employerAccommodationAcknowledgment: (c, frm) =>
        frm.controls.currentlyEmployed.value || frm.controls.planToBecomeEmployed.value
            ? validate(c, required, requiredTrue)
            : setErrors(c, {}),
    currentEducationalInstitution: (c, frm) => frm.controls.currentlyStudent.value
        ? validate(c, required)
        : setErrors(c, {}),
    currentEducationAttendanceMode: (c, frm) => frm.controls.currentlyStudent.value
        ? validate(c, required)
        : setErrors(c, {}),
    currentEducationDegreeType: (c, frm) => frm.controls.currentlyStudent.value
        ? validate(c, required)
        : setErrors(c, {}),
    educationServiceDogPolicyAcknowledgement: (c, frm) => frm.controls.currentlyStudent.value
        ? validate(c, required, requiredTrue)
        : setErrors(c, {})
}
