import { Component } from '@angular/core';
//Icons
import { faStar, faArrowRight, IconDefinition } from '@fortawesome/free-solid-svg-icons'
//import sign in and sign up components
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignInComponent } from './sign-in/sign-in.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  faStar:IconDefinition = faStar;
  faArrowRight:IconDefinition = faArrowRight;

  signUp: boolean = true;

  toggleSignInComponents() {
    this.signUp = !this.signUp
  }
}
