import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../authorize.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
    selector: 'app-login-menu',
    templateUrl: './login-menu.component.html',
    styleUrls: ['./login-menu.component.css']
})
export class LoginMenuComponent implements OnInit {
    public isAuthenticated$: Observable<boolean>;
    public isAdmin$: Observable<boolean>;
    public isImpersonated$: Observable<boolean>;
    public impersonatedBy$: Observable<string | null>;
    public userName$: Observable<string>;

    constructor(private authorizeService: AuthorizeService) { }

    ngOnInit() {
        this.isAuthenticated$ = this.authorizeService.isAuthenticated();
        this.isAdmin$ = this.authorizeService.isAdministrator();
        this.isImpersonated$ = this.authorizeService.isImpersonated();
        this.impersonatedBy$ = this.authorizeService.impersonatedByUser();
        this.userName$ = this.authorizeService.getUser().pipe(map(u => u && u.name));
    }
}
