import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { faBars, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { AuthorizeService } from "src/api-authorization/authorize.service";
import { map } from "rxjs/operators";

@Component({
    selector: "app-nav-menu",
    templateUrl: "./nav-menu.component.html",
    styleUrls: ["./nav-menu.component.css"],
})


export class NavMenuComponent implements OnInit {
    faBars: IconDefinition = faBars;
    isNotAuthenticated$: Observable<boolean>

    constructor(private authorizeService: AuthorizeService) { }

    ngOnInit(): void {
        this.isNotAuthenticated$ = this.authorizeService.isAuthenticated().pipe(map(a => !a));
    }
}
