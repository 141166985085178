<div class="bg-form-field border-bottom d-flex justify-content-between align-items-center p-3 py-4 responsive-form-field">
    <div class="d-flex flex-column w-25 form-field-container">
        <label [htmlFor]="control.id" class="text-dark w-50 form-field-container mb-0">{{label}}</label>
        <small class="text-dark">{{sublabel}}</small>
    </div>
    <select *ngIf="control" class="form-select form-control"
        [id]="control.id"
        [ngrxFormControlState]="control"
        [ngrxValueConverter]="selectValueConverter"
        [class.is-valid]="control.isValid"
        [class.is-invalid]="control.isInvalid"
        [attr.aria-label]="ariaLabel || label">
        <option [value]="''">{{placeholder}}</option>
        <option *ngFor="let option of options" [value]="option">{{option}}</option>
    </select>
    <span *ngIf="!control" class="text-danger text-right">Error loading form field.</span>
</div>
