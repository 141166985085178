<div class="container p-5 bg-white shadow-sm rounded d-flex justify-content-between responsive-container">
    <div class="w-75">
        <div class="mr-4">
            <h1 class="text-blue">Welcome back, {{userFirstName}}.</h1>
            <div class="alert-notice mt-4">
                <h5 class="text-blue">Good news! Your application has been submitted.</h5>
                <p class="text-dark">
                    Please allow for 3 business days for your application to be processed. We will make
                    contact with you if anything arises.
                    <!-- This text will need to be provided K9s on how we want to approach this.-->
                </p>
                <!-- <button class="btn btn-secondary">GO TO HOME PAGE</button> -->
            </div>
        </div>
    </div>
    <img class="w-50" src="../../assets/celebration-dog.png" alt="dog with party hat and confetti" />
</div>
