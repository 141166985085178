import { FormGroupState, createFormGroupState } from "ngrx-forms";
import { WarriorApplicationMetadata } from "src/app/services/application.service";
import { ApplicationForm } from './application-form.state';

export const ServiceBranches = [
    'Army',
    'Air Force',
    'Coast Guard',
    'Marine Corps',
    'Navy',
    'Space Force'
] as const;

export const ServiceRanks: Record<typeof ServiceBranches[number], string[]> = {
    Army: ['PVT','PFC','SPC','CPL','SGT','SSG','SFC','MSG','1SG','SGM','CSM','SMA','2LT','1LT','CPT','MAJ','LTC','COL','BG','MG','LTG','GEN','GA','WO1','CW2','CW3','CW4','CW5'],
    "Air Force": ['AB','Amn','A1C','SrA','SSgt','TSgt','MSgt','SMSgt','CMSgt','CCM','CMSAF','2LT','1LT','CPT','MAJ','LTC','COL','BG','MG','LTG','GEN','GAF'],
    "Coast Guard": ['SR','SA','SN','PO3','PO2','PO1','CPO','SCPO','MCPO','CMC','MCPO-CG','ENS','LTJG','LT','LCDR','CDR','CAPT','RADM(L)','RADM(U)','VADM','ADM','FADM','W-1','CW02','CW03','CW04'],
    "Marine Corps": ['Pvt','PFC','LCpl', 'Cpl','Sgt','SSgt','GySgt','MSgt','1stSgt','MGySgt','SgtMaj','SgtMajMC','2LT','1LT','Capt','MAJ','LTC','COL','BG','MG','LTG','GEN','GAMC','W0','CW02','CWO3','CW04','CW05'],
    Navy: ['SR','SA','SN','PO3','PO2','PO1','CPO','SCPO','MCPO','FORMC','FLTMC','MCPON','ENS','LTJG','LT','LCDR','CDR','CAPT','RADM(L)','RADM(U)','VADM','ADM','FADM','W1','CW02','CW03','CW04'],
    "Space Force": ['Spc1','Spc2','Spc3','Spc4','Sgt','TSgt','MSgt','SMSgt','CMSgt','CMSSF','SEAC','2LT','1LT','CPT','MAJ','LTC','COL','BG','MG','LTG','GEN']
};

export const AllServiceRanks = Object.values(ServiceRanks).reduce((a, v) => a.concat(v), []);

export const PayGrades = ['E1','E2','E3','E4','E5','E6','E7','E8','E9','E (Special)','O1','O2','O3','O4','O5','O6','O7','O8','09','O10','O (Special)','W1','W2','W3','W4','W5'];

export enum ApplicationSteps {
    ApplicationIntro = 'ApplicationIntro',
    PersonalInfo = 'PersonalInfo',
    HouseholdInfo = 'HouseholdInfo',
    MilitaryInfo = 'MilitaryInfo',
    BiographicalInfo = 'BiographicalInfo',
    WorkAndEducation = 'WorkAndEducation',
    Transportation = 'Transportation',
    MedicalAndMentalHealth = 'MedicalAndMentalHealth',
    LegalHistory = 'LegalHistory',
    Miscellaneous = 'Miscellaneous',
    Consent = 'Consent',
    CertificationAndSignature = 'CertificationAndSignature'
}

export interface ApplicationState {
    loading: boolean;
    saving: boolean;
    metadata?: WarriorApplicationMetadata,
    showErrorSummary: boolean;
    applicationForm: FormGroupState<ApplicationForm>;
    currentTab: ApplicationSteps;
}

export const initialState: ApplicationState = {
    loading: false,
    saving: false,
    metadata: undefined,
    showErrorSummary: false,
    applicationForm: createFormGroupState<ApplicationForm>("WarriorApplicationForm", {
        personalInfo: {
            firstName: '',
            lastName: '',
            middleName: '',
            maidenName: '',
            socialSecurityNumber: '',
            dateOfBirth: null,
            sexGenderIdentity: null,
            raceEthnicityIdentity: null,
            maritalPartnershipStatus: null,
            height: null,
            weightPounds: null,
            homePhone: '',
            cellPhone: '',
            primaryEmailAddress: '',
            hasUploadedHeadshotPhoto: false,
            has_uploaded_discharge_document_214_form: false,
            hasUploadedAgreementToSupportForm: false,

            currentAddress: {
                moveInDate: '',
                moveOutDate: null,
                streetAddress: '',
                unitNumber: null,
                city: '',
                stateOrProvince: '',
                postalCode: ''
            },
            previousAddresses: [],
            emergencyContacts: [{
                name: '',
                relation: '',
                phoneNumber: ''
            }],

            advocateContactIdentity: '',
            advocateContactName: '',
            advocateContactPhoneNumber: '',
            advocateContactEmailAddress: '',

            personalReferences: [{
                name: '',
                phoneNumber: '',
                email: ''
            }],

            personalReferenceContactConsent: false,
            personalReferenceInfoDisclosureConsent: false,

            applicationAssistanceProvidedIndicator: false,
            applicationAssistantName: '',
            applicationAssistantRelation: '',
            applicationAssistantPhoneNumber: ''
        },
        householdInfo: {
            householdMembers: [],
            serviceAnimalsInHome: false,
            serviceAnimals: [],
            petsInHome: false,
            pets: [],
            dogAllergyInHome: false,
            homeIsOwned: false,
            homeType: '',
            hasAYard: null,
            yardIsFenced: false,
            homeLocationType: '',
            homeIsFullyAccessible: false
        },
        militaryInfo: {
            serviceDetailsFoiaConsent: false,
            rank: '',
            payGrade: '',
            age: null,
            servicePeriods: [{
                enteredDate: null,
                dischargeDate: null,
                dischargeType: ''
            }],
            serviceLocations: [{
                startDate: null,
                endDate: null,
                location: ''
            }],
            deploymentLocations: [{
                startDate: null,
                endDate: null,
                location: ''
            }],
            awardsOrDecorations: '',
            era: null
        },
        biographicalInfo: {
            descriptionOfSelf: '',
            angerAndStress: '',
            emotionalConflict: '',
            personalConfrontations: '',
            anxietyOrPtsd: ''
        },
        workAndEducation: {
            currentlyEmployed: false,
            employerAwareOfApplicationForServiceDog: false,
            planToBecomeEmployed: false,
            employerAccommodationAcknowledgment: false,
            educationLevel: '',
            educationalDegrees: [],
            currentlyStudent: false,
            currentEducationalInstitution: '',
            currentEducationAttendanceMode: '',
            currentEducationDegreeType: '',
            educationServiceDogPolicyAcknowledgement: false
        },
        transportation: {
            currentAndValidDriversLicense: false,
            driveYourself: false,
            primaryDriver: '',
            transportationAccess: false,
            primaryTravelMeans: '',
            adaptiveVehicle: false,
            adaptiveExplanation: ''
        },
        medicalAndMentalHealth: {
            postTraumaticStressDisability: false,
            traumaticBrainInjury: false,
            militarySexualTrauma: false,
            diagnoses: [],
            receiveMedicalServices: false,
            nearestVAFacility: '',
            verbalSkill: '',
            primaryDisability: '',
            physicalStrength: '',
            activityLevel: '',
            mobilityLevel: '',
            ableToManeuver: false,
            braceFrequency: '',
            prescriptionMedications: '',
            allergies: false,
            dominantHandRight: false,
            historyOfFalling: false,
            primaryPhysicianName: null,
            primaryPhysicianPhoneNumber: null,
            assistedDevices: [],
            physicalLimitations: '',
            dailyLifeEffects: '',
            otherLimitations: '',
            doOwnRoutineMedications: null,
            doOwnFinances: false,
            financialAssistant: '',
            doHousecleaning: false,
            doOwnMeals: false,
            dressSelf: false,
            runOwnErrands: false,
            doOwnPersonalCare: false,
            historyOfSubstanceAbuse: false,
            foodAllergies: false,
            foodAllergiesStatement: '',
            dietaryRestrictions: false,
            dietaryRestrictionsStatement: '',
            howWouldServiceDogAssist: '',
            otherProblems: '',
            hasUploadedMentalHealthVerificationForm: false,
            hasUploadedPhysicianReport: false
        },
        legalHistory: {
            felony: false,
            felonyStatement: '',
            chargeStatement: '',
            yearChargedStatement: '',
            stateChargedIn: '',
            finalVerdictStatement: '',
            isCaseOpen: false,
            isCurrentlyOnParole: false,
            isCurrentlyOnProbation: false,
            misdemeanor: false,
            misdemeanorStatement: '',
            criminalTrafficViolation: false,
            criminalTrafficViolationStatement: '',
            otherTrafficViolation: false,
            otherTrafficViolationStatement: '',
            arrestedStatement: '',
            historyOfViolence: false,
            struckPerson: false,
            struckAnimal: false,
        },
        miscellaneous: {
            ownedPetDog: false,
            familiarWithDogCare: false,
            cynophobia: false,
            householdSupportApplication: false,
            householdSupportApplicationNonSupportingPerson: '',
            obstaclesAndIssues: '',
            acceptedToAlternateDogOrg: false,
            acceptedToAlternateDogOrgWhichOrg: '',
            haveAlternateDog: '',
            deniedServiceDog: false,
            deniedServiceDogWhichOrg: '',
            learnAboutK9: '',
            providingContinuousPetInsurance: false,
            joinInprogressClass: false,
            joinInprogressClassTimeFrame: ''
        },
        consent: {
            informationReleaseConsent: false,
            doctors: [],
            psychologistsAndPsychiatrists: [],
            veterinarians: []
        },
        certificationAndSignature: {
            accurateInfo: false,
            waitingListRemoval: false,
            notMedicalTreatment: false,
            noMedicalStaff: false,
            noFirearms: false,
            generalBackgroundInvestigation: false,
            reasonablePrivacyEffort: false,
            noAggressiveBehavior: false,
            noIllegalDrugs: false,
            cancellationAcknowledgement: false,
            criminalBackgroundInvestigation: false,
            serviceDogResponsibility: false
        }
    }),
    currentTab: ApplicationSteps.ApplicationIntro
};
