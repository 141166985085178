<div>
    <h2 class="mb-0">Sign in.</h2>
    <small>Sign into your account. If you do not have an account, <a href="./" class="sign-in-link">you can sign up
            here</a></small>

    <form class="mt-4">
        <label>Your Email</label>
        <input type="text" class="form-control mb-3" id="email" aria-describedby="email">
        <!-- TODO.JB - Validation Error Message -->

        <label>Your Password</label>
        <input type="password" class="form-control mb-4" id="password" aria-describedby="password">
        <!-- TODO.JB - Validation Error Message -->

        <div class="d-flex">
            <input type="checkbox" id="staySignedIn" name="staySignedIn" class="mr-1">
            <label for="staySignedIn" class="w-100 mb-4">Keep me signed in</label>
        </div>

        <button class="btn-primary btn-block">SIGN IN<fa-icon [icon]="faArrowRight" class="ml-1"></fa-icon></button>
    </form>

    <p class="small-text-label text-center mt-3 mb-2">Need an account? <a href="./" class="sign-in-link">Sign up</a></p>

    <p class="small-text-label text-center">
        <a href="./" class="sign-in-link">Forgot your password?</a>
    </p>
</div>
