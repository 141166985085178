import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormArrayState, FormControlState, FormControlValueTypes, FormGroupState, NgrxValueConverter } from 'ngrx-forms';
import { Observable, Subscription } from 'rxjs';
import { setCurrentStep, addPreviousAddress, removePreviousAddress, addEmergencyContact, removeEmergencyContact, addPersonalReference, removePersonalReference, uploadedHeadshotPhoto, savingApplication } from '../store/application.actions';
import { ApplicationState, ApplicationSteps } from '../store/application.state';
import { faCheck, faTimesCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { ApplicationForm, EmergencyContact, HomeAddress, PersonalReference } from '../store/application-form.state';
import { ApplicationService, WarriorApplicationMetadata, WarriorApplicationViewModel } from 'src/app/services/application.service';
import { FileService } from 'src/app/services/file.service';
import { Relations } from '../store/reference-data';

@Component({
    selector: 'app-personal-info',
    templateUrl: './personal-info.component.html',
    styleUrls: ['./personal-info.component.css']
})
export class PersonalInfoComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    private currentTab: string;
    private applicationMetadata?: WarriorApplicationMetadata;
    private applicationForm?: ApplicationForm;

    faCheck: IconDefinition = faCheck;
    faTimesCircle: IconDefinition = faTimesCircle;

    formState$: Observable<FormGroupState<ApplicationForm['personalInfo']>>
    previousAddresses$: Observable<FormArrayState<HomeAddress>>;
    emergencyContacts$: Observable<FormArrayState<EmergencyContact>>;
    personalReferences$: Observable<FormArrayState<PersonalReference>>;

    isUploading: boolean;
    hasUploadedHeadshotPhoto: boolean;

    genderIdentityOptions: string[] = ['Female', 'Male', 'Non-binary', 'Transgender', 'Other', 'I prefer not to say'];
    maritalPartnershipStatusOptions: string[] = ['Single', 'Married', 'Domestic Partnership', 'Widowed', 'Divorced'];
    emergencyContactRelationOptions: string[] = Relations;
    applicationAssistantRelationOptions: string[] = Relations;
    advocateContactIdentityOptions: string[] = ['Spouse or partner', 'Designated relative', 'Designated friend', 'Decline to identify'];
    raceEthnicityIdentityOptions: string[] = ['White or Caucasian', 'Black or African American', 'Hispanic or Latino/Latina', "Asian, South Asian, or Pac' Islander", 'Multiracial', 'Native American or Alaskan Native', 'Prefer not to answer', 'Other'];

    showAdvocateContactInfo: boolean = false

    constructor(private store: Store<{ application: ApplicationState }>,
                private applicationService: ApplicationService,
                private fileService: FileService) {
        this.formState$ = store.select(s => s.application.applicationForm.controls.personalInfo)

        this.previousAddresses$ = this.store.select(s =>
            s.application.applicationForm.controls.personalInfo.controls.previousAddresses);

        this.emergencyContacts$ = this.store.select(s =>
            s.application.applicationForm.controls.personalInfo.controls.emergencyContacts);

        this.personalReferences$ = this.store.select(s =>
            s.application.applicationForm.controls.personalInfo.controls.personalReferences);
    }

    ngOnInit() {
        this.subscription.add(this.store.select(s => s.application).subscribe(state => {
            this.currentTab = state.currentTab;
            this.applicationMetadata = state.metadata;
            this.applicationForm = state.applicationForm?.value;
            const personalInfoControls = state.applicationForm?.controls.personalInfo.controls;

            this.hasUploadedHeadshotPhoto = personalInfoControls.hasUploadedHeadshotPhoto.value;

            this.showAdvocateContactInfo =
                personalInfoControls.advocateContactIdentity.value !== 'Decline to identify'
                && personalInfoControls.advocateContactIdentity.value !== null
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addPreviousAddress() {
        this.store.dispatch(addPreviousAddress());
    }

    removePreviousAddress(index: number) {
        this.store.dispatch(removePreviousAddress({ index }));
    }

    addEmergencyContact() {
        this.store.dispatch(addEmergencyContact());
    }

    removeEmergencyContact(index: number) {
        if (index < 1) {
            console.warn('WARNING: At least one emergency contact is required. Cannot remove final contact.');
            return;
        }

        this.store.dispatch(removeEmergencyContact({ index }));
    }

    addPersonalReference() {
        this.store.dispatch(addPersonalReference());
    }

    removePersonalReference(index: number) {
        if (index < 1) {
            console.warn('WARNING: At least one personal reference is required. Cannot remove final reference.');
            return;
        }

        this.store.dispatch(removePersonalReference({ index }));
    }

    async uploadHeadshot($event) {
        const file = $event.target.files[0];

        this.isUploading = true;

        try {
            await this._saveApplication();
            await this.fileService.uploadFile(file, 'Headshot');
            this.store.dispatch(uploadedHeadshotPhoto());
            await this._saveApplication();
        } finally {
            this.isUploading = false;
        }
    }

    continueToNextStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.HouseholdInfo }));
        window.scrollTo(0, 0);
    }

    isInvalid<TValue extends FormControlValueTypes>(control: FormControlState<TValue>): boolean {
        return control.isTouched && control.isInvalid
    }

    trackByIndex(index, item) {
        return index;
    }

    selectValueConverter: NgrxValueConverter<string, string> = {
        convertViewToStateValue(value: string): string {
            if (!value)
                return null;
            return value;
        },

        convertStateToViewValue(value: string): string {
            if (!value)
                return '';
            return value;
        }
    }

    // HACK.JB - Duplicated from application.component.ts
    private async _saveApplication() {
        let viewModel: WarriorApplicationViewModel = {
            application: this.applicationForm,
            metadata: {
                applicationStep: this.currentTab,
                ...this.applicationMetadata
            }
        }

        this.store.dispatch(savingApplication({ saving: true }));

        try {
            await this.applicationService.saveApplication(viewModel);
        } finally {
            this.store.dispatch(savingApplication({ saving: false }));
        }
    }
}
