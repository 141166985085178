import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { ApplicationService, WarriorApplicationMetadata, WarriorApplicationViewModel } from "../services/application.service";
import { ApplicationState, ApplicationSteps } from "./store/application.state";
import { ApplicationForm } from './store/application-form.state';
import {
    incrementLoading,
    decrementLoading,
    loadApplication,
    setCurrentStep,
    uploadedHeadshotPhoto,
    savingApplication,
    uploadedMentalHealthVerificationForm,
    uploadedPhysicianReport,
    uploadedDD214Form,
    uploadedAgreementToSupportForm
} from './store/application.actions';
import {
    faCheckCircle,
    faPaperclip,
    faCheck,
    faTimes,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { StepMetadata, STEP_METADATA } from "../common/step-metadata";
import { FileService } from "../services/file.service";
import { Router } from "@angular/router";
import { AccountService } from "../services/account.service";

type UploadTypes =
    'Headshot' |
    'MentalHealthVerificationForm' |
    'PrimaryCarePhysicianReport' |
    'DD214Form' |
    'AgreementToSupportForm';
type UploaderMap = { [K in keyof UploadTypes]?: boolean }

@Component({
    selector: "app-application",
    templateUrl: "./application.component.html",
    styleUrls: ["./application.component.css"],
})
export class ApplicationComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    private activeUploads: UploaderMap = {}

    faCheckCircle: IconDefinition = faCheckCircle;
    faPaperclip: IconDefinition = faPaperclip;
    faCheck: IconDefinition = faCheck;
    faTimes: IconDefinition = faTimes;

    saving = false;
    completed = true;

    userInfo: ({ type: string, value: string })[] = [];

    currentTab: string;
    applicationSteps: StepMetadata[];
    applicationMetadata?: WarriorApplicationMetadata;
    applicationForm?: ApplicationForm;

    hasUploadedHeadshotPhoto: boolean;
    hasUploadedMentalHealthVerificationForm: boolean;
    hasUploadedPhysicianReport: boolean;
    // Note: Due to JSON serialization, the following variable needs to be snake case.
    has_uploaded_discharge_document_214_form: boolean;
    hasUploadedAgreementToSupportForm: boolean;

    isHeadshotPhotoValid: boolean = true;
    headshotPhotoErrorMsg: string;
    isMentalHealthVerificationFormValid: boolean = true;
    mentalHealthVerificationFormErrorMsg: string;
    isPhysicianReportValid: boolean = true;
    physicianReportErrorMsg: string;
    isDD214FormValid: boolean = true;
    dd214FormErrorMsg: string;
    isAgreementToSupportFormValid: boolean = true;
    agreementToSupportFormErrorMsg: string;

    // Note: These are taken from the server-side logic for supported file extensions.
    headshotPhotoSupportedExtensions = "jpg | jpeg | png";
    mentalHealthFormSupportedExtensions = "jpg | jpeg | png | pdf";
    primaryCareFormSupportedExtensions = "jpg | jpeg | png | pdf";
    dd214FormSupportedExtensions = "jpg | jpeg | png | pdf";
    agreementToSupportFormSupportedExtensions = "jpg | jpeg | png | pdf";

    constructor(
        // private accountService: AccountService,
        private applicationService: ApplicationService,
        private store: Store<{ application: ApplicationState }>,
        private router: Router,
        private fileService: FileService) {
        this.applicationSteps = this.buildApplicationSteps();
    }

    async ngOnInit() {
        const state$ = this.store.select(s => s.application);

        this.subscription.add(state$.subscribe(state => {
            this.currentTab = state.currentTab;
            this.applicationMetadata = state.metadata;
            this.applicationForm = state.applicationForm?.value;

            if (this.applicationMetadata?.applicationState === 'Submitted') {
                this.router.navigate(['/application/submitted']);
            }

            this.hasUploadedHeadshotPhoto = state.applicationForm?.controls
                .personalInfo.controls
                .hasUploadedHeadshotPhoto.value;

            this.hasUploadedMentalHealthVerificationForm = state.applicationForm?.controls
                .medicalAndMentalHealth.controls
                .hasUploadedMentalHealthVerificationForm.value;

            this.hasUploadedPhysicianReport = state.applicationForm?.controls
                .medicalAndMentalHealth.controls
                .hasUploadedPhysicianReport.value;

            this.has_uploaded_discharge_document_214_form = state.applicationForm?.controls
                .personalInfo.controls
                .has_uploaded_discharge_document_214_form.value;

            this.hasUploadedAgreementToSupportForm = state.applicationForm?.controls
                .personalInfo.controls
                .hasUploadedAgreementToSupportForm.value;

            this.saving = state.saving;
        }));

        await this._loadApplication();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setApplicationStep(stepName: string) {
        const step = stepName as ApplicationSteps;
        this.store.dispatch(setCurrentStep({ step }));
    }

    setApplicationStep2(step: ApplicationSteps) {
        this.store.dispatch(setCurrentStep({ step }));
    }

    step(stepName: string): ApplicationSteps {
        return <ApplicationSteps>stepName;
    }

    async uploadFile($event, type: string) {
        const file = $event.target.files[0];

        this.activeUploads[type] = true;

        try {
            // Note: File input change event fires if you open and close the File Explorer.
            // File variable will be `undefined` at that time.
            if (file) {
                await this.saveApplication();
                var errorMsg = await this.fileService.uploadFile(file, type);
                if (errorMsg) {
                    return this.updateErrorStateAndMsg(type, errorMsg);
                } else {
                    this.resetErrorStateAndMsg(type);
                }

                switch (type) {
                    case 'Headshot':
                        this.store.dispatch(uploadedHeadshotPhoto());
                        break;
                    case 'MentalHealthVerificationForm':
                        this.store.dispatch(uploadedMentalHealthVerificationForm());
                        break;
                    case 'PrimaryCarePhysicianReport':
                        this.store.dispatch(uploadedPhysicianReport());
                        break;
                    case 'DD214Form':
                        this.store.dispatch(uploadedDD214Form());
                        break;
                    case 'AgreementToSupportForm':
                        this.store.dispatch(uploadedAgreementToSupportForm());
                        break;
                }

                await this.saveApplication();
            }
        } finally {
            this.activeUploads[type] = false;
        }
    }

    async saveApplication() {
        let viewModel: WarriorApplicationViewModel = {
            application: this.applicationForm,
            metadata: {
                applicationStep: this.currentTab,
                ...this.applicationMetadata
            }
        }

        // this.saving = true;
        this.store.dispatch(savingApplication({ saving: true }));

        try {
            await this.applicationService.saveApplication(viewModel);
        } catch (err) {
            console.error(err);
            alert('An unexpected error occurred while saving your application.');
        } finally {
            this.store.dispatch(savingApplication({ saving: false }));
            // this.saving = false;
        }
    }

    get canImpersonate(): boolean {
        return !!this.userInfo.find(i =>
            i.type === "can_impersonate" &&
            i.value === "true"
        )
    }

    get hasAnyActiveUpload(): boolean {
        const keys = Object.keys(this.activeUploads);
        return keys.length > 0 && keys.some(k => this.activeUploads[k]);
    }

    isActiveUpload(type: string): boolean {
        return !!this.activeUploads[type];
    }

    private async _loadApplication() {
        this.store.dispatch(incrementLoading());

        try {
            const viewModel = await this.applicationService.getApplication();
            this.store.dispatch(loadApplication({ viewModel }));
        } finally {
            this.store.dispatch(decrementLoading());
        }
    }

    private buildApplicationSteps(): StepMetadata[] {
        return Object.keys(STEP_METADATA)
            .map(key => <StepMetadata>STEP_METADATA[key])
            .sort((a, b) => a.index - b.index);
    }

    private resetErrorStateAndMsg(type: string) {
        switch (type) {
            case 'Headshot':
                this.headshotPhotoErrorMsg = undefined;
                this.isHeadshotPhotoValid = true;
                break;
            case 'MentalHealthVerificationForm':
                this.mentalHealthVerificationFormErrorMsg = undefined;
                this.isMentalHealthVerificationFormValid = true;
                break;
            case 'PrimaryCarePhysicianReport':
                this.physicianReportErrorMsg = undefined;
                this.isPhysicianReportValid = true;
                break;
            case 'DD214Form':
                this.dd214FormErrorMsg = undefined;
                this.isDD214FormValid = true;
                break;
            case 'AgreementToSupportForm':
                this.agreementToSupportFormErrorMsg = undefined;
                this.isAgreementToSupportFormValid = true;
                break;
        }
    }

    private updateErrorStateAndMsg(type: string, errorMsg: string) {
        switch (type) {
            case 'Headshot':
                this.headshotPhotoErrorMsg = errorMsg;
                this.isHeadshotPhotoValid = false;
                break;
            case 'MentalHealthVerificationForm':
                this.mentalHealthVerificationFormErrorMsg = errorMsg;
                this.isMentalHealthVerificationFormValid = false;
                break;
            case 'PrimaryCarePhysicianReport':
                this.physicianReportErrorMsg = errorMsg;
                this.isPhysicianReportValid = false;
                break;
            case 'DD214Form':
                this.dd214FormErrorMsg = errorMsg;
                this.isDD214FormValid = false;
                break;
            case 'AgreementToSupportForm':
                this.agreementToSupportFormErrorMsg = errorMsg;
                this.isAgreementToSupportFormValid = false;
                break;
        }
    }
}

