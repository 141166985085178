<div class="p-4">
    <ng-container *ngIf="!!userFirstName && !!leftOffStepLabel">
        <h1 class="text-blue">Welcome back to <br />your application, {{userFirstName}}.</h1>
        <div class="alert-notice mt-4">
            <h5 class="text-blue">Ready to pick up where you left off?</h5>
            <p class="text-dark">The last time you left, you stopped on <strong>{{leftOffStepLabel}}</strong>. You
                have 23 days left to
                finish
                this application. Please fill out the application and submit it as soon as possible.</p>
            <button class="btn btn-secondary" (click)="continueTheApplication()">CONTINUE THE APPLICATION</button>
        </div>
    </ng-container>
    <ng-container *ngIf="!userFirstName || !leftOffStepLabel">
        <div class="d-flex align-items-center">
            <strong>Loading...</strong>
            <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
        </div>
    </ng-container>
</div>
