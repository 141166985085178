import { StateUpdateFns, validate } from "ngrx-forms";
import { maxLength, required } from "ngrx-forms/validation";
import { ApplicationForm } from "../application-form.state";

export const BiographicalInfoRequiredInputStateFns: StateUpdateFns<ApplicationForm['biographicalInfo']> = {
    descriptionOfSelf: validate(required, maxLength(4000)),
    angerAndStress: validate(required, maxLength(4000)),
    emotionalConflict: validate(required, maxLength(4000)),
    personalConfrontations: validate(required, maxLength(4000)),
    anxietyOrPtsd: validate(required, maxLength(4000))
}


export const BiographicalInfoOptionalInputStateFns: StateUpdateFns<ApplicationForm['biographicalInfo']> = {
}


export const BiographicalInfoSetOptionalInputStateFns: StateUpdateFns<ApplicationForm['biographicalInfo']> = {
}


export const BiographicalInfoOptionalInputValidatorStateFns: StateUpdateFns<ApplicationForm['biographicalInfo']> = {
}