<div class="border-bottom px-3 py-4">
    <h5 class="section-title mb-0">Biographical Info</h5>
</div>
<ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState">
        <app-warrior-form-input
            inputType="textarea"
            label="About You"
            sublabel="Please tell us about yourself. Include a description of a typical day in your life."
            [control]="formState.controls.descriptionOfSelf"
            placeholder="Leave a comment here">
        </app-warrior-form-input>

        <app-warrior-form-input
            inputType="textarea"
            label="Anger and Stress"
            sublabel="How do you deal with your anger and stress?"
            [control]="formState.controls.angerAndStress"
            placeholder="Leave a comment here">
        </app-warrior-form-input>

        <app-warrior-form-input
            inputType="textarea"
            label="Emotional Conflict"
            sublabel="Please provide an example of a time when you were faced with an emotional
            conflict and the steps used to resolve the conflict."
            [control]="formState.controls.emotionalConflict"
            placeholder="Leave a comment here">
        </app-warrior-form-input>

        <app-warrior-form-input
            inputType="textarea"
            label="Personal Confrontation"
            sublabel="How do you deal with personal confrontation?"
            [control]="formState.controls.personalConfrontations"
            placeholder="Leave a comment here">
        </app-warrior-form-input>

        <app-warrior-form-input
            inputType="textarea"
            label="Anxiety or PTSD"
            sublabel="If you experience anxiety and/or other symptoms of PTSD, please list the most common triggers."
            [control]="formState.controls.anxietyOrPtsd"
            placeholder="Leave a comment here">
        </app-warrior-form-input>

        <div class="border-bottom px-3 py-4 d-flex justify-content-between align-items-center btn-container">
            <button class="btn btn-back" (click)="backToPreviousStep()">BACK TO SECTION 3 - MILITARY INFO</button>
            <button class="btn btn-primary" (click)="continueToNextStep()">CONTINUE TO SECTION 5 - WORK/EDUCATION</button>
        </div>

    </form>
</ng-container>
