<div>
    <h2 class="mb-0">Sign up.</h2>
    <small>If you have an account, <a href="/Identity/Account/Login?returnUrl=%2Fapplication" class="sign-in-link">you can sign in here</a></small>
    <form class="mt-4" [formGroup]="signUpForm" (submit)="onSignUp()">
        <ul *ngIf="modelErrors && modelErrors.length > 0" style="padding: 0;">
            <li *ngFor="let err of modelErrors"><small class="text-danger">{{err}}</small></li>
        </ul>

        <div class="form-group mb-3">
            <label>Your First Name</label>
            <input type="text"
                class="form-control"
                formControlName="firstName"
                id="firstName"
                aria-describedby="firstName"
                [class.is-valid]="isValid('firstName')"
                [class.is-invalid]="isInvalid('firstName')">
            <small class="invalid-feedback" *ngIf="hasRequiredError('firstName')">First name is required.</small>
        </div>

        <div class="form-group mb-3">
            <label>Your Last Name</label>
            <input type="text"
                class="form-control"
                formControlName="lastName"
                id="lastName"
                aria-describedby="lastName"
                [class.is-valid]="isValid('lastName')"
                [class.is-invalid]="isInvalid('lastName')">
            <small class="invalid-feedback" *ngIf="hasRequiredError('lastName')">Last name is required.</small>
        </div>

        <div class="form-group mb-3">
            <label>Your Email</label>
            <input type="email"
                class="form-control"
                formControlName="email"
                id="email"
                aria-describedby="email"
                [class.is-valid]="isValid('email')"
                [class.is-invalid]="isInvalid('email')">
            <small class="invalid-feedback" *ngIf="hasRequiredError('email')">Email is required.</small>
            <small class="invalid-feedback" *ngIf="hasError('email', 'email')">Email must be a valid email address.</small>
        </div>

        <div class="form-group mb-3">
            <label>Your Password</label>
            <input type="password"
                class="form-control"
                formControlName="password"
                id="password"
                aria-describedby="password"
                [class.is-valid]="isValid('password')"
                [class.is-invalid]="isInvalid('password')">
            <small class="invalid-feedback" *ngIf="hasError('password', 'required')">Password is required.</small>
            <small class="invalid-feedback" *ngIf="hasError('password', 'minlength')">Password must be at least {{minLength('password')}} characters in length.</small>
            <small class="invalid-feedback" *ngIf="hasError('password', 'maxlength')">Password must be at most {{maxLength('password')}} characters in length.</small>
            <small class="invalid-feedback" *ngIf="hasError('password', 'atLeastOneNumber')">Password must contain at least one number.</small>
            <small class="invalid-feedback" *ngIf="hasError('password', 'atLeastOneUpperCase')">Password must contain at least one upper case letter.</small>
            <small class="invalid-feedback" *ngIf="hasError('password', 'atLeastOneLowerCase')">Password must contain at least one lower case letter.</small>
            <small class="invalid-feedback" *ngIf="hasError('password', 'atLeastOneNonAlphanumeric')">Password must contain at least one non-alphanumeric (special) character.</small>
        </div>

        <div class="form-group mb-4">
            <label>Confirm Password</label>
            <input type="password"
                class="form-control"
                formControlName="confirmPassword"
                id="confirm-password"
                aria-describedby="confirm-password"
                [class.is-valid]="isValid('confirmPassword')"
                [class.is-invalid]="isInvalid('confirmPassword')">
                <small class="invalid-feedback" *ngIf="hasError('confirmPassword', 'required')">Password is required.</small>
                <small class="invalid-feedback" *ngIf="hasError('confirmPassword', 'minlength')">Password must be at least {{minLength('password')}} characters in length.</small>
                <small class="invalid-feedback" *ngIf="hasError('confirmPassword', 'maxlength')">Password must be at most {{maxLength('password')}} characters in length.</small>
                <small class="invalid-feedback" *ngIf="hasError('confirmPassword', 'atLeastOneNumber')">Password must contain at least one number.</small>
                <small class="invalid-feedback" *ngIf="hasError('confirmPassword', 'atLeastOneUpperCase')">Password must contain at least one upper case letter.</small>
                <small class="invalid-feedback" *ngIf="hasError('confirmPassword', 'atLeastOneLowerCase')">Password must contain at least one lower case letter.</small>
                <small class="invalid-feedback" *ngIf="hasError('confirmPassword', 'atLeastOneNonAlphanumeric')">Password must contain at least one non-alphanumeric (special) character.</small>
                <small class="invalid-feedback" *ngIf="hasError('confirmPassword', 'nopasswordmatch')">Password and Confirm Password must match.</small>
        </div>

        <div class="form-check pl-0">
            <div class="d-flex">
                <input
                    id="termsOfUseCheck"
                    formControlName="termsOfUseCheck"
                    type="checkbox"
                    class="form-check-input mr-1"
                    [class.is-invalid]="isInvalid('termsOfUseCheck')">
                <label for="termsOfUseCheck"
                    class="form-check-label w-100 mb-4">By creating an account you agree to the <a href="./"
                        class="sign-in-link">terms of use</a> and
                    our <a href="./" class="sign-in-link">privacy policy</a>.
                </label>
            </div>
            <div class="invalid-feedback" *ngIf="hasError('termsOfUseCheck', 'required')">You must agree before submitting.</div>
        </div>

        <button class="btn btn-primary btn-block" [disabled]="fg.invalid">SIGN UP<fa-icon [icon]="faArrowRight" class="ml-1"></fa-icon></button>
    </form>
    <p class="small-text-label text-center mt-3">Have an account? <a href="/Identity/Account/Login?returnUrl=%2Fapplication" class="sign-in-link">Sign in</a></p>
</div>
