<div class="container p-5 bg-banner rounded-3">
  <div class="container-fluid py-5">
    <h1 class="display-5 fw-bold text-white">Welcome to the <br />Warrior Application.</h1>
    <img src="../../assets/lines.png" alt="image of golden forward slashes" class="my-4"/>
    <p class="col-md-8 fs-4 text-white pl-0">To fill out an application, please create an account.<br /> To continue or
      check on the status of your application, please sign in to your account.</p>
  </div>
</div>

<div class="container mb-4 shadow d-flex bg-white main-container">
  <div class="container p-5 shadow-right letter-container">
    <div class="text-center mb-5">
      <img src="../../assets/stars.png" alt="image of 3 golden stars"/>
    </div>
    <strong>
      Thank you for your interest in K9s For Warriors.
      As you may know, we are an Assistance Dogs
      International (ADI) accredited provider of Service
      Dogs, serving veterans with service-related post-traumatic stress disorder (PTSD), traumatic brain injury (TBI),
      and/or military sexual trauma (MST).
    </strong>
    <p class="mt-3">
      K9s For Warriors is committed to promoting diversity, equality, and inclusion in its program services and
      considers all applications regardless of race, color, national origin, ancestry, gender, gender identity,
      gender expression, sexual orientation, results of genetic testing, marital status, religion, age, marital status,
      military service, or any other basis protected by applicable law.  Eligibility requirements for acceptance into K9s
      For Warriors’ programs are posted at: <a href="https://k9sforwarriors.org/warrior-journey" target="_blank">https://k9sforwarriors.org/warrior-journey</a>.
    </p>
    <p class="mt-3">
      As a 501(c)(3) nonprofit organization and with our generous community of supporters, K9s For Warriors matches our
      Warriors with a Service Dog and hosts the pair at one of our campuses for a 21-day program at no cost to the
      Warrior. This program includes on campus and off-site training, bonding time in our welcoming housing, and useful
      classes, and it prepares the pair to pass a public access test, earning ADI certification that the dog is the
      handler’s Service Dog. Although a certification is not required by the U.S. laws and regulations which protect a
      person’s right to be accompanied by a Service Dog, ADI’s certification is a nationally recognized indication that
      a dog has been individually trained, by a quality organization, to perform specific tasks to aid a handler with a
      disability in accordance with the ADA, and it can be helpful.
    </p>
    <p>
      Our team selects dogs based on breed, height, weight, health, and temperament; houses them on our campuses; and
      trains them to aid our Warriors. We do not train Service Dogs in the tasks that would be necessary to aid someone
      who uses a wheelchair, mobility scooter, or walker; is substantially visually or hearing impaired; needs retrieval
      assistance; or has advanced mobility or medical alert issues. Also, training your owned pet or a dog from outside
      our program is not an option we offer.
    </p>
    <p>
      We take great pride in providing our Warriors the best possible service, and your honesty and accuracy in this
      application is the first step in getting a “new leash on life!” Although other information may be required, our
      basic criteria includes:
    </p>
    <ol>
      <li>Military service with honorable discharge or current honorable service.</li>
      <li>Verifiable diagnosis of PTSD, TBI, and/or MST which must be service-related.</li>
      <li>Stable living environment, including the financial ability to provide care for a Service Dog.</li>
      <li>No alcohol or substance abuse and no illegal dependency.</li>
      <li>No felony conviction, pending criminal charges, current parole/probation, pattern of criminality, or animal
        abuse.</li>
      <li>Residence in the territorial United States and no intention to leave for 60 days after graduation from our
        program.</li>
      <li>Willingness to rehome pets as required by K9s For Warriors due to, for example, conflict or number of
        dogs.(limit = 3 dogs in the home, including your K9s For Warriors Service Dog)</li>
      <li>Ability to participate fully for 21 days without a caregiver or assistance in activities of daily living.</li>
    </ol>
    <p>
      As I am sure you can imagine, it is ideal that our applicants submit a complete application rather than sending
      portions at a time; however, you will have 60 days to submit all required documents, after which we will welcome
      anyone with an incomplete application who would still like to apply to do so by restarting the process. Upon
      acceptance into the K9s For Warriors Service Dog program, there will be a waiting period which Warriors use to
      prepare to be paired with a Service Dog. During this time, the same team that reviewed your application, the
      Warriors Relations Team will be available to help and support you – as we will continue to be while you are with
      us for your training and in the years after.
    </p>
    <p class="mb-4">
      Thank you for your service and for considering K9s For Warriors as your service dog provider!
    </p>
    <img src="../../assets/kevin-steele-signature.png" class="mb-3" />
    <br />
    <strong>Kevin R. Steele</strong>
    <p class="mb-0">Chief Program Officer</p>
    <p>K9s For Warriors, Inc.</p>
  </div>
  <div class="container p-5">
    <div class="position-sticky">
      <!-- <ul class="nav nav-tabs mb-5">
        <li class="nav-item" (click)="toggleSignInComponents()">
          <a class="nav-link" [ngClass]="{'active': signUp === false}">Sign In</a>
        </li>
        <li class="nav-item" (click)="toggleSignInComponents()">
          <a class="nav-link" [ngClass]="{'active': signUp === true}">Sign Up</a>
        </li>
      </ul>
      <div *ngIf="signUp">
        <app-sign-up></app-sign-up>
      </div>
      <div *ngIf="!signUp">
        <app-sign-in></app-sign-in>
      </div> -->
      <app-sign-up></app-sign-up>
    </div>
  </div>
</div>
