<label [htmlFor]="control ? control.id : null" class="form-label mb-0">{{label}}</label>
<select class="form-control form-select w-100 mb-3"
    [id]="control.id"
    [ngrxFormControlState]="control"
    [ngrxValueConverter]="selectValueConverter"
    [attr.aria-label]="ariaLabel || label"
    [class.is-valid]="control.isValid"
    [class.is-invalid]="control.isInvalid">
    <option [value]="''">{{placeholder}}</option>
    <option *ngFor="let option of options; trackBy: trackByValue" [value]="getValue(option)">
        {{getName(option)}}
    </option>
</select>
