import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState, FormControlValueTypes, FormGroupState, NgrxValueConverter } from 'ngrx-forms';
import { Observable, Subscription } from 'rxjs';
import { setCurrentStep } from '../store/application.actions';
import { ApplicationState, ApplicationSteps } from '../store/application.state';
import { ApplicationForm } from '../store/application-form.state';
import { IconDefinition, faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { StatesAndTerritories } from '../store/reference-data';

@Component({
    selector: 'app-legal-history',
    templateUrl: './legal-history.component.html',
    styleUrls: ['./legal-history.component.css']
})
export class LegalHistoryComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    private applicationForm?: ApplicationForm;

    faExclamationCircle: IconDefinition = faExclamationCircle;
    faTimesCircle: IconDefinition = faTimesCircle;

    formState$: Observable<FormGroupState<ApplicationForm['legalHistory']>>
    showParoleOrProbationWarning: boolean = false

    statesAndTerritoriesOptions: string[] = Object
        .values(StatesAndTerritories)
        .map(k => (k));

    constructor(private store: Store<{ application: ApplicationState }>) {
        this.formState$ = store.select(s => s.application.applicationForm.controls.legalHistory)
    }

    ngOnInit() {
        this.subscription.add(this.store.select(s => s.application).subscribe(state => {
            this.applicationForm = state.applicationForm?.value;

            if (!state.applicationForm)
                return;

            function getValue<K extends keyof ApplicationForm['legalHistory']>(key: K)
                : ApplicationForm['legalHistory'][K] {
                return state.applicationForm.value['legalHistory'][key];
            }

            // Note: Need the felony value since we're displaying the warning outside of the nested section.
            this.showParoleOrProbationWarning =
                getValue('felony') === true &&
                (getValue('isCurrentlyOnParole') === true ||
                 getValue('isCurrentlyOnProbation') === true);
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    backToPreviousStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.MedicalAndMentalHealth }));
        window.scrollTo(0, 0);
    }

    continueToNextStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.Miscellaneous }));
        window.scrollTo(0, 0);
    }

    selectValueConverter: NgrxValueConverter<string, string> = {
        convertViewToStateValue(value: string): string {
            if (!value)
                return null;
            return value;
        },

        convertStateToViewValue(value: string): string {
            if (!value)
                return '';
            return value;
        }
    }

    isInvalid<TValue extends FormControlValueTypes>(control: FormControlState<TValue>): boolean {
        return control.isTouched && control.isInvalid
    }
}
