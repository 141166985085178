import { setErrors, setValue, StateUpdateFns, validate, updateArray, updateGroup } from "ngrx-forms";
import { maxLength, number, required } from "ngrx-forms/validation";
import { ApplicationForm, HouseholdAnimal } from "../application-form.state";
import { initIsOptional } from "./common-functions";

export const HouseholdInfoRequiredInputStateFns: StateUpdateFns<ApplicationForm['householdInfo']> = {
    householdMembers: updateArray(updateGroup({
        name: validate(required),
        relation: validate(required),
        age: validate(required)
    })),

    serviceAnimalsInHome: validate(required),
    serviceAnimals: updateArray(updateGroup<HouseholdAnimal>({
        species: validate(required),
        weight: validate(required, number),
        ageYears: validate(required, number),
        ageMonths: validate(required, number),
        behavioralConcerns: validate(maxLength(1000))
    }, {
        breed: initIsOptional(),
        behavioralConcerns: initIsOptional()
    })),

    petsInHome: validate(required),
    pets: updateArray(updateGroup<HouseholdAnimal>({
        species: validate(required),
        breed: validate(required),
        weight: validate(required, number),
        ageYears: validate(required, number),
        ageMonths: validate(required, number),
        behavioralConcerns: validate(maxLength(1000))
    }, {
        otherSpecies: (c, frm) => frm.controls.species.value === 'Other'
            ? validate(c, required)
            : setErrors(c, {}),
        behavioralConcerns: initIsOptional()
    }, {
        otherSpecies: (c, frm) => frm.controls.species.value !== 'Other'
            ? setValue(c, null)
            : c
    })),

    dogAllergyInHome: validate(required),
    homeIsOwned: validate(required),
    homeType: validate(required),
    hasAYard: validate(required),
    yardIsFenced: validate(required),
    homeLocationType: validate(required),
    homeIsFullyAccessible: validate(required)
}


export const HouseholdInfoOptionalInputStateFns: StateUpdateFns<ApplicationForm['householdInfo']> = {
}


export const HouseholdInfoSetOptionalInputStateFns: StateUpdateFns<ApplicationForm['householdInfo']> = {
}


export const HouseholdInfoOptionalInputValidatorStateFns: StateUpdateFns<ApplicationForm['householdInfo']> = {
}