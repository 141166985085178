<div class="border-bottom px-3 py-4">
    <h5 class="section-title mb-0">Transportation</h5>
</div>
<ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState">
        <div
            class="bg-form-field border-bottom d-flex justify-content-between align-items-center px-3 py-4 responsive-form-field">
            <h6 class="text-dark mb-0">Do you have a current and valid drivers license?</h6>
            <div class="d-flex">
                <div class="form-check mr-3">
                    <input [ngrxFormControlState]="formState.controls.currentAndValidDriversLicense" class="form-check-input" type="radio"
                    name="currentAndValidDriversLicense" id="currentAndValidDriversLicense_Yes" [value]="true">
                    <label class="form-check-label" for="currentAndValidDriversLicense_Yes">
                        Yes
                    </label>
                </div>
                <div class="form-check">
                    <input [ngrxFormControlState]="formState.controls.currentAndValidDriversLicense" class="form-check-input" type="radio"
                    name="currentAndValidDriversLicense" id="currentAndValidDriversLicense_No" [value]="false">
                    <label class="form-check-label" for="currentAndValidDriversLicense_No">
                        No
                    </label>
                </div>
            </div>
        </div>
        <div class="bg-form-field px-3 py-4 border-bottom">
            <div class="d-flex justify-content-between align-items-center responsive-form-field"
                [class.mb-3]="formState.controls.driveYourself.value === false">
                <h6 class="text-dark mb-0">Do you drive yourself?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.driveYourself" class="form-check-input" type="radio"
                        name="driveYourself" id="driveYourself_Yes" [value]="true">
                        <label class="form-check-label" for="driveYourself_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.driveYourself" class="form-check-input" type="radio"
                        name="driveYourself" id="driveYourself_No" [value]="false">
                        <label class="form-check-label" for="driveYourself_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.driveYourself.value === false"
                label="Who is your primary driver?"
                [showBottomBorder]="false"
                [control]="formState.controls.primaryDriver"
                placeholder="Primary driver">
            </app-warrior-form-input>
        </div>
        <div class="bg-form-field px-3 py-4 border-bottom ">
            <div class="d-flex justify-content-between align-items-center mb-3 responsive-form-field">
                <h6 class="text-dark mb-0">Do you have daily access to transportation?</h6>
                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.transportationAccess" class="form-check-input" type="radio"
                        name="transportationAccess" id="transportationAccess_Yes" [value]="true">
                        <label class="form-check-label" for="transportationAccess_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.transportationAccess" class="form-check-input" type="radio"
                        name="transportationAccess" id="transportationAccess_No" [value]="false">
                        <label class="form-check-label" for="transportationAccess_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="!formState.controls.transportationAccess.value"
                label="What is your primary means of travel?"
                [control]="formState.controls.primaryTravelMeans"
                placeholder="Primary means of travel"
                [showBottomBorder]="false">
            </app-warrior-form-input>
        </div>
        <div class="bg-form-field px-3 py-4 border-bottom ">
            <div class="d-flex justify-content-between align-items-center mb-3 responsive-form-field">
                <div class="d-flex flex-column w-25 form-field-container">
                    <h6 class="text-dark mb-0">Do you have an adaptive vehicle?</h6>
                    <small>If so, explain type: hand controlled auto, hand controlled van, van with a lift, etc.</small>
                </div>

                <div class="d-flex">
                    <div class="form-check mr-3">
                        <input [ngrxFormControlState]="formState.controls.adaptiveVehicle" class="form-check-input" type="radio"
                        name="adaptiveVehicle" id="adaptiveVehicle_Yes" [value]="true">
                        <label class="form-check-label" for="adaptiveVehicle_Yes">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input [ngrxFormControlState]="formState.controls.adaptiveVehicle" class="form-check-input" type="radio"
                        name="adaptiveVehicle" id="adaptiveVehicle_No" [value]="false">
                        <label class="form-check-label" for="adaptiveVehicle_No">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-warrior-form-input
                *ngIf="formState.controls.adaptiveVehicle.value"
                label="If so, please explain"
                [control]="formState.controls.adaptiveExplanation"
                [showBottomBorder]="false">
            </app-warrior-form-input>
        </div>

        <div class="border-bottom px-3 py-4 d-flex justify-content-between align-items-center btn-container">
            <button class="btn btn-back" (click)="backToPreviousStep()">BACK TO SECTION 5 - WORK/EDUCATION</button>
            <button class="btn btn-primary" (click)="continueToNextStep()">CONTINUE TO SECTION 7 - MEDICAL/MENTAL HEALTH</button>
        </div>
    </form>
</ng-container>
