import { Component, OnInit } from '@angular/core';
//Icons
import { faArrowRight, IconDefinition } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  faArrowRight:IconDefinition = faArrowRight;

  constructor() { }

  ngOnInit() {
  }

}
