import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    constructor(private http: HttpClient) { }

    public async uploadFile(myFile: File, attachmentType: string): Promise<any> {
        let formData = new FormData();
        formData.append('file', myFile);
        try {
            return await this.http
                .post<any>(`/api/warrior-applications/current-user/attachments/${attachmentType}`,formData)
                .toPromise();
        } catch (err) {
            if (isHttpErrorResponse(err) && err.status === 400) {
                return err.error.msg;
            } else {
                throw err;
            }
        }
    }
}

function isHttpErrorResponse(err: any): err is HttpErrorResponse {
    return err && err.name && err.name === 'HttpErrorResponse';
}
