<label [htmlFor]="control ? control.id : null" class="form-label mb-0">{{label}}</label>
<input
    *ngIf="!!control && inputType !== 'textarea'"
    [id]="control.id"
    [type]="inputType"
    [ngrxFormControlState]="control"
    [ngrxValueConverter]="valueConverter"
    class="form-control w-100 mb-3"
    [class.is-valid]="control.isValid"
    [class.is-invalid]="control.isInvalid"
    [placeholder]="placeholder || label"
    [attr.aria-label]="ariaLabel || label">
<textarea
    *ngIf="!!control && inputType === 'textarea'"
    [id]="control.id"
    [ngrxFormControlState]="control"
    [ngrxValueConverter]="valueConverter"
    class="form-control w-100 mb-3"
    [class.is-valid]="control.isValid"
    [class.is-invalid]="control.isInvalid"
    [placeholder]="placeholder || label"
    [attr.aria-label]="ariaLabel || label"
></textarea>
<span *ngIf="!control" class="text-danger text-right">Error loading form field.</span>
