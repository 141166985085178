import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { faCheck, faTimesCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FormArrayState, FormControlState, FormControlValueTypes, FormGroupState, NgrxValueConverter } from 'ngrx-forms';
import { addAssistiveDevice, addMedicalDiagnosis, removeAssistiveDevice, removeMedicalDiagnosis, savingApplication, setCurrentStep, uploadedMentalHealthVerificationForm, uploadedPhysicianReport } from '../store/application.actions';
import { ApplicationState, ApplicationSteps } from '../store/application.state';
import { ApplicationForm, AssistiveDevice, MedicalDiagnosis } from '../store/application-form.state';
import { FileService } from "../../services/file.service";
import { ApplicationService, WarriorApplicationMetadata, WarriorApplicationViewModel } from 'src/app/services/application.service';

const LATINATES = [ 'Primary', 'Secondary', 'Tertiary', 'Quaternary' ]

type UploadTypes =
    'MentalHealthVerificationForm' |
    'PrimaryCarePhysicianReport';
type UploaderMap = { [K in keyof UploadTypes]?: boolean }

@Component({
    selector: 'app-medical-mental-health',
    templateUrl: './medical-mental-health.component.html',
    styleUrls: ['./medical-mental-health.component.css']
})
export class MedicalMentalHealthComponent implements OnInit, OnDestroy {
    faTimesCircle: IconDefinition = faTimesCircle;
    faCheck: IconDefinition = faCheck;

    private subscription = new Subscription();

    private activeUploads: UploaderMap = {}
    private currentTab: string;
    private applicationMetadata?: WarriorApplicationMetadata;
    private applicationForm?: ApplicationForm;

    formState$: Observable<FormGroupState<ApplicationForm['medicalAndMentalHealth']>>;
    medicalDiagnoses$: Observable<FormArrayState<MedicalDiagnosis>>;
    assistiveDevices$: Observable<FormArrayState<AssistiveDevice>>;

    hasUploadedMentalHealthVerificationForm: boolean;
    hasUploadedPhysicianReport: boolean;
    showNotQualifiedWarning: boolean = false;

    braceFrequencyOptions: string[] = ['Never', 'Occasionally','Frequently','Always'];

    constructor(private store: Store<{ application: ApplicationState }>,
                private applicationService: ApplicationService,
                private fileService: FileService) {
        this.formState$ = store.select(s => s.application.applicationForm.controls.medicalAndMentalHealth);
        this.medicalDiagnoses$ = store.select(s => s.application.applicationForm.controls.medicalAndMentalHealth.controls.diagnoses);
        this.assistiveDevices$ = store.select(s => s.application.applicationForm.controls.medicalAndMentalHealth.controls.assistedDevices);
    }

    ngOnInit() {
        this.subscription.add(this.store.select(s => s.application).subscribe(state => {
            this.currentTab = state.currentTab;
            this.applicationMetadata = state.metadata;
            this.applicationForm = state.applicationForm?.value;

            if (!state.applicationForm)
                return;

            function getValue<K extends keyof ApplicationForm['medicalAndMentalHealth']>(key: K)
                : ApplicationForm['medicalAndMentalHealth'][K] {
                return state.applicationForm.value['medicalAndMentalHealth'][key];
            }

            this.hasUploadedMentalHealthVerificationForm = getValue('hasUploadedMentalHealthVerificationForm');
            this.hasUploadedPhysicianReport = getValue('hasUploadedPhysicianReport');

            this.showNotQualifiedWarning =
                getValue('postTraumaticStressDisability') === false &&
                getValue('traumaticBrainInjury') === false &&
                getValue('militarySexualTrauma') === false;
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    range(min: number, max: number): string[] {
        const arr = [];
        for (let i = min; i <= max; i++)
            arr.push(i.toString());
        return arr;
    }

    latinateDiagnosis(i: number) {
        if (i < LATINATES.length) {
            return `${LATINATES[i]} Diagnosis`;
        } else {
            return `Diagnosis ${i + 1}`;
        }
    }

    addDiagnosis() {
        this.store.dispatch(addMedicalDiagnosis());
    }

    removeDiagnosis(index: number) {
        this.store.dispatch(removeMedicalDiagnosis({ index }));
    }

    addAssistiveDevice() {
        this.store.dispatch(addAssistiveDevice());
    }

    removeAssistiveDevice(index: number) {
        this.store.dispatch(removeAssistiveDevice({ index }));
    }

    backToPreviousStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.Transportation }));
        window.scrollTo(0,0);
    }

    continueToNextStep() {
        this.store.dispatch(setCurrentStep({ step: ApplicationSteps.LegalHistory }));
        window.scrollTo(0,0);
    }

    trackByIndex(index, item) {
        return index;
    }

    selectValueConverter: NgrxValueConverter<string, string> = {
        convertViewToStateValue(value: string): string {
            if (!value)
                return null;
            return value;
        },

        convertStateToViewValue(value: string): string {
            if (!value)
                return '';
            return value;
        }
    }

    isInvalid<TValue extends FormControlValueTypes>(control: FormControlState<TValue>): boolean {
        return control.isTouched && control.isInvalid
    }

    async uploadPrimaryCarePhysicianReport($event) {
        await this.uploadFile($event, 'PrimaryCarePhysicianReport');
    }

    async uploadFileMentalHealthVerification($event) {
        await this.uploadFile($event, 'MentalHealthVerificationForm');
    }

    private async uploadFile($event, type: UploadTypes) {
        this.activeUploads[type] = true;

        try {
            await this._saveApplication();
            await this.fileService.uploadFile($event.target.files[0], type);

            switch (type) {
                case 'MentalHealthVerificationForm':
                    this.store.dispatch(uploadedMentalHealthVerificationForm());
                    break;
                case 'PrimaryCarePhysicianReport':
                    this.store.dispatch(uploadedPhysicianReport());
                    break;
            }

            await this._saveApplication();
        } catch (err) {
            console.error(err);
            alert('An error occurred while uploading the Mental Health Verification form.');
        } finally {
            this.activeUploads[type] = false;
        }
    }

    get hasAnyActiveUpload(): boolean {
        const keys = Object.keys(this.activeUploads);
        return keys.length > 0 && keys.some(k => this.activeUploads[k]);
    }

    isActiveUpload(type: string): boolean {
        return !!this.activeUploads[type];
    }

    // HACK.JB - Duplicated from application.component.ts
    private async _saveApplication() {
        let viewModel: WarriorApplicationViewModel = {
            application: this.applicationForm,
            metadata: {
                applicationStep: this.currentTab,
                ...this.applicationMetadata
            }
        }

        this.store.dispatch(savingApplication({ saving: true }));

        try {
            await this.applicationService.saveApplication(viewModel);
        } finally {
            this.store.dispatch(savingApplication({ saving: false }));
        }
    }
}


