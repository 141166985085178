import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroupState } from 'ngrx-forms';
import { faTimesCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { HomeAddress } from 'src/app/application/store/application-form.state';
import { StatesAndTerritories } from 'src/app/application/store/reference-data';

type CodeAndName = {
    code: string;
    name: string;
};

@Component({
    selector: 'app-home-address-form',
    templateUrl: './home-address-form.component.html',
    styleUrls: ['./home-address-form.component.css']
})
export class HomeAddressFormComponent implements OnInit {
    @Input() formState: FormGroupState<HomeAddress>;
    @Input() isCurrentAddress: boolean;
    @Input() addressIndex?: number;
    @Output() removeAddress = new EventEmitter<number>();

    faTimesCircle: IconDefinition = faTimesCircle;
    statesAndTerritories = Object
        .keys(StatesAndTerritories)
        .map(k => ({ code: k, name: StatesAndTerritories[k] }));

    constructor() { }

    ngOnInit(): void {}

    get addressLabel(): string {
        if (this.isCurrentAddress) {
            return 'Current Address';
        } else {
            return `Previous Address ${this.addressIndex + 1}`;
        }
    }

    handleRemoveButtonClick() {
        this.removeAddress.emit(this.addressIndex);
    }

    getCode(item: CodeAndName) {
        return item.code;
    }

    getName(item: CodeAndName) {
        return item.name;
        // return `${item.code} - ${item.name}`;
    }
}
